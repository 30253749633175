import classNames from 'classnames'
import Button from 'components/Button/Button'
import ButtonIconText from 'components/Button/components/ButtonIconText'
import TextCore from 'components/form/TextCore/TextCore'
import { useState } from 'react'
import { IServiceCallLogFilters } from '../meta/types'

type Props = {
    filters: IServiceCallLogFilters
    changeEvent: (filters: Partial<IServiceCallLogFilters>) => void;
}

function AdminServiceCallLogHeader({
    filters,
    changeEvent
}: Props) {
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    return (
        <div className='user-list-header py-3 px-2 pr-3'>
            <div className="d-flex">
                <h2 className='flex-grow-1'>
                    Service Call Log
                </h2>
                <Button color='yellow' onClick={() => setIsFilterOpen(prev => !prev)}>
                    <ButtonIconText
                        icon={<i className='icon icon-filter' />}
                    />
                </Button>
            </div>

            <div className={classNames("d-flex mb-4 filters", { 'is-open': isFilterOpen })}>
                <TextCore
                    className='search-text col-3'
                    placeholder='Service Name'
                    value={filters.serviceName}
                    onChange={(e) => changeEvent({
                        serviceName: e.target.value
                    })}
                />
                <TextCore
                    className='search-text ml-3 col-3'
                    placeholder='Username'
                    value={filters.userId}
                    onChange={(e) => changeEvent({
                        userId: e.target.value
                    })}
                />
            </div>
        </div>
    )
}

export default AdminServiceCallLogHeader