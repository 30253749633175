import Button from 'components/Button/Button'
import ButtonIconText from 'components/Button/components/ButtonIconText'
import { useModalState } from 'components/Modal/hooks/useModalState'
import Modal from 'components/Modal/Modal'
import endpoints from 'constants/endpoints'
import useRequest from 'hooks/useRequest/useRequest'
import { Fragment, useMemo } from 'react'
import { ITradingCommissionValues, ITradingCommissionValuesForm } from '../meta/types'
import TradingCommissionForm from './TradingCommissionForm'

type TradingCommissionFormEditProps = {
    id: number;
    tokenId?: number;
    currency?: string;
    close: () => void;
}

function TradingCommissionFormEdit({
    id,
    currency,
    tokenId,
    close
}: TradingCommissionFormEditProps) {
    const {
        responseData
    } = useRequest<ITradingCommissionValues>({
        url: endpoints.tradingCommissions.getOne(id),
    })


    const initialValues = useMemo<ITradingCommissionValuesForm>(() => ({
        lowerLimitOfTurnOver: Number(responseData?.lowerLimitOfTurnOver),
        upperLimitOfTurnOver: Number(responseData?.upperLimitOfTurnOver),
        makerCommission: Number(responseData?.makerCommission) * 100,
        takerCommission: Number(responseData?.takerCommission) * 100,
    }), [responseData?.lowerLimitOfTurnOver, responseData?.makerCommission, responseData?.takerCommission, responseData?.upperLimitOfTurnOver])

    if (responseData)
        return <TradingCommissionForm
            close={close}
            currency={currency}
            tokenId={tokenId}
            method={'PUT'}
            initialData={initialValues}
            url={endpoints.tradingCommissions.update(id)}
        />
    return null
}

type Props = {
    tokenId?: number;
    currency?: string;
    id: number
}

function TradingCommissionFormEditDialog({
    currency,
    tokenId,
    id
}: Props) {
    const { isOpen, close, open } = useModalState()
    return (
        <Fragment>
            <Button onClick={open} color="primary" className="my-auto">
                <ButtonIconText
                    icon={<i className='icon icon-edit' />}
                    text={'Edit'}
                />
            </Button>
            <Modal isOpen={isOpen} close={close}>
                {isOpen && <TradingCommissionFormEdit
                    currency={currency}
                    tokenId={tokenId}
                    close={close}
                    id={id}
                />}
            </Modal>
        </Fragment>
    )
}

export default TradingCommissionFormEditDialog