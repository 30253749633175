import portalRoot from 'portalRoot';
import React, { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom';
import { IAnchorRenderProps, IDropdownMenuState } from './meta/types'
import './assets/DropdownMenu.scss'
import { wait } from 'utils/wait';

export interface IDropdownMenuProps {
    renderAnchor: (e: IAnchorRenderProps) => ReactNode;
    children?: ReactNode | ((e: IAnchorRenderProps) => ReactNode);
    contentWidth?: number;
    /**
     * open button of anchor
     */
    openButtonOfAnchor?: boolean;
}

function DropdownMenu({
    renderAnchor,
    children,
    contentWidth,
    openButtonOfAnchor
}: IDropdownMenuProps) {

    const dropdownMenuContentRef = useRef<HTMLDivElement>(null)

    const anchorPointRef = useRef<HTMLDivElement>(null)

    const [state, setState] = useState<IDropdownMenuState>({
        isOpen: false,
        left: 0,
        top: 0,
        visibility: 'hidden'
    })

    const calculatePosition = useCallback(async () => {
        for (let i = 0; i < 8; i++) {
            if (dropdownMenuContentRef.current) {
                const rect = dropdownMenuContentRef.current.getBoundingClientRect();
                if (rect.bottom > window.innerHeight) {
                    if (anchorPointRef.current) {
                        const anchorRect = anchorPointRef.current.getBoundingClientRect();
                        setState(prev => ({ ...prev, top: anchorRect.top - rect.height - 12, visibility: 'visible' }))
                    }
                } else {
                    setState(prev => ({ ...prev, visibility: 'visible' }))
                }
                return;
            }
            await wait(1000)
        }
    }, [])

    useEffect(() => {
        if (state.isOpen) {
            calculatePosition()
        }
    }, [calculatePosition, state.isOpen])


    const open = useCallback((e: React.MouseEvent<HTMLElement>) => {
        if (state.isOpen === false) {
            if (anchorPointRef.current) {
                const targetRect = (anchorPointRef.current as HTMLElement).getBoundingClientRect();
                let left = 0;
                if (openButtonOfAnchor) {
                    left = targetRect.left;
                } else {
                    left = targetRect.left + targetRect.width;
                    const finalContentWidth = contentWidth ?? anchorPointRef.current?.getBoundingClientRect().width ?? 0;
                    if (left + finalContentWidth > window.document.documentElement.clientWidth) {
                        left = targetRect.left + targetRect.width - finalContentWidth;
                    }
                }
                setState({
                    isOpen: true,
                    left,
                    top: targetRect.top + targetRect.height,
                    visibility: 'hidden'
                })
            }
        }
    }, [contentWidth, openButtonOfAnchor, state.isOpen])

    const close = useCallback(() => {
        setState({ isOpen: false, left: 0, top: 0, visibility: 'hidden' })
    }, [])

    const toggle = useCallback((e: React.MouseEvent<HTMLElement>) => {
        if (state.isOpen) {
            close()
        } else {
            open(e)
        }
    }, [close, open, state.isOpen])

    useEffect(() => {
        const handleClickOutsideOfDropdownMenuContent = (e: MouseEvent) => {

            if (anchorPointRef.current?.contains(e.target as any) === false &&
                dropdownMenuContentRef.current?.contains(e.target as any) === false) {
                close()
            }
        }
        window.addEventListener('mousedown', handleClickOutsideOfDropdownMenuContent)
        return () => {
            window.removeEventListener('mousedown', handleClickOutsideOfDropdownMenuContent)
        }
    }, [close])

    return (
        <Fragment>
            <div ref={anchorPointRef} className='dropdown-menu-anchor-point'>
                {renderAnchor({ close, open, toggle })}
            </div>

            {state.isOpen && createPortal(<div
                ref={dropdownMenuContentRef}
                className='dropdown-menu-content portal-item'
                style={{
                    left: state.left,
                    top: state.top + 2,
                    width: contentWidth ?? anchorPointRef.current?.getBoundingClientRect().width,
                    visibility: state.visibility
                }}
            >
                {typeof children === 'function' ? children({ close, open, toggle }) : children}
            </div>, portalRoot)}
        </Fragment>
    )
}

export default DropdownMenu