import Button from 'components/Button/Button'
import { useModalState } from 'components/Modal/hooks/useModalState'
import Modal from 'components/Modal/Modal'
import WalletCreateForm from './WalletCreateForm'

function WalletCreateDialog() {
    const { isOpen, open, close } = useModalState()
    return (
        <div>
            <Button className='d-flex pr-4' onClick={open}>
                <i className='md-icon icon-plus my-auto' />
                <span className="title mt-1 ml-1 d-block">
                    Create New Wallet
                </span>
            </Button>
            <Modal width={470} close={close} isOpen={isOpen}>
                <WalletCreateForm closeModal={close} />
            </Modal>
        </div>
    )
}

export default WalletCreateDialog