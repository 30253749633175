import TradingCommissionFormDialog from "./TradingCommissionFormDialog"

interface Props {
  tokenId?: number;
  currency?: string;
}

function TradingCommissionListHeader({
  currency,
  tokenId,
}: Props) {
  return (
    <div className="d-flex pb-3">
      <h3 className="pl-2 flex-grow-1 my-auto">
        Trading Commission List
      </h3>
      <TradingCommissionFormDialog
        tokenId={tokenId}
        currency={currency}
      />
    </div>
  )
}

export default TradingCommissionListHeader