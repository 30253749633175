import classNames from 'classnames'
import { HTMLProps, ReactNode } from 'react'
import { numberWithCommas } from 'utils/string'
import './assets/AmountViewer.scss'

export interface IAmountViewerProps extends HTMLProps<HTMLDivElement> {
    value?: number;
    children?: ReactNode;
}

function AmountViewer({
    value = 0,
    className,
    children,
    ...props
}: IAmountViewerProps) {
    const isNumberPositive = value > 0;
    const isNumberNegative = value < 0;
    const isNumberZero = value === 0;
    return (
        <div
            className={classNames('amount-viewer', {
                'is-number-positive': isNumberPositive,
                'is-number-negative': isNumberNegative,
                'is-number-zero': isNumberZero
            }, className)}
            {...props}
        >
            {children ?? numberWithCommas(value)}
        </div>
    )
}

export default AmountViewer