import Layout from 'containers/Layout/Layout';
import Snackbar from 'containers/Snackbar/Snackbar';
import Login from 'pages/Login/Login';
import SignUp from 'pages/SignUp/SignUp';
import WalletList from 'pages/Wallet/WalletList';
import WalletDetail from 'pages/WalletDetail/WalletDetail';
import { Routes } from 'react-router';
import { BrowserRouter, Outlet, Route } from 'react-router-dom';
import CurrencyProvider from 'containers/CurrencyProvider/CurrencyProvider'
import Dashboard from 'pages/Dashboard/Dashboard';
import TokenFactory from 'pages/TokenFactory/TokenFactory';
import ChainProvider from 'containers/ChainProvider/ChainProvider';
import AccessToken from 'pages/AccessToken/AccessToken';
import AdminLogin from 'pages/AdminLogin/AdminLogin';
import ForceChangePassword from 'pages/ForceChangePassword/ForceChangePassword';
import UserList from 'pages/UserList/UserList';
import Credits from 'pages/Credits/Credits';
import ServiceCost from 'pages/ServiceCost/ServiceCost';
import TradingCommission from 'pages/TradingCommission/TradingCommission';
import ServiceCallLog from 'pages/ServiceCallLog/ServiceCallLog';
import DaoList from 'pages/DaoList/DaoList';
import ProposalList from 'pages/ProposalList/ProposalList';

function App() {
  return (
    <Snackbar>
      <CurrencyProvider>
        <ChainProvider>
          <BrowserRouter basename='/'>
            <Routes>
              <Route element={<ForceChangePassword redirectPath='/admin/login' />} path='/admin/forceChangePassword' />
              <Route element={<Login />} path='/login' />
              <Route element={<AdminLogin />} path="/admin/login" />
              <Route element={<SignUp />} path='/signup' />
              <Route
                path='/'
                element={<Layout>
                  <Outlet />
                </Layout>}
              >
                <Route element={<Dashboard />} path="/dashboard" />
                <Route element={<WalletList />} path="/wallet" />
                <Route element={<TokenFactory />} path="/tokenFactory" />
                <Route element={<WalletDetail />} path="/wallet/:walletId" />
                <Route element={<UserList />} path="/userList" />
                <Route element={<ServiceCost />} path='/serviceCost' />
                <Route element={<Credits />} path="/credits" />
                <Route element={<AccessToken />} path='/accessToken' />
                <Route element={<TradingCommission />} path="/tradingCommission" />
                <Route element={<ServiceCallLog />} path="/serviceCallLog" />
                <Route element={<DaoList />} path="/dao" />
                <Route element={<ProposalList />} path="/dao/address/:address" />
              </Route>
            </Routes>
          </BrowserRouter>
        </ChainProvider>
      </CurrencyProvider>
    </Snackbar>
  );
}

export default App;
