import Tooltip from 'components/Tooltip/Tooltip';
import '../assets/WalletDetailOverview.scss'
import CoinIcon from 'components/CoinIcon/CoinIcon';
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard';
import WalletAssetsDepositDialog from './WalletAssets/components/WalletAssetsDepositDialog';

type Props = {
    walletName: string;
    walletId: string;
    isin: string;
}

function WalletDetailOverView({
    walletName,
    walletId,
    isin
}: Props) {
    return (
        <div className='wallet-detail-over-view'>
            <div className='wallet-detail-title-wallet d-flex' style={{ alignItems: 'baseline' }}>
                <div className="d-flex flex-grow-1">
                    <CoinIcon
                        className='my-auto'
                        icon={isin}
                        color='primary-color-lighter'
                        height={48}
                        width={48}
                    />
                    <div className='ml-3'>
                        <div className="d-flex">
                            <h2 className='mt-1'>
                                {walletName}
                            </h2>
                        </div>
                        <span className="wallet-id d-flex">
                            Wallet ID:{' '}
                            {walletId} <CopyToClipboard textToCopy={walletId}>
                                <Tooltip tooltipContent='Copy'>
                                    <i className='icon icon-copy' />
                                </Tooltip>
                            </CopyToClipboard>
                        </span>
                    </div>

                </div>
                <WalletAssetsDepositDialog
                    className='ml-2'
                />
            </div>
        </div>
    )
}

export default WalletDetailOverView