import classNames from 'classnames'
import { HTMLProps, ReactNode } from 'react'
import '../assets/ButtonIconText.scss'

interface Props extends HTMLProps<HTMLDivElement> {
    icon?: ReactNode;
    text?: ReactNode;
}

function ButtonIconText({
    icon,
    text,
    className,
    ...props
}: Props) {
    return (
        <div className={classNames(className, 'button-icon-text')} {...props}>
            {icon}
            <div className="text">
                {text}
            </div>
        </div>
    )
}

export default ButtonIconText
