import React, { ReactNode, useCallback } from 'react'
import { RadioGroupContext } from './context/RadioGroupContext'
import { RadioGroupStateType } from './meta/types'

type Props = {
  children: ReactNode;
  value?: RadioGroupStateType
  onChange?: (value: RadioGroupStateType) => void;
}

function RadioGroupCore({
  children,
  onChange,
  value = null
}: Props) {

  const handleChange = useCallback((value: RadioGroupStateType) => {
    if (onChange) {
      onChange(value)
    }
  }, [onChange])

  return (
    <RadioGroupContext.Provider value={{
      update: handleChange,
      value
    }}>
      {children}
    </RadioGroupContext.Provider>
  )
}

export default RadioGroupCore