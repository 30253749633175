import { ITableColumn } from 'components/Table/meta/types';
import Table from 'components/Table/Table'
import moment from 'jalali-moment';
import { IAccessToken } from 'types/IAccessToken';
import AccessTokenActiveDeActive from './AccessTokenActiveDeActive';

const columns: ITableColumn<IAccessToken>[] = [
    {
        field: 'name',
        header: 'Name'
    },
    {
        field: 'validUntil',
        header: 'Valid Until',
        render: v => moment(v).format('YYYY-MM-DD')
    },
    {
        field: 'createdAt',
        header: 'Date Added',
        render: v => moment(v).format('YYYY-MM-DD')
    },
    {
        field: 'isActive',
        header: 'status',
        render: (v, row) => <AccessTokenActiveDeActive 
            id={row.id}
            isActive={v}
        />
    },
    
];

interface Props {
    data?: any[];
}

function AccessTokenList({
    data
}: Props) {
    return (
        <Table
            columns={columns}
            data={data}
        />
    )
}

export default AccessTokenList