import Button from 'components/Button/Button'
import ButtonIconText from 'components/Button/components/ButtonIconText'
import CoinCombobox from 'components/CoinCombobox/CoinCombobox'
import BaseField from 'components/form/BaseField/BaseField'
import RadioButton from 'components/form/RadioButton/RadioButton'
import RadioGroupCore from 'components/form/RadioGroupCore/RadioGroupCore'
import TokenSelect from 'components/form/TokenSelect/TokenSelect'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import './assets/TradingCommission.scss'
import TradingCommissionList from './components/TradingCommissionList'
import { ITradingCommissionSearchFormValues } from './meta/types'

function TradingCommission() {
    const form = useForm<ITradingCommissionSearchFormValues>()
    const type = form.watch('type')

    const [submitedValues, setSubmitedValues] = useState<{
        tokenId?: number,
        currency?: string,
    }>()

    const handleSubmit = async (values: ITradingCommissionSearchFormValues) => {
        setSubmitedValues({
            currency: values?.currency?.isin,
            tokenId: values?.token?.id
        })
    }

    return (
        <div className='trading-commission'>
            <h2>
                Trading Commission
            </h2>
            <div className="mt-4">
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)}>
                        <label className='type-label'>
                            Please select type trading currency
                        </label>
                        <div className="d-flex mt-4">
                            <div className='col-3 pr-5'>
                                <BaseField
                                    component={RadioGroupCore}
                                    onChange={() => {
                                        form.setValue('currency', undefined)
                                        form.setValue('token', undefined)
                                    }}
                                    name="type"
                                >
                                    <div className="d-flex justify-content-space-between mt-3">
                                        <RadioButton
                                            label='Token'
                                            value='token'
                                        />
                                        <RadioButton
                                            className="ml-4"
                                            label='Coin Currency'
                                            value='currency'
                                        />
                                    </div>
                                </BaseField>
                            </div>
                            <div className="col-4">
                                {type === 'token' && <BaseField
                                    component={TokenSelect}
                                    name="token"
                                    inputLabel="Token"
                                />}
                                {type === 'currency' && <BaseField
                                    component={CoinCombobox}
                                    name="currency"
                                    className="w-100"
                                    inputLabel="Currency"
                                />}
                            </div>
                            <Button className='ml-4' type='submit'>
                                <ButtonIconText
                                    icon={<i className='icon icon-search' />}
                                    text='Search...'
                                />
                            </Button>
                        </div>
                        <div className="d-flex flex-direction-row-reverse mt-4">
                        </div>
                    </form>
                </FormProvider>
                <div className="mt-4">
                    {(submitedValues?.tokenId || submitedValues?.currency) && <TradingCommissionList
                        currency={submitedValues?.currency}
                        tokenId={submitedValues?.tokenId}
                    />}
                </div>
            </div>
        </div>
    )
}

export default TradingCommission