import Button from 'components/Button/Button'
import ButtonIconText from 'components/Button/components/ButtonIconText'

type Props = {
    openDialog: () => void;
}

function AccessTokenHeader({
    openDialog
}: Props) {
    return (
        <div className='access-token-header d-flex mb-3'>
            <h2 className='flex-grow-1 m-auto'>
                Access Token Managment
            </h2>
            <Button className='add-button' onClick={openDialog}>
                <ButtonIconText 
                    icon={<i className='icon icon-plus' />}
                    text={'Generate Access Token'}
                />
            </Button>
        </div>
    )
}

export default AccessTokenHeader