/**
 * this function return float number with number of decimals you want
 * @param value is a js number with unlimited decimal number
 * @param decimals witch specify number of decimals you want and default value of decimals is 2
 * @returns float number with number of decimals specified in decimals
 */
export function toFixed(value: number, decimals: number = 2) {
  const num: any = value ?? "";
  const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`);
  const with2Decimals = num.toString().match(reg)[0];
  return with2Decimals;
}

export function includesCaseIgnore(v: string, search: string) {
  return v?.toLowerCase().includes(search?.toLowerCase());
}

export function copyTextToClipboard(text: string) {
  var textArea: any = document.createElement("textarea");

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if the element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a
  // flash, so some of these are just precautions. However in
  // Internet Explorer the element is visible whilst the popup
  // box asking the user for permission for the web page to
  // copy to the clipboard.
  //

  // Place in the top-left corner of screen regardless of scroll position.
  textArea.style.position = "fixed";
  textArea.style.top = 0;
  textArea.style.left = 0;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = "2em";
  textArea.style.height = "2em";

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0;

  // Clean up any borders.
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";

  // Avoid flash of the white box if rendered for any reason.
  textArea.style.background = "transparent";

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Copying text command was " + msg);
  } catch (err) {
    console.log("Oops, unable to copy");
  }

  document.body.removeChild(textArea);
}

export function formattNumber(num: number = 0, fixed: number = 0) {
  if (fixed) {
    Number(num.toFixed(fixed)).toLocaleString();
  }
  return num.toLocaleString();
}

export function createCaptcha(canv: any): string {
  const charsArray =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let lengthOtp = 5;
  let captcha = [];
  for (let i = 0; i < lengthOtp; i++) {
    //below code will not allow Repetition of Characters
    let index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
    if (captcha.indexOf(charsArray[index]) === -1)
      captcha.push(charsArray[index]);
    else i--;
  }
  canv.width = 133;
  canv.height = 45;
  const ctx = canv.getContext("2d");
  ctx.font = "25px Georgia";
  ctx.strokeText(captcha.join(""), 120, 30);
  //storing captcha so that can validate you can save it somewhere else according to your specific requirements
  return captcha.join("");
}

export function fixNumber(x: any = "") {
  x = x.toString();
  if (Math.abs(Number(x)) < 1.0) {
    let e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x?.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}

export function numberWithCommas(x: number | string) {
  x = x.toString();
  const intSection = x.slice(
    0,
    x.indexOf(".") === -1 ? x.length : x.indexOf(".")
  );
  const decimals =
    x.indexOf(".") === -1 ? "" : x.slice(x.indexOf("."), x.length);
  return intSection.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + decimals;
}

export function priceViewer(
  price: number,
  numberOfDecimals: number = 3,
  maxNumberAfterZeros: number = 2
) {
  const priceString = fixNumber(price) + "";
  const numberWithoutDecimal = priceString.slice(
    0,
    priceString.indexOf(".") === -1 ? 0 : priceString.indexOf(".")
  );
  const decimal = priceString.slice(
    priceString.indexOf(".") === -1 ? 0 : priceString.indexOf(".")
  );
  let numberOfZeros = 0;
  let index = 0;
  while (decimal.slice(1)[index++] === "0") {
    numberOfZeros++;
  }
  if (numberWithoutDecimal === "0" && numberOfZeros > 0) {
    // return price?.toLocaleString('en', { maximumFractionDigits: numberOfZeros + 2 })
    return numberWithCommas(price.toFixed(numberOfZeros + numberOfDecimals));
  }
  // return price?.toLocaleString('en', { maximumFractionDigits: 3 })
  if (
    [...decimal.slice(1)].every((item) => item === "0") === false &&
    decimal.slice(1).length > numberOfDecimals
  ) {
    return numberWithCommas(price.toFixed(numberOfDecimals));
  }
  return numberWithCommas(price.toString());
}

export const numberPrettier = (v: string | number) => {
  const numberWitoutDecimal = Number(v).toFixed(15);
  let decimals = [
    ...Number(v)
      .toFixed(15)
      .slice(numberWitoutDecimal.indexOf(".") + 1),
  ];
  for (let i = decimals.length - 1; i > 0; i--) {
    if (decimals[i] === "0") {
      decimals = decimals.slice(0, -1);
    } else break;
  }
  return v && Number(v).toFixed(decimals.length);
};

export function numberToEnglish(n: string | number) {
  var string = n.toString(),
    units,
    tens,
    scales,
    start,
    end,
    chunks,
    chunksLen,
    chunk,
    ints,
    i,
    word,
    words,
    and = "and";

  /* Remove spaces and commas */
  string = string.replace(/[, ]/g, "");

  /* Is number zero? */
  if (parseInt(string) === 0) {
    return "zero";
  }

  /* Array of units as words */
  units = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  /* Array of tens as words */
  tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  /* Array of scales as words */
  scales = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
    "decillion",
    "undecillion",
    "duodecillion",
    "tredecillion",
    "quatttuor-decillion",
    "quindecillion",
    "sexdecillion",
    "septen-decillion",
    "octodecillion",
    "novemdecillion",
    "vigintillion",
    "centillion",
  ];

  /* Split user argument into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return "";
  }

  /* Stringify each integer in each chunk */
  words = [];
  for (i = 0; i < chunksLen; i++) {
    chunk = parseInt(chunks[i]);

    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i].split("").reverse().map(parseFloat);

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if ((word = scales[i])) {
        words.push(word);
      }

      /* Add unit word if array item exists */
      if ((word = units[ints[0]])) {
        words.push(word);
      }

      /* Add tens word if array item exists */
      if ((word = tens[ints[1]])) {
        words.push(word);
      }

      /* Add 'and' string after units or tens integer if: */
      if (ints[0] || ints[1]) {
        /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
        // eslint-disable-next-line no-mixed-operators
        if (ints[2] || (!i && chunksLen)) {
          words.push(and);
        }
      }

      /* Add hundreds word if array item exists */
      if ((word = units[ints[2]])) {
        words.push(word + " hundred");
      }
    }
  }

  return words.reverse().join(" ");
}

export function formatBigNumber(value: number) {
  const valueStr = value.toLocaleString("fullwide", { useGrouping: false });
  if (valueStr.length >= 7 && valueStr.length <= 9) {
    return (value / 10 ** 6).toFixed(0) + "M";
  }
  if (valueStr.length >= 10 && valueStr.length <= 12) {
    return (value / 10 ** 9).toFixed(0) + "B";
  }
  if (valueStr.length >= 13) {
    return (value / 10 ** 12).toFixed(0) + "T";
  }
  return Number(value).toLocaleString("en", { useGrouping: true });
}

function decodeBase64(s: string) {
  var e: any = {},
    i: any,
    b: any = 0,
    c,
    x,
    l = 0,
    a,
    r = "",
    w = String.fromCharCode,
    L = s.length;
  var A: any =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  for (i = 0; i < 64; i++) {
    e[A.charAt(i)] = i;
  }
  for (x = 0; x < L; x++) {
    c = e[s.charAt(x)];
    b = (b << 6) + c;
    l += 6;
    while (l >= 8) {
      ((a = (b >>> (l -= 8)) & 0xff) || x < L - 2) && (r += w(a));
    }
  }
  return r;
}

export function getJWTPayload(jwt: string) {
  try {
    return JSON.parse(decodeBase64(jwt.split(".")[1]));
  } catch {
    return null;
  }
}
