import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import TabItem from 'components/TabPanel/components/TabItem'
import TabPanel from 'components/TabPanel/TabPanel'
import endpoints from 'constants/endpoints'
import useRequest from 'hooks/useRequest/useRequest'
import { useParams } from 'react-router-dom'
import { IWallet } from 'types/IWallet'
import WalletAssets from './components/WalletAssets/WalletAssets'
import WalletDetailOverView from './components/WalletDetailOverview'
import { WalletDetailContext } from './context/WalletDetailContext'

function WalletDetail() {

    const { walletId } = useParams<Record<'walletId', string>>()

    const {
        responseData,
        pending
    } = useRequest<IWallet>({
        url: endpoints.wallet.getOne(walletId as string)
    })

    return (
        <div className="wallet-detail position-relative">
            {pending && <LoadingContainer>
                <CircleLoading />
            </LoadingContainer>}
            {responseData && <WalletDetailContext.Provider value={{ wallet: responseData }}>
                <WalletDetailOverView
                    walletName={responseData.label}
                    walletId={responseData.wallet}
                    isin={responseData.chain}
                />
                <TabPanel defaultActive={1} className='mt-4'>
                    <TabItem title={'Assets'} id={1}>
                        <WalletAssets
                            address={responseData.address}
                            chain={responseData.chain}
                            className='mt-2'
                        />
                    </TabItem>
                </TabPanel>
            </WalletDetailContext.Provider>}
        </div>
    )
}

export default WalletDetail