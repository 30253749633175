import { ReactNode } from 'react'
import './assets/ComboboxLabelIcon.scss'

type Props = {
    icon: ReactNode;
    label: string;
    description?: string;
}

function ComboboxLabelIcon({
    icon,
    label,
    description
}: Props) {
  return (
    <div className='d-flex combobox-label-icon'>
        <div className="my-auto icon-container">
            {icon}
        </div>
        <div className='ml-2'>
            <div className="title my-auto">
                {label}
            </div>
            <div className="note">
                {description}
            </div>
        </div>
    </div>
  )
}

export default ComboboxLabelIcon