import React, { ReactElement, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import '../assets/MonthList.scss'

const messages = defineMessages({
    farvardin: {
        id: 'farvardin',
        defaultMessage: 'farvardin'
    },
    ordibehesht: {
        id: 'ordibehesht',
        defaultMessage: 'ordibehesht'
    },
    khordad: {
        id: 'khordad',
        defaultMessage: 'khordad'
    },
    tir: {
        id: 'tir',
        defaultMessage: 'tir'
    },
    mordad: {
        id: 'mordad',
        defaultMessage: 'mordad'
    },
    shahrivar: {
        id: 'shahrivar',
        defaultMessage: 'shahrivar'
    },
    mehr: {
        id: 'mehr',
        defaultMessage: 'mehr'
    },
    aban: {
        id: 'aban',
        defaultMessage: 'aban'
    },
    azar: {
        id: 'azar',
        defaultMessage: 'azar'
    },
    diy: {
        id: 'diy',
        defaultMessage: 'diy'
    },
    bahman: {
        id: 'bahman',
        defaultMessage: 'bahman'
    },
    esfand: {
        id: 'esfand',
        defaultMessage: 'esfand'
    },
})


function MonthList(): ReactElement {

    const intl = useIntl()

    const monthsNames: string[] = useMemo<string[]>(() => [
        intl.formatMessage(messages.farvardin),
        intl.formatMessage(messages.ordibehesht),
        intl.formatMessage(messages.khordad),
        intl.formatMessage(messages.tir),
        intl.formatMessage(messages.mordad),
        intl.formatMessage(messages.shahrivar),
        intl.formatMessage(messages.mehr),
        intl.formatMessage(messages.aban),
        intl.formatMessage(messages.azar),
        intl.formatMessage(messages.diy),
        intl.formatMessage(messages.bahman),
        intl.formatMessage(messages.esfand),
    ], [intl])

    return (
        <div className="month-viewer">
            {monthsNames.map(month => <div key={month} className="month">
                    <div className="month-label">
                        {month}
                    </div>
            </div>)}
        </div>
    )
}

export default MonthList
