import classNames from 'classnames'
import { MouseEvent, ReactNode } from 'react'

type Props = {
    item: any;
    isSelected: boolean;
    getItemKey: (item: any) => string | number;
    onClick: (e: MouseEvent) => void;
    close: () => void;
    renderLabel: (item: any, index: number, isSelected?: boolean) => ReactNode;
    index: number;
}

function ComboboxItem({
    item,
    isSelected,
    onClick,
    getItemKey,
    close,
    renderLabel,
    index
}: Props) {

    const handleItemClick = () => {
        onClick(item)
        close()
    }

    return (
        <li
            key={getItemKey(item)}
            className={classNames({
                'selected': isSelected
            })}
            onClick={handleItemClick}
        >
            {renderLabel(item, index, isSelected)}
        </li>
    )
}

export default ComboboxItem