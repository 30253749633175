export const minValidator = (min: number) => ({
  minValidator: (v: string | number) => {
    const numberValue = Number(v);
    if (numberValue < min) {
      return `Number cannot be lesser than ${min}`;
    }
  },
});

export const maxValudator = (max: number) => ({
    maxValudator: (v: string | number) => {
      const numberValue = Number(v);
      if (numberValue > max) {
        return `Number cannot be bigger than ${max}`;
      }
    },
  });
  