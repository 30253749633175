import { ITableColumn } from 'components/Table/meta/types'
import Table from 'components/Table/Table'
import { useMemo } from 'react';
import { ITradingCommission } from 'types/ITradingCommission';
import TradingCommissionListActions from './TradingCommissionListActions';


type Props = {
  data?: ITradingCommission[];
  currency?: string;
  tokenId?: number;
}

function TradingCommissionListTable({
  data,
  currency,
  tokenId
}: Props) {
  const columns: ITableColumn<ITradingCommission>[] = useMemo(() => [
    {
      field: 'lowerLimitOfTurnOver',
      header: 'Lower limit of turn over In IRR'
    },
    {
      field: 'upperLimitOfTurnOver',
      header: 'Upper limit of turn over In IRR'
    },
    {
      field: 'takerCommission',
      header: 'Taker Commission',
      render: v => `${v * 100}%`
    },
    {
      field: 'makerCommission',
      header: 'Maker Commission',
      render: v => `${v * 100}%`
    },
    {
      field: 'id',
      header: 'Actions',
      render: (id) => <TradingCommissionListActions
        id={id}
        currency={currency}
        tokenId={tokenId}
      />
    }
  ], [currency, tokenId]);

  return (
    <Table
      columns={columns}
      data={data}
    />
  )
}

export default TradingCommissionListTable