import ThreeDotsLoading from 'components/Loading/ThreeDotsLoading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import SwitchButton from 'components/SwitchButton/SwitchButton'
import endpoints from 'constants/endpoints'
import useRequest from 'hooks/useRequest/useRequest'
import { ISingleResult } from 'types/ISingleResult'
import { IActiveDeActiveRequestBody } from '../meta/types'

type Props = {
    id: number;
    isActive: boolean;
}

function AccessTokenActiveDeActive({
    id,
    isActive
}: Props) {
    const {
        responseData,
        callRequest,
        pending
    } = useRequest<ISingleResult<boolean>, IActiveDeActiveRequestBody>({
        url: endpoints.accessToken.activeDeActive(id),
        method: 'PATCH',
        callRequestOnInit: false
    })

    const handleChange = (newState: boolean) => {
        callRequest({
            data: {
                isActive: newState
            }
        })
    }

    return (
        <div className="position-relative ">
            {pending && <LoadingContainer>
                <ThreeDotsLoading />
            </LoadingContainer>}
            <SwitchButton
                value={responseData?.result ?? isActive}
                onChange={handleChange}
            />
        </div>
    )
}

export default AccessTokenActiveDeActive