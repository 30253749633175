import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import ListContainer from 'containers/ListContainer/ListContainer'
import useDebounce from 'hooks/useDebounce'
import useRequest from 'hooks/useRequest/useRequest'
import { useCallback, useState } from 'react'
import { IListModuleState } from 'types/IListModuleState'
import UserListHeader from './components/UserListHeader'
import UserListTable from './components/UserListTable'
import UserListFooter from './components/UserListFooter'
import { IUserList, IUserListFilters } from './meta/types'

function UserList() {

    const [state, setState] = useState<IListModuleState>({
        page: 0
    })

    const [filters, setFilters] = useState<IUserListFilters>({})

    const handleFilterChange = useCallback((filters: IUserListFilters) => {
        setFilters(prev => ({ ...prev, ...filters }))
    }, [])

    const debounceFilters = useDebounce(filters, 500)

    const {
        responseData,
        pending,
        callRequest
    } = useRequest<IUserList | undefined>({
        url: endpoints.admin.userList,
        callRequestOnInit: true,
        params: {
            ...debounceFilters,
            page: state.page,
        }
    })

    return (
        <ListContainer
            refreshList={callRequest}
            page={state.page}
        >
            <div className='position-relative'>
                {pending && <LoadingContainer>
                    <CircleLoading />
                </LoadingContainer>}
            </div>
            <UserListHeader
                filters={filters}
                changeEvent={handleFilterChange}
            />
            <UserListTable
                data={responseData?.userList ?? []}
            />
            <UserListFooter
                onPageChange={(page) => setState({ page })}
                currenctPage={state.page}
                totalItems={responseData?.totalCount}
            />
        </ListContainer>
    )
}

export default UserList