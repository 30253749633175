import TokenFactoryDialog from './TokenFactoryDialog'

function TokenFactoryHeader() {
  return (
    <div className='d-flex'>
        <h2 className='flex-grow-1'>
            Token Factory
        </h2>
        <TokenFactoryDialog />
    </div>
  )
}

export default TokenFactoryHeader