import CreditsChartDounates from 'components/CreditsCharts/components/CreditsChartDounates'
import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import useRequest from 'hooks/useRequest/useRequest'
import UserListAddCredits from './UserListAddCredits'
import { IServiceCallStatistics } from 'types/IServiceCallStatistics'

type Props = {
    userName: string;
}

function UserListCredits({
    userName
}: Props) {
    const {
        pending,
        callRequest,
        responseData
    } = useRequest<IServiceCallStatistics | undefined>({
        url: endpoints.admin.getUserGetCreditsStatisticsUserId(userName),
        callRequestOnInit: true
    })
    return (
        <div className='position-relative'>
            {pending && <LoadingContainer>
                <CircleLoading />
            </LoadingContainer>}
            {responseData && <CreditsChartDounates
                creditsUsed={responseData?.creditsUsed}
                totlaCredits={responseData?.totlaCredits}
                remainingCredits={responseData?.remainingCredits}
            />}
            <UserListAddCredits
                refreshCharts={callRequest}
                userName={userName}
            />
        </div>
    )
}

export default UserListCredits