const endpoints = {
  getToken: "/userService/authenticate",
  register: "/userService/register",
  profile: "/userService/profile",
  changePassword: "/userService/changePassword",
  changeUserInfo: "/userService/changeUserInfo",
  forceChangePassword: "/userService/forceChangePassword/admin",
  adminLogin: "/userService/authenticate/admin",
  wallet: {
    list: "/api/v1/wallets",
    create: (chain: string) => `/api/v1/${chain}/wallet`,
    getOne: (id: string) => `/api/v1/wallet/${id}`,
    count: "/api/v1/wallets/count",
  },
  token: {
    assets: (chain: string, address: string) =>
      `/api/v1/${chain}/address/${address}/tokens`,
    create: (chain: string) => `/api/v1/${chain}/token/create`,
    transfer: (chain: string) => `/api/v1/${chain}/token/transfer`,
    tokens: "/api/v1/tokens",
    adminTokenList: "api/v1/admin/tokens",
    tokenTransactions: (
      chain: string,
      address: string,
      contractAddress: string
    ) =>
      `/api/v1/${chain}/address/${address}/token/${contractAddress}/transaction`,
  },
  chain: "/api/v1/chains",
  accessToken: {
    list: "/userService/access-token/list",
    generate: "/userService/access-token/generate",
    activeDeActive: (accessTokenId: number) =>
      `/userService/access-token/active-deactive/${accessTokenId}`,
  },
  apiKey: {
    list: "",
    create: "/",
    changeIsActive: "/",
  },
  admin: {
    userList: "/userService/admin/user/list",
    confirm: (id: number) => `/userService/admin/user/${id}/confirm`,
    credits: (userName: string) =>
      `/api/v1/service-call-cost/user-get-credits/${userName}`,
    getUserGetCreditsStatisticsUserId: (userName: string) =>
      `/api/v1/service-call-cost/user-get-credits-statistics/${userName}`,
    addCredits: (userName: string) =>
      `/api/v1/service-call-cost/add-credit/${userName}`,
    serviceCallLog: "/api/v1/service-call-cost/admin-service-call-log",
  },
  serviceCall: {
    serviceCallCostLogList:
      "/api/v1/service-call-cost/user-get-service-call-log",
    serviceCallAddCredits: "/api/v1/service-call-cost/user-add-credit",
    userGetCreditsStatistics:
      "/api/v1/service-call-cost/user-get-credits-statistics",
    serviceCostGetOne: (id: number) =>
      `/api/v1/service-call-cost/get-service-cost/${id}`,
    serviceCostUpdate: (id: number) =>
      `/api/v1/service-call-cost/update-service-cost/${id}`,
    getServiceCostList: `/api/v1/service-call-cost/get-service-cost-list`,
  },
  tradingCommissions: {
    list: "/api/v1/trading-commissions/list",
    create: "/api/v1/trading-commissions/create",
    getOne: (id: number) => `/api/v1/trading-commissions/get/${id}`,
    update: (id: number) => `/api/v1/trading-commissions/update/${id}`,
  },
  dao: {
    list: '/api/v1/dao/list',
    getDaoByAddress: (chain: string, address: string) => `/api/v1/${chain}/dao/voting/address/${address}`,
    createPorpose: `/api/v1/CELO/dao/voting/porpose`
  }
};

export default endpoints;
