import classNames from 'classnames'
import Button from 'components/Button/Button'
import ButtonIconText from 'components/Button/components/ButtonIconText'
import TextCore from 'components/form/TextCore/TextCore'
import SwitchButton from 'components/SwitchButton/SwitchButton'
import { useState } from 'react'
import { IUserListFilters } from '../meta/types'
import '../assets/UserListHeader.scss'

interface Props {
    filters: IUserListFilters;
    changeEvent: (filters: Partial<IUserListFilters>) => void;
}

function UserListHeader({
    filters,
    changeEvent
}: Props) {
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    return (
        <div className='user-list-header py-3 px-2 pr-3'>
            <div className="d-flex">
                <h2 className='flex-grow-1'>
                    User List
                </h2>
                <Button color='yellow' onClick={() => setIsFilterOpen(prev => !prev)}>
                    <ButtonIconText
                        icon={<i className='icon icon-filter' />}
                    />
                </Button>
            </div>

            <div className={classNames("d-flex mb-4 filters", { 'is-open': isFilterOpen })}>
                <TextCore
                    className='search-text col-3'
                    placeholder='Username'
                    value={filters.userName}
                    onChange={(e) => changeEvent({
                        userName: e.target.value
                    })}
                />
                <TextCore
                    className='search-text ml-3 col-3'
                    placeholder='Fullname'
                    value={filters.fullName}
                    onChange={(e) => changeEvent({
                        fullName: e.target.value
                    })}
                />
                <div className="d-flex col-3 ml-3" style={{ marginTop: -23 }}>
                    <SwitchButton
                        className='my-auto mr-2'
                        onChange={(value) => changeEvent({ unConfirmedOnly: value })}
                        value={filters.unConfirmedOnly}
                    />
                    <label className='my-auto'>
                        Display Only UnConfirmed
                    </label>
                </div>
            </div>
        </div>
    )
}

export default UserListHeader