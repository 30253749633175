import { ITabPanelHeaderItem } from '../meta/types'
import TabPanelHeaderItem from './TabPanelHeaderItem';
import '../assets/TabPanelHeader.scss'

type Props = {
    tabs: ITabPanelHeaderItem[];
    activeTabOrIndex: number | string;
    onTabClick: (tabId: string | number) => void;
}

function TabPanelHeader({
    tabs,
    activeTabOrIndex,
    onTabClick
}: Props) {
  return (
    <div className='tab-panel-header'>
        {tabs.map((tab, index) => <TabPanelHeaderItem
            onClick={onTabClick}
            key={tab.id ?? index}
            isActive={activeTabOrIndex === (tab.id === null ? index : tab.id)}
            id={tab.id}
            title={tab.title}
        />)}
    </div>
  )
}

export default TabPanelHeader