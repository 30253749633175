import Modal from 'components/Modal/Modal'
import QrCode from 'components/QrCode/QrCode'
import TokenAddressLink from 'components/TokenAddressLink/TokenAddressLink'
import '../assets/TokenFactorySuccessDialog.scss'

type Props = {
    chain: string;
    tokenAddress: string;
    closeModal: () => void;
}

function TokenFactorySuccessDialog({
    chain,
    tokenAddress,
    closeModal
}: Props) {
    return (
        <Modal close={closeModal} isOpen={true}>
            <div className="token-factory-success-dialog">
                <h3 className='text-center'>
                    Token Generated Successfuly
                </h3>
                <div className="token-factory-address">

                    <QrCode
                        height={320}
                        width={320}
                        value={tokenAddress}
                    />
                    <div className="token-address-label">
                        Token Address:
                    </div>
                    <div className="token-address">
                        {tokenAddress}
                    </div>
                    <div className="mt-2 token-address-label">
                        Token Link:
                    </div>
                    <div className="token-address">
                        <TokenAddressLink
                            displayLink
                            chain={chain}
                            tokenAddress={tokenAddress}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default TokenFactorySuccessDialog