import Button from 'components/Button/Button'
import BaseField from 'components/form/BaseField/BaseField'
import TextCore from 'components/form/TextCore/TextCore'
import endpoints from 'constants/endpoints'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { userSlice } from 'redux/user/userSlice'
import { IUserInfoFormValues, IUserInfoFormValuesData } from './meta/types'

type Props = {
    close: () => void;
    initialData: IUserInfoFormValuesData;
}

function UserInfoForm({
    close,
    initialData
}: Props) {
    const form = useForm<IUserInfoFormValues>({
        defaultValues: initialData
    })

    const {
        callRequest: changeUserInfo,
    } = useRequest<any, IUserInfoFormValuesData>({
        url: endpoints.changeUserInfo,
        method: 'PATCH',
        callRequestOnInit: false
    })

    const snackbar = useSnackbar()
    const dispatch = useDispatch()

    const handleSubmit = async (values: IUserInfoFormValues) => {
        changeUserInfo({
            data: values,
        }).then(() => {
            snackbar.open({
                color: 'green',
                timeout: 4000,
                content: 'Password Changed'
            })
            dispatch(userSlice.actions.setUserInfo(values))
            close()
        })
    };
    return (
        <FormProvider {...form}>
            <h3>
                Update User Info
            </h3>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <BaseField
                    component={TextCore}
                    name="firstName"
                    className="col-12 mt-4"
                    inputLabel="First Name"
                    required
                />

                <BaseField
                    component={TextCore}
                    name="lastName"
                    className="col-12 mt-4"
                    inputLabel="Last Name"
                    required
                />

                <BaseField
                    component={TextCore}
                    name="companyName"
                    className="col-12 mt-4"
                    inputLabel="Company"
                    required
                />

                <BaseField
                    component={TextCore}
                    name="countryName"
                    className="col-12 mt-4"
                    inputLabel="Country"
                    required
                />

                <div className="d-flex flex-direction-row-reverse mt-4">
                    <Button type='submit'>
                        Register
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}

export default UserInfoForm