import { ITableColumn } from 'components/Table/meta/types';
import Table from 'components/Table/Table'
import { ITokenAddress } from 'types/ITokenAddress';
import WalletAssetsWithdrawalDialog from './WalletAssetsWithdrawalDialog';
import TokenAddressLink from 'components/TokenAddressLink/TokenAddressLink';
import '../assets/WalletAssetsTable.scss'
import WalletTokenTransactionsListDialog from './WalletTokenTransactionsListDialog';
import { numberWithCommas } from 'utils/string';

type Props = {
    chain: string;
    walletAddress: string;
    data?: ITokenAddress[];
    className?: string;
}

function WalletAssetsTable({
    data = [],
    className,
    walletAddress,
    chain
}: Props) {
    const walletAssetsTableColumns: ITableColumn<ITokenAddress>[] = [
        {
            field: 'name',
            header: 'Token Name',
            render: (v) => v ? v : <div className="not-generated-by-us">
                -
            </div>,
            width: '20%',
        },
        {
            field: 'symbol',
            header: 'Symbol',
            render: (v) => v ? v : <div className="not-generated-by-us">
                -
            </div>,
            width: '20%',
        },
        {
            header: 'Token Address',
            field: 'tokenAddress',
            render: (v: string, row) => {
                return <TokenAddressLink
                    chain={row.chain}
                    tokenAddress={v}
                />
            },
            width: '35%',
        },
        {
            header: 'Balance',
            field: 'balance',
            width: '20%',
            render: v => <b style={{ fontWeight: 500 }}>
                {numberWithCommas(v)}
            </b>
        },
        {
            field: 'tokenAddress',
            uniqueSortKey: 'actions',
            header: 'Actions',
            render: (tokenAddress: string) => <div className="d-flex">
                <WalletAssetsWithdrawalDialog
                    tokenAddress={tokenAddress}
                />
            </div>
        },
        {
            field: 'tokenAddress',
            render: (v, row) => <WalletTokenTransactionsListDialog
                address={walletAddress}
                tokenName={row.name}
                chain={chain}
                contractAddress={v}
            />
        }
    ];
    return (
        <Table
            className={className}
            columns={walletAssetsTableColumns}
            data={data}
        />
    )
}

export default WalletAssetsTable