import useSnackbar from 'containers/Snackbar/hooks/useSnackbar';
import React, { ElementType, HTMLProps } from 'react'

interface Props extends HTMLProps<HTMLElement> {
    tag?: ElementType;
    textToCopy?: string;
    [rest: string]: any;
}

function CopyToClipboard({
    children,
    textToCopy = '',
    tag: Tag = 'div',
    ...props
}: Props) {
    const snackbar = useSnackbar()
    const handleClick = () => {
        navigator.clipboard.writeText(textToCopy);
        snackbar.open({
            color: 'primary',
            content: 'Copied!',
            timeout: 2000
        })
    }
    return (
        <Tag onClick={handleClick} {...props}>
            {children}
        </Tag>
    )
}

export default CopyToClipboard