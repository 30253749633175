import { ElementType, Fragment, HTMLProps, ReactNode, useMemo } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

interface Props extends HTMLProps<HTMLElement> {
    tooltipContent?: ReactNode;
    children?: ReactNode;
    tag?: ElementType;
}

function Tooltip({
    tooltipContent,
    children,
    tag: TagComponent = 'span',
    ...rest
}: Props) {
    const id = useMemo(() => (new Date().getTime()).toString(),[])
    return (
        <Fragment>
            <ReactTooltip id={id}>
                {tooltipContent}
            </ReactTooltip>
            <TagComponent {...rest} data-tooltip-id={id}>
                {children}
            </TagComponent>
        </Fragment>
    )
}

export default Tooltip