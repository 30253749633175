import classNames from 'classnames'
import CoinIcon from 'components/CoinIcon/CoinIcon'
import TextCore from 'components/form/TextCore/TextCore'
import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import useDebounce from 'hooks/useDebounce'
import useRequest from 'hooks/useRequest/useRequest'
import { ITokenFactoryListModel } from 'pages/TokenFactory/meta/types'
import { ChangeEvent, HTMLProps, useState } from 'react'
import { IToken } from 'types/IToken'
import '../assets/TokenSelectList.scss'

interface Props extends HTMLProps<HTMLDivElement> {
    onTokenClick?: (token: IToken) => void;
    selectedTokenId?: number;
    close?: () => void;
}

function TokenSelectList({
    onTokenClick,
    selectedTokenId,
    className,
    close
}: Props) {
    const [searchKey, setSearchKey] = useState('')

    const handleSearchKeyChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchKey(e.target.value)
    };

    const deboundedSearchKey = useDebounce(searchKey, 400);

    const {
        responseData,
        pending
    } = useRequest<ITokenFactoryListModel | undefined>({
        url: endpoints.token.adminTokenList,
        params: {
            tokenName: deboundedSearchKey
        }
    })

    return (
        <div className={classNames('token-select-list', className)}>
            <TextCore
                onChange={handleSearchKeyChange}
                value={searchKey}
                inputLabel='Search for tokens...'
            />
            <div className='position-relative'>
                {pending && <LoadingContainer>
                    <CircleLoading />
                </LoadingContainer>}

                {responseData?.tokens.map(token => <div
                    onClick={() => {
                        if (onTokenClick) {
                            onTokenClick(token)
                            if (close)
                                close()
                        }
                    }}
                    className={classNames("token-select-list-item d-flex", {
                        'selected': selectedTokenId === token.id
                    })}
                    key={token.tokenAddress}
                >
                    <CoinIcon
                        className='coin-icon'
                        height={32}
                        width={32}
                        icon={token.chain}
                    />
                    <div className="token-name-symbol ml-2">
                        <div className="name">
                            {token.name}
                        </div>
                        <div className="symbol">
                            {token.symbol}
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    )
}

export default TokenSelectList