import { createContext } from "react";
import { IWalletDetailContext } from "../meta/types";

export const WalletDetailContext = createContext<IWalletDetailContext>({
    wallet: {
        chain: '',
        id: 0,
        label: '',
        createdAt: '',
        wallet: '',
        address: ''
    }
})