import ThreeDotsLoading from 'components/Loading/ThreeDotsLoading'
import endpoints from 'constants/endpoints'
import useRequest from 'hooks/useRequest/useRequest'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { IWalletTokenTransactionsList, IWalletTokenTransactionsListItem } from '../meta/types'
import WalletTokenTransactionsList from './WalletTokenTransactionsList'

type Props = {
  chain: string
  address: string;
  contractAddress: string;
  tokenName: string;
}

const PAGE_SIZE = 5;

function WalletTokenTransactions({
  chain,
  address,
  contractAddress,
  tokenName
}: Props) {

  const [items, setItems] = useState<IWalletTokenTransactionsListItem[]>([])

  const {
    callRequest,
    responseData,
    pending
  } = useRequest<IWalletTokenTransactionsList>({
    url: endpoints.token.tokenTransactions(chain, address, contractAddress),
    callRequestOnInit: false,
  })

  const hasRequestCalled = useRef(false)

  const callAndConcat = useCallback((pageSize: number, offset: number = 0) => {
    callRequest({ params: { pageSize, page: Math.ceil(offset / pageSize) } }).then((res) => {
      if (res.data.transactions)
        setItems(prev => prev.concat(res.data.transactions))
      hasRequestCalled.current = true;
    })
  }, [callRequest])

  useEffect(() => {
    if (hasRequestCalled.current === false) {
      callAndConcat(PAGE_SIZE, 0)
    }
  }, [callAndConcat])

  const loadMore = () => {
    callAndConcat(PAGE_SIZE, items.length)
  }

  return (
    <Fragment>
      <h3 className='mb-2'>
        <span style={{ fontWeight: 600 }} className='color-gray-400'>
          {tokenName}
        </span>
        {' '}
        <span style={{ fontWeight: 200, fontSize: 15 }} className='color-gray-300'>
          Transaction List
        </span>
      </h3>
      <WalletTokenTransactionsList
        data={items}
      />
      {pending && <div className="text-center">
        <ThreeDotsLoading />
      </div>}

      {(responseData?.transactions ?? []).length >= PAGE_SIZE && <div className="load-more" onClick={loadMore}>
        Load more...
      </div>}
    </Fragment>
  )
}

export default WalletTokenTransactions