import Button from 'components/Button/Button'
import BaseField from 'components/form/BaseField/BaseField'
import NumberCore from 'components/form/NumberCore/NumberCore'
import TextCore from 'components/form/TextCore/TextCore'
import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import { useListContext } from 'containers/ListContainer/hooks/useListContext'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'
import { FormProvider, useForm } from 'react-hook-form'
import { IWalletAssetTransferTokenModel, IWalletAssetTransferTokenModelForm } from '../meta/types'

type Props = {
    tokenAddress: string;
    walletAddress: string;
    chain: string;
    closeModal: () => void;
}

function WalletAssetsWithdrawalForm({
    tokenAddress,
    walletAddress,
    chain,
    closeModal
}: Props) {

    const {
        callRequest,
        pending,
    } = useRequest<any, IWalletAssetTransferTokenModel>({
        url: endpoints.token.transfer(chain),
        method: 'POST',
        callRequestOnInit: false,
    })

    const { refreshList } = useListContext()

    const snackbar = useSnackbar()

    const handleSubmit = async (values: IWalletAssetTransferTokenModelForm) => {
        await callRequest({
            data: {
                amount: values.amount.replace(/,/g, ''),
                toAddress: values.toAddress,
                fromAddress: walletAddress,
                tokenAddress
            }
        })

        refreshList()

        closeModal()

        snackbar.open({
            color: 'green',
            timeout: 4000,
            content: 'Token Transferd Successfuly'
        })
    }

    const form = useForm<IWalletAssetTransferTokenModelForm>()

    return (
        <FormProvider {...form}>
            <form className='position-relative' onSubmit={form.handleSubmit(handleSubmit)}>
                <h2 className='mb-6 mt-3 pt-1'>
                    Withdraw Token
                </h2>
                {pending && <LoadingContainer>
                    <CircleLoading />
                </LoadingContainer>}
                <BaseField
                    required
                    component={TextCore}
                    name="toAddress"
                    className="mt-4"
                    inputLabel="To Address"
                />

                <BaseField
                    component={NumberCore}
                    hasSeperator
                    name="amount"
                    required
                    className="mt-4"
                    inputLabel="Amount"
                />

                <div className="d-flex justify-content-center mt-4">
                    <Button type='submit'>
                        Withdraw Token
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}

export default WalletAssetsWithdrawalForm