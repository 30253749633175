import Button from 'components/Button/Button';
import BaseField from 'components/form/BaseField/BaseField';
import NumberCore from 'components/form/NumberCore/NumberCore';
import TextCore from 'components/form/TextCore/TextCore';
import CircleLoading from 'components/Loading/Loading';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';
import endpoints from 'constants/endpoints';
import { useListContext } from 'containers/ListContainer/hooks/useListContext';
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar';
import useRequest from 'hooks/useRequest/useRequest';
import { FormProvider, useForm } from 'react-hook-form'
import { IServiceCost } from 'types/IServiceCost';
import { ISingleResult } from 'types/ISingleResult';
import { IServiceCostForm } from '../meta/types';

type Props = {
    serviceCostId: number;
    closeModal: () => void;
}

function ServiceCostForm({
    serviceCostId,
    closeModal
}: Props) {
    const form = useForm<IServiceCostForm>();

    const { refreshList } = useListContext()

    const { responseData, pending } = useRequest<IServiceCost | undefined>({
        url: endpoints.serviceCall.serviceCostGetOne(serviceCostId),
    });

    const snackbar = useSnackbar()

    const { pending: updatePending, callRequest: update } = useRequest<ISingleResult<boolean>, IServiceCostForm>({
        url: endpoints.serviceCall.serviceCostUpdate(serviceCostId),
        callRequestOnInit: false,
        method: 'PATCH'
    });

    const handleSubmit = async (values: IServiceCostForm) => {
        await update({
            data: {
                cost: Number(values.cost)
            }
        });
        refreshList()
        snackbar.open({
            color: 'green',
            timeout: 3000,
            content: 'Successfuly Updated'
        })
        closeModal();
    }

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                {(pending || updatePending) && <div className="position-relative">
                    <LoadingContainer>
                        <CircleLoading />
                    </LoadingContainer>
                </div>}
                {responseData && <div className='d-flex flex-wrap'>
                    <div className="col-6 pr-1">
                        <TextCore
                            inputLabel='Service Name'
                            disabled
                            className="mt-4"
                            value={responseData.name ?? ''}
                        />
                    </div>
                    <div className="col-6 pl-1">
                        <TextCore
                            inputLabel='Category'
                            disabled
                            className="mt-4"
                            value={responseData.category ?? ''}
                        />
                    </div>
                    <TextCore
                        inputLabel='Service URL'
                        className="mt-4"
                        disabled
                        value={responseData.serviceUrlPattern ?? ''}
                    />
                    <BaseField
                        component={NumberCore}
                        className="mt-4"
                        required
                        defaultValue={responseData.cost}
                        inputLabel='Cost'
                        name="cost"
                    />
                </div>}
                <div className="mt-4 d-flex flex-direction-row-reverse">
                    <Button type='submit'>
                        Register
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}

export default ServiceCostForm