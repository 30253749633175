import { Moment } from "moment";
import { useMemo } from "react";
import { IDaysInMonth } from "../meta/types";
import { daysInMonth } from "../meta/utils";

interface Props {
    date: Moment
}

function useCalender({
    date
}: Props): IDaysInMonth {
    const days = useMemo(() => daysInMonth(date), [date]);
    return days
}

export default useCalender