import classNames from 'classnames';
import Tooltip from 'components/Tooltip/Tooltip';
import { NavLink } from 'react-router-dom';
import { ISidebarItem } from '../meta/types';

interface Props extends ISidebarItem { }

function SidebarItem({
    icon,
    title,
    link,
    collapsedIcon
}: Props) {

    const Container = link !== undefined ? NavLink : 'div';

    const contianerProps = (link !== undefined ? {
        to: link,
        className: ({ isActive }: any) => classNames('sidebar-item', { 'is-active': isActive })
    } as any : {
        className: classNames('sidebar-item')
    }) as any;

    return (
        <Container {...contianerProps}>
            <div className="sidebar-icon">
                {icon}
            </div>
            <div className="collapsed-icon">
                {collapsedIcon ?? <Tooltip tooltipContent={title}>
                    {icon}
                </Tooltip>}
            </div>
            <span className="title">
                {title}
            </span>
        </Container>
    )
}

export default SidebarItem