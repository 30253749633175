export function getPasswordStrength(password: string): { message: string | null, power: number } {
    let power: number = 0;
    let message: string | null = null;
    if(/.*[!@#$%^&*() ].*/g.test(password)) {
        power += 2;
    } else {
        message = 'contains at least one special character';
    }
    if(/.*[A-Z].*/g.test(password)) {
        power += 1;
    } else {
        message = 'contains at least one Capital character';
    }
    if(/.*[0-9].*/g.test(password)) {
        power += 1;
    } else {
        message = 'contains at least one number';
    }
    if(password?.length >= 8) {
        power += 1;
    } else {
        message = 'contains at least 8 characters';
    }
    return {
        message: message ? message : 'password is strong',
        power
    }
}