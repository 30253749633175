import classNames from 'classnames'
import React, { ElementType, HTMLProps } from 'react'
import { ColorType } from 'types/ColorType'
import './assets/Button.scss'

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
    tag?: ElementType;
    color?: ColorType;
    [key: string]: any;
}

function Button({
    type='button',
    tag: Tag = 'button',
    className,
    children,
    color = 'primary',
    ...buttonProps
}: ButtonProps) {
  return (
    <Tag {...buttonProps} className={classNames(className, 'button', color)} type={type}>
        {children}
    </Tag>
  )
}

export default Button