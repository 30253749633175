import portalRoot from 'portalRoot'
import { ReactNode, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import ModalCard from './components/ModalCard'
import classNames from 'classnames'
import './assets/Modal.scss'

export interface ModalProps {
    /**
     * Content of Modal card
     */
    children: ReactNode;
    isOpen?: boolean;
    close?: () => void;
    cardClassName?: string;
    containerClassName?: string;
    /**
     * width of modal card Content
     */
    width?: number;
    minHeight?: number;
}

function Modal({
    children,
    close,
    isOpen,
    cardClassName,
    containerClassName,
    width,
    minHeight = 300
}: ModalProps) {

    const modalCardRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleWindowClick = (e: MouseEvent) => {
            if (modalCardRef.current?.contains(e.target as HTMLElement) === false) {
                if (portalRoot.contains(e.target as HTMLElement) === false) {
                    if (close)
                        close()
                }
            }
        }
        window.addEventListener('mousedown', handleWindowClick);
        return () => {
            window.removeEventListener('mousedown', handleWindowClick);
        }
    }, [close])

    if (isOpen)
        return <div className={classNames("modal-container ", containerClassName
        )}>
            {createPortal(
                <ModalCard width={width}
                    style={{ minHeight }}
                    className={classNames('modal-container-card', cardClassName)}
                    ref={modalCardRef}
                >
                    {children}
                </ModalCard>, portalRoot)}
        </div>

    return null
}

export default Modal