import { ReactNode, useCallback, useMemo, useState } from 'react'
import SnackItem from './components/SnackItem'
import { IOpenSnackProps, ISnackItem } from './meta/types'
import './assets/Snackbar.scss'
import { SnackbarContext } from './context/SnackbarContext'

type Props = {
    children: ReactNode | ((props: { open: (snack: IOpenSnackProps) => void }) => ReactNode);
}

function Snackbar({
    children
}: Props) {
    const [snacks, setSnacks] = useState<ISnackItem[]>([])

    const open = useCallback((input: IOpenSnackProps) => {
        setSnacks(snacks => {
            const snack: ISnackItem = {
                color: input.color,
                timeout: input.timeout,
                content: input.content,
                id: new Date().getTime()
            }
            return snacks.concat(snack)
        })
    }, [])

    const handleTimeOut = useCallback((id: number) => {
        setSnacks(prev => prev.filter(item => item.id !== id))
    }, [])

    const contextValue = useMemo(() => ({ open }), [open])

    return (
        <SnackbarContext.Provider value={contextValue}>
            <div className='snackbar-container'>
                {typeof children === 'function' ? children({ open }) : children}
                <div className="snack-list">
                    {snacks.map((snack, index) => <SnackItem
                        onTimeEnd={handleTimeOut}
                        color={snack.color}
                        id={snack.id}
                        key={snack.id}
                        timeout={snack.timeout}
                        bottom={index * 56 + 16}
                    >
                        {snack.content}
                    </SnackItem>)}
                </div>
            </div>
        </SnackbarContext.Provider>
    )
}

export default Snackbar