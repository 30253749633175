import Card from 'components/Card/Card'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import React, { KeyboardEvent, ReactNode } from 'react'
import TextCore, { TextCoreProps } from '../TextCore/TextCore'
import { comboboxDefaultGetKey, comboboxDefaultGetLabel, comboboxDefaultRenderLabel } from './meta/utils'
import './assets/Combobox.scss'
import ComboboxItem from './components/ComboboxItem'
import classNames from 'classnames'

export interface IComboboxProps extends TextCoreProps {
    items?: any[];
    getItemKey?: (item: any) => string | number;
    getItemLabel?: (item: any) => ReactNode;
    renderLabel?: (item: any, index: number, isSelected?: boolean) => ReactNode;
}

function Combobox({
    items = [],
    getItemKey = comboboxDefaultGetKey,
    renderLabel = comboboxDefaultRenderLabel,
    getItemLabel = comboboxDefaultGetLabel,
    onChange,
    value,
    onBlur,
    onFocus,
    onClick,
    className,
    placeholder,
    ...textCoreProps
}: IComboboxProps) {

    const handleInputClick = (toggle: (event: React.MouseEvent<HTMLInputElement>) => void) =>
        (e: React.MouseEvent<HTMLInputElement>) => {
            if (onClick)
                onClick(e)
            toggle(e as any)
        }

    const handleItemClick = (selectedItem: any) => {
        if (onChange) {
            onChange(selectedItem)
        }
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        let selectedIndex;
        if (value) {
            selectedIndex = items.findIndex(item => getItemKey(item) === getItemKey(value))
        }
        if (e.key === 'ArrowDown') {
            if (selectedIndex !== null && selectedIndex !== undefined) {
                if (selectedIndex > -1) {
                    if (onChange) {
                        onChange(items[selectedIndex + 1 <= items.length - 1 ? selectedIndex + 1 : 0])
                    }
                }
            } else {
                if (onChange) {
                    onChange(items[0])
                }
            }
        } else if (e.key === 'ArrowUp') {
            if (selectedIndex !== null && selectedIndex !== undefined) {
                if (selectedIndex > -1) {
                    if (onChange) {
                        onChange(items[selectedIndex - 1 >= 0 ? selectedIndex - 1 : items.length - 1])
                    }
                }
            } else {
                if (onChange) {
                    onChange(items[items.length - 1])
                }
            }
        }
    }

    return (
        <DropdownMenu
            openButtonOfAnchor
            renderAnchor={({ toggle, close }) => <TextCore
                {...textCoreProps}
                className={classNames('combobox-text-core', className)}
                children={getItemLabel(value)}
                readOnly
                tag='div'
                right={<i className='icon-chevron-right' onClick={toggle} />}
                onKeyDown={handleKeyDown}
                onClickCapture={handleInputClick(toggle)}
                placeholder={placeholder}
            />}
        >
            {({ close }) => <Card className='combobox-items-list'>
                <ul>
                    {items.map((item, index) => {
                        const isSelected = getItemKey(item) === getItemKey(value);
                        return <ComboboxItem
                            key={getItemKey(item)}
                            getItemKey={getItemKey}
                            close={close}
                            index={index}
                            isSelected={isSelected}
                            item={item}
                            onClick={handleItemClick}
                            renderLabel={renderLabel}
                        />
                    })}
                </ul>
            </Card>}
        </DropdownMenu>
    )
}

export default Combobox