import classNames from 'classnames'
import React, { ChangeEvent, ElementType, HTMLProps, ReactNode, useRef, useState } from 'react'
import './assets/TextCore.scss'

export interface TextCoreProps extends Omit<HTMLProps<HTMLInputElement>, 'onChange'> {
    /**
     * can be textarea or input or your custom input
     * */
    tag?: ElementType
    /**
     * input, textarea element className
     * */
    inputClassName?: string;
    /**
     * when text is empty label is placeholder when is filled is gonna be the label
     * */
    inputLabel?: string;
    /**
     * you can put some element of string on left side of textCore
     * **/
    left?: ReactNode;
    /**
     * you can put some element of string on right side of textCore
     *  */
    right?: ReactNode;
    /**
     * error message to display
     *  */
    errorMessage?: string;
    /**
     * when there is error but you don't want to display error at the time with this flag you can 
     * display error any time you want
     */
    displayError?: boolean;
    /**
     * for controlled Inputs
     */
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

function TextCore({
    tag: Tag = 'input',
    inputLabel,
    onBlur,
    onFocus,
    onClick,
    inputClassName,
    className,
    left,
    right,
    value = '',
    placeholder,
    errorMessage,
    displayError = false,
    children,
    ...htmlPrpos
}: TextCoreProps) {

    const inputRef = useRef<HTMLInputElement>(null)
    const [isFocused, setIsFocused] = useState(false)
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true)
        if (onFocus)
            onFocus(e)
    }
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false)
        if (onBlur)
            onBlur(e)
    }

    const handleInputLabelClick = (e: React.MouseEvent<HTMLLabelElement>) => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        if (onClick) {
            onClick(e as any)
        }
        if (onFocus)
            onFocus(e as any)
    }

    return (
        <div className={classNames("text-core-container", className)}>
            <div className={classNames('text-core', {
                'is-focused': isFocused,
                'is-filled-text': Boolean(value || inputRef.current?.value || children) === true,
                'have-left-icon': left,
                'has-error': errorMessage && displayError
            })}>
                {inputLabel && <label
                    onClick={handleInputLabelClick}
                    className={classNames("input-lablel")}>
                    {inputLabel}
                </label>}
                <div className="text-core-input-wrapper">
                    {left && <div className="left">
                        {left}
                    </div>}
                    <Tag
                        ref={inputRef}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onClick={onClick}
                        tabIndex={1}
                        className={classNames('handle-input', inputClassName)}
                        placeholder={(isFocused || !inputLabel) ? placeholder : undefined}
                        value={value}
                        children={children}
                        {...htmlPrpos}
                    />
                    {right && <div className="right">
                        {right}
                    </div>}
                </div>
            </div>
            {errorMessage && displayError && <div className="error-message">
                <i className='icon icon-error' />
                <div className="message">
                    {errorMessage}
                </div>
            </div>}
        </div>
    )
}

export default TextCore