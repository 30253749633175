import classNames from 'classnames'
import Header from 'components/Header/Header'
import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import Sidebar from 'components/Sidebar/Sidebar'
import { AUTH_LOCAL_STORAGE_KEYS } from 'constants/auth'
import endpoints from 'constants/endpoints'
import useRequest from 'hooks/useRequest/useRequest'
import { ReactNode, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootState } from 'redux/store'
import { userSlice } from 'redux/user/userSlice'
import { IJwtTokenPayload } from 'types/IJwtTokenPayload'
import { IUser } from 'types/IUser'
import { getJWTPayload } from 'utils/string'
import './assets/Layout.scss'

type Props = {
  children: ReactNode;
}

function Layout({
  children
}: Props) {
  const nvaigate = useNavigate()
  const { pathname } = useLocation()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const autheticateState = useSelector<RootState>(state => state.user.autheticateState)

  const dispatch = useDispatch()
  const {
    callRequest
  } = useRequest<IUser>({
    url: endpoints.profile,
    callRequestOnInit: false
  })

  useLayoutEffect(() => {
    const jwt = localStorage.getItem(AUTH_LOCAL_STORAGE_KEYS.TOKEN);
    if (jwt) {
      const payload: IJwtTokenPayload = getJWTPayload(jwt);
      if (payload.claims === 'ADMIN') {
        nvaigate('/userList')
        dispatch(userSlice.actions.setUser({ userName: payload.username, isAdmin: true }))
      } else {
        callRequest().then((res) => {
          dispatch(userSlice.actions.setUser(res.data))
          if (pathname === '/' || pathname === '')
            nvaigate('/dashboard')
        })
      }
    } else {
      window.location.href = "/login";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callRequest])

  return (
    <div className={classNames("layout", { 'is-collapsed': isCollapsed })}>
      {autheticateState === 'NOT_SPECIFIED_YET' && <LoadingContainer>
        <CircleLoading width={200} height={200} />
      </LoadingContainer>}
      <div className="layout-container">
        <Sidebar onCollapseButtonClick={() => setIsCollapsed(prev => !prev)} />
        <div className="container">
          <Header />
          <div className="content">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout