import Button from 'components/Button/Button'
import BaseField from 'components/form/BaseField/BaseField'
import FieldWrapper from 'components/form/FieldWrapper/FieldWrapper'
import TextCore from 'components/form/TextCore/TextCore'
import Password from 'components/Password/Password'
import endpoints from 'constants/endpoints'
import SignInUpLayout from 'containers/SignInUpLayout/SignInUpLayout'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import './assets/SignUp.scss'
import { ISignUpModelForm } from './meta/types'
import { useNavigate } from 'react-router-dom';

function SignUp() {

  const navigate = useNavigate()

  const {
    callRequest: signUp
  } = useRequest({
    url: endpoints.register,
    callRequestOnInit: false,
    method: 'POST',
  })

  const form = useForm<ISignUpModelForm>({ mode: 'onTouched' })

  const snackbar = useSnackbar()

  const handleSubmit = (values: ISignUpModelForm) => {
    if (values.confirmPassword !== values.password) {
      snackbar.open({
        color: 'red',
        timeout: 3000,
        content: 'Confirm password is not match to password'
      })
    }
    signUp({ data: values }).then(() => {
      snackbar.open({
        color: 'green',
        timeout: 3000,
        content: 'Registration successful!'
      });
      navigate('/login')
    });
  }

  return (
    <SignInUpLayout className='sign-up'>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="d-flex p-2">
            <div className='sign-up'>
              <div className="d-flex">
                <h2 className='my-auto mt-6 ml-2'>
                  Create new Account
                </h2>
              </div>
              <FieldWrapper icon={<i className='icon icon-person' />} className='mt-6 col-12'>
                <BaseField
                  inputLabel='Firstname'
                  required
                  component={TextCore}
                  name="firstName"
                />
                <BaseField
                  inputLabel='Lastname'
                  required
                  className='ml-2'
                  component={TextCore}
                  name="lastName"
                />
              </FieldWrapper>
              <FieldWrapper icon={<i className='icon icon-email' />} className='mt-6 col-12'>
                <BaseField
                  inputLabel='Email'
                  placeholder='example@gmail.com'
                  isEmail
                  required
                  component={TextCore}
                  name="email"
                />
              </FieldWrapper>
              <FieldWrapper icon={<i className='icon icon-account' />} className='mt-6'>
                <BaseField
                  component={TextCore}
                  required
                  inputLabel='Username'
                  name="userName"
                  placeholder='only english charaters with numbers'
                />
              </FieldWrapper>
              <BaseField
                component={Password}
                name="password"
                required
                inputLabel={'Password'}
                className='mt-6'
              />
              <BaseField
                component={TextCore}
                name="confirmPassword"
                inputLabel='Confirm Password'
                className='mt-6'
                required
                type="password"
                right={<i className='icon icon-password' />}
              />
              <div className="d-flex justify-content-center mb-6">
                <Button type='submit' className='mt-6'>
                  Create Account
                </Button>
              </div>
            </div>
          </div>
          <div className="have-not-an-account mt-6">
            Already have an account? <Link to="/login">Sign In</Link>
          </div>
        </form>
      </FormProvider>
    </SignInUpLayout>
  )
}

export default SignUp