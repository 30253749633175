import AmountViewer from 'components/AmountViewer/AmountViewer';
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard';
import { ITableColumn } from 'components/Table/meta/types'
import Table from 'components/Table/Table';
import Tooltip from 'components/Tooltip/Tooltip';
import { IWalletTokenTransactionsListItem } from '../meta/types';

const columns: ITableColumn<IWalletTokenTransactionsListItem>[] = [
  {
    field: 'amount',
    header: 'Amount',
    render: (v) => <AmountViewer
      value={v}
    >
      {v}
    </AmountViewer>
  },
  {
    field: 'from',
    header: 'From',
    width: '165px',
    render: v => <div className="long-text d-flex">
      <div className="overflow-text-ellipsis" style={{ width: 165 }}>
        {v}
      </div>
      <CopyToClipboard tag={Tooltip} tooltipContent={'Copy'} textToCopy={v}>
        <i className='icon icon-copy cursor-pointer' />
      </CopyToClipboard>
    </div>
  },
  {
    field: 'to',
    header: 'To',
    width: '165px',
    render: v => <div className="long-text d-flex">
      <div className="overflow-text-ellipsis" style={{ width: 165 }}>
        {v}
      </div>
      <CopyToClipboard tag={Tooltip} tooltipContent={'Copy'} textToCopy={v}>
        <i className='icon icon-copy cursor-pointer' />
      </CopyToClipboard>
    </div>
  },
  {
    field: 'txId',
    header: 'Transaction ID',
    width: '200px',
    render: v => <div className="long-text d-flex">
      <div className="overflow-text-ellipsis" style={{ width: 200 }}>
        {v}
      </div>
      <CopyToClipboard tag={Tooltip} tooltipContent={'Copy'} textToCopy={v}>
        <i className='icon icon-copy cursor-pointer' />
      </CopyToClipboard>
    </div>
  }
];

type Props = {
  data: IWalletTokenTransactionsListItem[];
}

function WalletTokenTransactionsList({
  data
}: Props) {
  return (
    <Table
      columns={columns}
      data={data}
    />
  )
}

export default WalletTokenTransactionsList