import React, { ReactNode } from 'react'
import { ListContainerContext } from './context/ListContainerContext'
import { IListContainerContextProps } from './meta/types'

interface Props extends IListContainerContextProps {
    children: ReactNode;
}

function ListContainer({
    children,
    ...value
}: Props) {
  return (
    <ListContainerContext.Provider value={value}>
        {children}
    </ListContainerContext.Provider>
  )
}

export default ListContainer