import API from "API";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { CallRequestType, IUseRequestState } from "./meta/types";
import isEqual from "react-fast-compare";
import useSnackbar from "containers/Snackbar/hooks/useSnackbar";

export interface UseRequestProps extends AxiosRequestConfig {
  callRequestOnInit?: boolean;
  snackErrors?: boolean;
}

function useRequest<T = any, D = any>({
  callRequestOnInit = true,
  data,
  params,
  snackErrors = true,
  ...requestConfig
}: UseRequestProps) {
  const previusData = useRef(data);
  const previusParams = useRef(params);
  const snackbar = useSnackbar();

  const hasRequestCalled = useRef<boolean>(callRequestOnInit === false);
  const hasChangeEffectCalledForFirstTime = useRef<boolean>(false);

  const [state, setState] = useState<IUseRequestState<T | undefined>>({});

  const callRequest = useCallback<CallRequestType<T, D>>(
    async (newConfig?: AxiosRequestConfig<D>) => {
      try {
        setState({ pending: true });
        const res = await API({
          ...requestConfig,
          data,
          ...newConfig,
          params: { ...params, ...newConfig?.params },
        });
        setState({ pending: false, response: res, responseData: res.data });
        return Promise.resolve(res);
      } catch (err: any) {
        setState({ pending: false });
        if (snackErrors) {
          const responseData = err?.response?.data;
          if (responseData && responseData?.error) {
            snackbar.open({
              color: "red",
              timeout: 4000,
              content: responseData?.error,
            });
          } else {
            snackbar.open({
              color: "red",
              timeout: 4000,
              content: "Error! please try later",
            });
          }
        }
        return Promise.reject(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, params, snackErrors, JSON.stringify(requestConfig)]
  );

  useLayoutEffect(() => {
    if (hasRequestCalled.current === false) {
      callRequest();
      hasRequestCalled.current = true;
    }
  }, [callRequest]);

  // change Effect
  useEffect(() => {
    if (hasChangeEffectCalledForFirstTime.current === true) {
      hasChangeEffectCalledForFirstTime.current = false;
      return;
    }
    let hasDataOrParamsChange = false;
    if (isEqual(data, previusData.current) === false) {
      previusData.current = data;
      hasDataOrParamsChange = true;
    }
    if (isEqual(params, previusParams.current) === false) {
      previusParams.current = params;
      hasDataOrParamsChange = true;
    }
    if (hasDataOrParamsChange) {
      callRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, params]);

  return {
    callRequest: callRequest as CallRequestType<T, D>,
    response: state.response as AxiosResponse<T>,
    responseData: state.responseData as T | undefined,
    ...state,
  };
}

export default useRequest;
