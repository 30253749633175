import React from 'react'
import { ITableColumn } from '../meta/types'

export interface ITableRowProps {
    row: any;
    columns: ITableColumn[];
    keyField?: string;
}

function TableRow({
    row,
    keyField = 'id',
    columns
}: ITableRowProps) {
    return (
        <tr key={row[keyField]}>
            {columns.map(column => <td key={(column.uniqueSortKey || column.field as string) + (row.id + '')}>
                {(() => {
                    if (column.render) {
                        const result = column.render(row[column.field], row)
                        if (
                            typeof result === 'object'
                            && result !== null
                            && !React.isValidElement(result)) {
                            return null
                        } else {
                            return result;
                        }
                    } else {
                        if (typeof row[column.field] === 'object'
                            && row[column.field] !== null
                            && !React.isValidElement(row[column.field])) {
                            return null
                        } else {
                            return row[column.field]
                        }
                    }
                })()}
            </td>)}
        </tr>
    )
}

export default TableRow