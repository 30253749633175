import CreditsCharts from 'components/CreditsCharts/CreditsCharts';
import { useRef } from 'react';
import { Observer } from 'utils/observer';
import CreditModal from './components/CreditModal/CreditModal';
import ServiceCallLogList from './components/ServiceCallLog/ServiceCallLogList';
import { CreditsContext } from './context/CreditsContext';

function Credits() {
    const observer = useRef(new Observer())
    return (
        <CreditsContext.Provider value={{ observer: observer.current }}>
            <div className='d-flex flex-wrap'>
                <div className='col-12'>
                    <div className="d-flex">
                        <h2 className='flex-grow-1'>
                            Service Call Logs
                        </h2>
                        <CreditModal />
                    </div>
                    <ServiceCallLogList />
                </div>
                <div className='col-12'>
                    <CreditsCharts />
                </div>
            </div>
        </CreditsContext.Provider>
    )
}

export default Credits