import classNames from 'classnames'
import React from 'react'
import '../assets/PasswordStrengeViewer.scss'
import { getPasswordStrengthColorByPowerValue } from '../meta/utils'

type Props = {
    max?: number;
    value: number;
    className?: string;
}

function PasswordStrengeViewer({
    max = 5,
    value = 0,
    className
}: Props) {
  return (
    <div className={classNames('password-strenge-viewer', className)}>
        <div style={{ width: `${(value / max) * 100}%` }} className={classNames("password-strenge-viewer-line", 
        getPasswordStrengthColorByPowerValue(value))}></div>
    </div>
  )
}

export default PasswordStrengeViewer