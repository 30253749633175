import { useCallback, useState } from "react";

export function useModalState<T = any>() {
    const [state, setState] = useState<{
        isOpen?: boolean,
        payload?: T
    }>({
        isOpen: false
    })
    const open = useCallback((payload?: T) => {
        setState({ isOpen: true, payload })
    }, [])
    const close = useCallback(() => {
        setState({ isOpen: false })
    }, [])
    const toggle = useCallback((payload?: T) => {
        setState(prev => ({ isOpen: !prev.isOpen, payload }))
    }, [])
    return {
        isOpen: state.isOpen,
        toggle,
        close,
        open,
        payload: state.payload
    }
}