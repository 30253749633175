import classNames from 'classnames'
import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import useRequest from 'hooks/useRequest/useRequest'
import { IWalletListModel } from 'pages/Wallet/meta/types'
import DashboardWalletListItem from './DashboardWalletListItem'

function DashboardWalletList() {
    const {
        responseData,
        pending
    } = useRequest<IWalletListModel | undefined>({
        url: endpoints.wallet.list,
        params: {
            pageSize: 4
        }
    })
    return (
        <div className='d-flex position-relative' style={{ height: 65 }}>
            {pending && <LoadingContainer>
                <CircleLoading />
            </LoadingContainer>}
            {responseData?.wallets.map((wallet, index) => <div
                key={wallet.wallet}
                className={classNames("col-3", {
                    // not last item
                    'pr-3': index < responseData.wallets.length - 1,
                    'pr-2': index === responseData.wallets.length - 1,
                })}>
                <DashboardWalletListItem
                    address={wallet.address}
                    key={wallet.wallet}
                    wallet={wallet.wallet}
                    createdAt={wallet.createdAt}
                    chain={wallet.chain}
                    id={wallet.id}
                    label={wallet.label}
                />
            </div>)}
        </div>
    )
}

export default DashboardWalletList