import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import useRequest from 'hooks/useRequest/useRequest'
import { CreditsContext } from 'pages/Credits/context/CreditsContext'
import { useContext, useEffect } from 'react'
import { IServiceCallStatistics } from 'types/IServiceCallStatistics'
import CreditsChartDounates from './components/CreditsChartDounates'

interface Props {
    className?: string;
}

function CreditsCharts({
    className
}: Props) {

    const { observer } = useContext(CreditsContext)

    const {
        responseData,
        pending,
        callRequest: refresh
    } = useRequest<IServiceCallStatistics | undefined>({
        url: endpoints.serviceCall.userGetCreditsStatistics,
        callRequestOnInit: true
    })

    useEffect(() => {
        const cb = () => {
            refresh()
        }
        observer.on('REFRESH_CHARTS', cb)
        return () => {
            observer.off(cb);
        }
    }, [observer, refresh])

    return (
        <div className={className}>
            <div className="position-relative">
                {pending && <LoadingContainer>
                    <CircleLoading />
                </LoadingContainer>}
                <CreditsChartDounates
                    creditsUsed={responseData?.creditsUsed}
                    totlaCredits={responseData?.totlaCredits}
                    remainingCredits={responseData?.remainingCredits}
                />
            </div>
        </div>
    )
}

export default CreditsCharts