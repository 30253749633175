import classNames from 'classnames'
import { HTMLProps } from 'react'
import './assets/Card.scss'

interface Props extends HTMLProps<HTMLDivElement> {

}

function Card({
    className,
    children,
    ...htmlProps
}: Props) {
  return (
    <div {...htmlProps} className={classNames('card', className)}>
        {children}
    </div>
  )
}

export default Card