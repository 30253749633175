import Button from 'components/Button/Button'
import CoinCombobox from 'components/CoinCombobox/CoinCombobox'
import BaseField from 'components/form/BaseField/BaseField'
import TextCore from 'components/form/TextCore/TextCore'
import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import { useListContext } from 'containers/ListContainer/hooks/useListContext'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { IWalletFormRequestBody, IWalletFormValues } from '../meta/types'

interface Props {
    closeModal: () => void;
}

function WalletCreateForm({
    closeModal
}: Props) {

    const { refreshList } = useListContext()

    const userName = useSelector((state: RootState) => state.user.user?.userName)

    const snackbar = useSnackbar()

    const {
        pending,
        callRequest,
    } = useRequest<any, IWalletFormRequestBody>({
        method: 'POST',
        callRequestOnInit: false
    })

    const handleSubmit = async (values: IWalletFormValues) => {
        if (userName) {
            await callRequest({
                url: endpoints.wallet.create(values.chain.id),
                data: {
                    label: values.label,
                }
            })

            refreshList()


            snackbar.open({
                content: 'Wallet successfuly created!',
                color: 'green',
                timeout: 3000
            })

            closeModal()
        }
    }

    const form = useForm<IWalletFormValues>()
    return (
        <FormProvider {...form}>
            <form className='px-3 position-relative' onSubmit={form.handleSubmit(handleSubmit)}>

                {pending && <LoadingContainer>
                    <CircleLoading />
                </LoadingContainer>}

                <h2 className='mb-6 mt-3 pt-1'>
                    Create New Wallet
                </h2>
                <BaseField
                    component={TextCore}
                    name="label"
                    required
                    className="mt-4"
                    inputLabel={'Wallet Name'}
                />

                <span className='note mt-2 d-block'>
                    Wallet Name can be for example BTC-Wallet, Eth-Wallet etc.
                </span>

                <BaseField
                    component={CoinCombobox}
                    inputLabel={'Chain'}
                    required
                    name="chain"
                    className="mt-4"
                />

                <div className="d-flex justify-content-center mt-6">
                    <Button type="submit">
                        Create New Wallet
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}

export default WalletCreateForm