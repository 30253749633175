import classNames from 'classnames'
import FieldWrapper from 'components/form/FieldWrapper/FieldWrapper'
import TextCore from 'components/form/TextCore/TextCore'
import { ChangeEvent, Fragment, useMemo, useState } from 'react'
import { getPasswordStrength } from 'utils/password'
import PasswordStrengeViewer from './components/PasswordStrengeViewer'

interface Props {
    className?: string;
    onChange?: (value?: string) => void;
    onBlur?: (e: any) => void;
    value?: string;
    errorMessage?: string;
    displayError?: boolean;
    inputLabel?: string;
    hasIcon?: boolean;
}

function Password({
    className,
    onBlur,
    onChange,
    value = '',
    errorMessage,
    displayError,
    inputLabel,
    hasIcon
}: Props) {

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    const handleVisibilityPasswordIconClick = () => {
        setIsPasswordVisible(v => !v)
    }

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(onChange) {
            onChange(e.target.value)
        }
    }

    const { message, power } = useMemo(() => getPasswordStrength(value), [value])

    return (
        <Fragment>
            <FieldWrapper icon={hasIcon ? <i className='icon icon-lock' /> : undefined} className={className}>
                <TextCore
                    inputLabel={inputLabel}
                    onBlur={onBlur}
                    onChange={handlePasswordChange}
                    className={classNames('password', { 'filled': Boolean(value) })}
                    value={value}
                    type={isPasswordVisible ? 'text' : "password"}
                    errorMessage={errorMessage}
                    displayError={displayError  }
                    placeholder='including letters, number, special characters'
                    right={<i
                        onClick={handleVisibilityPasswordIconClick}
                        className={classNames('icon button', {
                            'icon-visibility-off': isPasswordVisible === true,
                            'icon-visibility': isPasswordVisible === false
                        })}
                    />}
                />
            </FieldWrapper>
            <PasswordStrengeViewer
                className='mt-6'
                value={power}
            />
            {value && <div className="password-strength-message mt-1">
                {message}
            </div>}
        </Fragment>
    )
}

export default Password