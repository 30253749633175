import classNames from 'classnames'
import Pagination from 'components/Pagination/Pagination'
import React from 'react'

type Props = {
    className?: string;
    onPageChange: (page: number) => void;
    totalItems?: number;
    currenctPage?: number;
}

function TokenFactoryFooter({
    className,
    onPageChange,
    totalItems = 0,
    currenctPage = 0
}: Props) {
    return (
        <div className={classNames('flex-direction-row-reverse d-flex', className)}>
            <Pagination
                currenctPage={currenctPage}
                onPageChange={onPageChange}
                pageCount={totalItems / Number(process.env.REACT_APP_API_DEFAULT_PAGE_SIZE || 10)}
            />
        </div>
    )
}

export default TokenFactoryFooter