import { COLOR_TRANSLATOR } from '../meta/constants'
import { ICoinIconProps } from '../meta/types'

interface Props extends ICoinIconProps { }

function ETHIcon({
    color = 'primary',
    ...props
}: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill={color ? COLOR_TRANSLATOR[color] : 'none'} />
            <path d="M11.9988 4V9.91332L16.9968 12.1466L11.9988 4Z" fill="white" fillOpacity="0.602" />
            <path d="M11.9987 4L7 12.1466L11.9987 9.91332V4Z" fill="white" />
            <path d="M11.9988 15.9787V19.9967L17.0001 13.0774L11.9988 15.9787Z" fill="white" fillOpacity="0.602" />
            <path d="M11.9987 19.9967V15.9781L7 13.0774L11.9987 19.9967Z" fill="white" />
            <path d="M11.9988 15.0487L16.9968 12.1467L11.9988 9.91469V15.0487Z" fill="white" fillOpacity="0.2" />
            <path d="M7 12.1467L11.9987 15.0487V9.91469L7 12.1467Z" fill="white" fillOpacity="0.602" />
        </svg>
    )
}

export default ETHIcon