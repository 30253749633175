import { Link } from 'react-router-dom'

function DashboardWalletListHeader() {
    return (
        <div className='d-flex'>
            <h4 className='py-1 item-titles flex-grow-1'>
                Wallets
            </h4>
            <Link className='font-w-400 color-primary pr-2' to="/wallet">
                View all wallets
            </Link>
        </div>
    )
}

export default DashboardWalletListHeader