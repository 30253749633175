import axios from "axios";
import { AUTH_LOCAL_STORAGE_KEYS } from "constants/auth";
import endpoints from "constants/endpoints";
import { store } from "redux/store";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "https://service.exchange.glss.ir/",
});

API.interceptors.response.use(
  (res) => res,
  (error) => {
    if (
      error.response.status === 401 &&
      !error?.config?.url?.endsWith(endpoints.getToken) &&
      !error?.config?.url?.endsWith(endpoints.adminLogin)
    ) {
      if(store.getState().user.user?.isAdmin) {
        window.location.href = "/admin/login";
      } else {
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

if (localStorage.getItem(AUTH_LOCAL_STORAGE_KEYS.TOKEN) !== null)
  API.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
    AUTH_LOCAL_STORAGE_KEYS.TOKEN
  )}`;
API.defaults.headers.common["Content-Type"] = "application/json";

export default API;
