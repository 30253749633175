import { ITableColumn } from 'components/Table/meta/types'
import Table from 'components/Table/Table'
import moment from 'moment';
import { IServiceCallLog } from 'types/IServiceCallLog';

const columns: ITableColumn<IServiceCallLog>[] = [
  {
    field: 'createdAt',
    header: 'Date',
    render: v => v && moment(v).format('YYYY/M/D hh:mm')
  },
  {
    field: 'cost',
    header: 'Cost',
  },
  {
    field: 'serviceName',
    header: 'Service Name',
  }
];

type Props = {
  data?: IServiceCallLog[];
}

function ServiceCallLogListTable({
  data = []
}: Props) {
  return (
    <Table
      columns={columns}
      data={data}
    />
  )
}

export default ServiceCallLogListTable