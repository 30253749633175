export function getPasswordStrengthColorByPowerValue(power: number): 'red' | 'yellow' | 'light-green' | 'green' {
    if(power < 1) {
        return 'red'
    }
    if(power >= 2 && power < 4) {
        return 'yellow'
    }
    if(power >= 4 && power < 5) {
        return 'light-green'
    }
    if (power >= 5) {
        return 'green'
    }
    return 'red'
}