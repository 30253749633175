import Table from "components/Table/Table";
import { ITableColumn } from "components/Table/meta/types";
import moment from "jalali-moment";
import { IProposal } from "types/IProposal";

const columns: ITableColumn<IProposal>[] = [
  {
    field: "title",
    header: "Title",
  },
  {
    field: "Status",
    header: "Status",
  },
  {
    field: "createdAt",
    header: "Date Created",
    render: v => v && moment(Number(v) * 1000).format('YYYY/MM/DD HH:mm')
  },
  {
    field: "votesForNo",
    header: "Votes for No",
  },
  {
    field: "votesForYes",
    header: "Votes for Yes",
  },
];

type Props = {
  data?: IProposal[];
};

function ProposalListTable({ data = [] }: Props) {
  return <Table columns={columns} data={data} />;
}

export default ProposalListTable;
