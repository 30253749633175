import Chip from 'components/Chip/Chip';
import { ITableColumn } from 'components/Table/meta/types';
import Table from 'components/Table/Table'
import moment from 'moment'
import { IUserListItem } from '../meta/types';
import UserListConfirm from './UserListConfirm';
import UserListCreditsDialog from './UserListCreditsDialog';

const columns: ITableColumn<IUserListItem>[] = [
    {
        field: 'userName',
        header: 'User Name',
        width: '25%',
    },
    {
        field: 'userInfo',
        header: 'Fullname',
        width: '25%',
        render: (v?: { firstName: string, lastName: string }) => {
            if (v) {
                return v.firstName + ' ' + v.lastName
            }
            return '-'
        }
    },
    {
        field: 'confirmedByAdmin',
        header: 'Confirmed By Admin',
        width: '20%',
        render: v => v ? <Chip mode="success">
            Confirmed
        </Chip> : <Chip mode="error">
            Not Confirmed Yet
        </Chip>
    },
    {
        field: 'createdAt',
        header: 'Registiration Date',
        width: '20%',
        render: v => moment(v).format('YYYY/MM/DD hh:mm')
    },
    {
        field: 'id',
        header: 'Actions',
        width: '20%',
        render: (v, row) => {
            return <div className="d-flex">
                {row?.confirmedByAdmin === false && <UserListConfirm
                    userId={v}
                />}
                {row?.confirmedByAdmin && <UserListCreditsDialog
                    row={row}
                />}
            </div>
        }
    }
];

interface Props {
    data: IUserListItem[]
}

function UserListTable({
    data
}: Props) {
    return (
        <Table
            columns={columns}
            data={data}
        />
    )
}

export default UserListTable