import classNames from 'classnames'
import { HTMLProps } from 'react'
import './assets/PageLogo.scss'

interface Props extends Omit<HTMLProps<HTMLImageElement>, 'size'> {
    size?: 'sm' | 'md' | 'lg'
}

function PageLogo({
    size = 'md',
    className,
    ...imgProps
}: Props) {
    return (
        <img
            src='/logo.svg'
            className={classNames('page-logo', size, className)}
            alt='Blockchain'
            {...imgProps}
        />
    )
}

export default PageLogo