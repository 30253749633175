import UserWidget from 'components/UserWidget/UserWidget'
import './assets/Header.scss'

function Header() {
  return (
    <header className='header'>
      <div className="flex-grow-1">
      </div>
        <UserWidget />
    </header>
  )
}

export default Header