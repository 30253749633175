import Modal from 'components/Modal/Modal'
import { IAccessTokenCreateSuccessModel } from '../meta/types'
import AccessTokenCreateForm from './AccessTokenCreateForm'

type Props = {
    isOpen?: boolean;
    close: () => void;
    onCreateSuccessfuly?: (model: IAccessTokenCreateSuccessModel) => void;
}

function AccessTokenCreateModal({
    isOpen,
    close,
    onCreateSuccessfuly
}: Props) {
    return (
        <Modal close={close} isOpen={isOpen}>
            <AccessTokenCreateForm
                closeModal={close}
                onCreateSuccessfuly={onCreateSuccessfuly    }
            />
        </Modal>
    )
}

export default AccessTokenCreateModal