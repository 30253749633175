import { ElementType, ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export type BaseFieldProps = {
    /**
     * any component witch contain onChange, onBlur and value can be put here
     */
    component: ElementType;
    /**
     * name is Required and it's will be key of Values Object on Submit
     */
    name: string;
    /**
     * default Value at the first Time, when component rendered so far 
     * it's not gonna update when defaultValue change
     */
    defaultValue?: any;
    /**
     * when there is busness on Change of field you can use onChange
     */
    onChange?: (value?: any) => void;
    /**
     * if field is null, undefined or empty string it's will display error and dos'nt let form submit
     */
    required?: boolean;
    /**
     * if email is not a valid mail it's will display error and dos'nt let form submit
     */
    isEmail?: boolean;
    /**
     * custom validators
     */
    validators?: Record<string, (v: any) => ReactNode>,
    [rest: string]: any
}

function BaseField({
    component: Component,
    name,
    defaultValue,
    onChange,
    className,
    required,
    inputLabel,
    isEmail,
    validators,
    ...rest
}: BaseFieldProps) {
    const { control } = useFormContext()
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                validate: {
                    isRequired: (v) => {
                        if (required && (v === null || v === undefined || v === '')) {
                            return `${inputLabel} is Required`
                        }
                        return undefined;
                    },
                    isEmail: (v) => {
                        if (v && isEmail && /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) === false) {
                            return 'Email is\'not valid'
                        }
                    },
                    ...validators
                }
            }}
            defaultValue={defaultValue}
            render={({ field, fieldState: { isTouched, error } }) => {
                return <Component
                    {...rest}
                    displayError={isTouched}
                    errorMessage={error?.message}
                    value={field.value}
                    onBlur={field.onBlur}
                    className={className}
                    inputLabel={inputLabel}
                    onChange={(v: any) => {
                        if (onChange) {
                            onChange(v)
                        }
                        field.onChange(v)
                    }}
                />
            }}
        />
    )
}

export default BaseField