import React, { ReactNode, Children, useMemo, useState } from 'react'
import TabPanelHeader from './components/TabPanelHeader';
import { ITabPanelTabProps } from './meta/types';

type Props = {
    children: ReactNode;
    className?: string;
    defaultActive?: number | string;
}

function TabPanel({
    children,
    defaultActive,
    className
}: Props) {

    const tabList = useMemo<ITabPanelTabProps[]>(() => {
        const result: ITabPanelTabProps[] = [];
        Children.forEach(children, (item) => {
            if(React.isValidElement(item)) {
                result.push(item.props as ITabPanelTabProps)
            }
        })
        return result;
    }, [children])
    const [activeTab, setActiveTab] = useState<string | number>(() => {
        if(defaultActive)
            return defaultActive;
        if(tabList.length > 0) {
            if(tabList[0].id)
                return tabList[0].id 
            return 0
        } else {
            return 0
        }
    })
    const activeTabChildren = useMemo(() => tabList ? tabList.find((item) => item.id === activeTab) : null,[activeTab, tabList])
  return (
    <div className={className}>
        <TabPanelHeader 
            activeTabOrIndex={activeTab}
            tabs={tabList}
            onTabClick={setActiveTab}
        />
        {activeTabChildren?.children}
    </div>
  )
}

export default TabPanel