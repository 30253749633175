import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import ListContainer from 'containers/ListContainer/ListContainer'
import useRequest from 'hooks/useRequest/useRequest'
import { useState } from 'react'
import { IListModuleState } from 'types/IListModuleState'
import TokenFactoryFooter from './components/TokenFactoryFooter'
import TokenFactoryHeader from './components/TokenFactoryHeader'
import TokenFactoryList from './components/TokenFactoryList'
import { ITokenFactoryListModel, ITokenFactorySuccessDialogModalPayload } from './meta/types'
import TokenFactorySuccessDialog from './components/TokenFactorySuccessDialog'
import { useModalState } from 'components/Modal/hooks/useModalState'
import { TokenFactoryContext } from './context/TokenFactoryContext'

function TokenFactory() {

    const {
        close: closeSuccessfulModal,
        isOpen: isSuccessfulModalOpen,
        payload: successPayload,
        open: openCreateSuccessDialog
    } = useModalState<ITokenFactorySuccessDialogModalPayload>()

    const [state, setState] = useState<IListModuleState>({
        page: 0
    })

    const {
        responseData,
        pending,
        callRequest
    } = useRequest<ITokenFactoryListModel | undefined>({
        url: endpoints.token.tokens,
        params: { page: state.page }
    })

    const handlePageChange = (page: number) => {
        setState({ page })
    }

    return (
        <ListContainer
            page={state.page}
            refreshList={callRequest}
        >
            <TokenFactoryContext.Provider value={{ openCreateSuccessDialog }}>
                {successPayload?.tokenAddress &&
                    successPayload?.chain &&
                    isSuccessfulModalOpen && <TokenFactorySuccessDialog
                        closeModal={closeSuccessfulModal}
                        chain={successPayload.chain}
                        tokenAddress={successPayload.tokenAddress}
                    />}
                <div className='position-relative'>
                    {pending && <LoadingContainer>
                        <CircleLoading />
                    </LoadingContainer>}
                    <TokenFactoryHeader />
                    <TokenFactoryList
                        data={responseData?.tokens}
                        className='mt-4'
                    />
                    <TokenFactoryFooter
                        onPageChange={handlePageChange}
                        currenctPage={state.page}
                        totalItems={responseData?.totalCount}
                        className='mt-4'
                    />
                </div>
            </TokenFactoryContext.Provider>
        </ListContainer>
    )
}

export default TokenFactory