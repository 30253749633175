import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserInfoFormValues } from "components/UserInfoForm/meta/types";
import { AUTH_LOCAL_STORAGE_KEYS } from "constants/auth";
import { IUser } from "types/IUser";
import { AutheiticateType, IUserState } from "./types";

const initialState: IUserState = {
  loading: false,
  user: null,
  autheticateState: "NOT_SPECIFIED_YET",
};

export const userSlice = createSlice({
  initialState: initialState,
  name: "USER_SLICE",
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
      state.autheticateState = "AUTHENTICATED";
    },

    setUserInfo: (state, action: PayloadAction<IUserInfoFormValues>) => {
      if (state.user) {
        state.user.userInfo = action.payload;
      }
    },
    setAuthenticateState: (state, action: PayloadAction<AutheiticateType>) => {
      state.autheticateState = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.autheticateState = "NOT_AUTHENTICATED";
      localStorage.removeItem(AUTH_LOCAL_STORAGE_KEYS.TOKEN);
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getProfileThunk.fulfilled, (state, action) => {
    //     if (action.payload?.user)
    //         state.user = action.payload?.user;
    //     state.loading = false;
    //     state.autheticateState = 'AUTHENTICATED';
    // }).addCase(getProfileThunk.pending, (state) => {
    //     state.loading = true;
    // }).addCase(getProfileThunk.rejected, (state) => {
    //     state.loading = false;
    //     state.autheticateState = 'NOT_AUTHENTICATED';
    // })
  },
});
