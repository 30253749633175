import classNames from 'classnames'
import Button from 'components/Button/Button'
import { useModalState } from 'components/Modal/hooks/useModalState'
import Modal from 'components/Modal/Modal'
import { WalletDetailContext } from 'pages/WalletDetail/context/WalletDetailContext'
import { Fragment, useContext } from 'react'
import WalletAssetsWithdrawalForm from './WalletAssetsWithdrawalForm'

type Props = {
    tokenAddress: string;
    className?: string;
}

function WalletAssetsWithdrawalDialog({
    tokenAddress,
    className
}: Props) {

    const { wallet } = useContext(WalletDetailContext)

    const {
        close,
        isOpen,
        open
    } = useModalState()
    return (
        <Fragment>
            <Button onClick={open} className={classNames('d-flex', className)} color='primary-color-lighter'>
                <i className='md-icon icon-upload' />
                <div className="title my-auto">
                    Transfer
                </div>
            </Button>
            {isOpen && <Modal isOpen={isOpen} close={close}>
                <WalletAssetsWithdrawalForm
                    chain={wallet.chain}
                    tokenAddress={tokenAddress}
                    walletAddress={wallet.address}
                    closeModal={close}
                />
            </Modal>}
        </Fragment>
    )
}

export default WalletAssetsWithdrawalDialog