import { ColorType } from 'types/ColorType'
import BTCIcon from './components/BTCIcon'
import CELOIcon from './components/CELOIcon'
import DOGEIcon from './components/DOGEIcon'
import ETHIcon from './components/ETHIcon'
import { ICoinIconProps } from './meta/types'

interface Props extends ICoinIconProps {
  color?: ColorType;
  icon: string;
}

function CoinIcon({
  icon,
  ...props
}: Props) {
  switch (icon) {
    case 'BTC':
      return <BTCIcon {...props} />
    case 'ETH':
      return <ETHIcon {...props} />
    case 'DOGE':
      return <DOGEIcon {...props} />
    case 'CELO':
      return <CELOIcon {...props} />
    default:
      return null
  }
}

export default CoinIcon