import endpoints from 'constants/endpoints'
import Login from 'pages/Login/Login'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import { IAuthResponseData, ILoginFormValues } from 'pages/Login/meta/types'
import { useDispatch } from 'react-redux'
import { userSlice } from 'redux/user/userSlice'

function AdminLogin() {
    const navigate = useNavigate()
    const snackbar = useSnackbar()
    const dispatch = useDispatch()

    const handleFailur = (error: any, values: ILoginFormValues) => {
        if (error?.response) {
            const axiosError: AxiosError = error;
            if (axiosError.response?.status === 401 &&
                axiosError.response?.data?.name === 'you.must.change.your.password.to.continue'
            ) {
                navigate(`/admin/forceChangePassword?userName=${values.userName}`)
            } else {
                snackbar.open({ color: 'red', timeout: 3000, content: 'Username or password is wrong!' })
            }
        } else {
            snackbar.open({ color: 'red', timeout: 3000, content: 'Error occured' })
        }
    }

    const handleSuccessLogin = (data: IAuthResponseData, values: ILoginFormValues) => {
        navigate('/userList');
        dispatch(userSlice.actions.setUser({ ...values, isAdmin: true }))
    }

    return (
        <Login
            displaySlider={false}
            url={endpoints.adminLogin}
            onSuccess={handleSuccessLogin}
            title={<h2 className='d-block mt-2'>Sign in</h2>}
            onFailur={handleFailur}
        />
    )
}

export default AdminLogin