import UseAndRemainChart from 'components/charts/UseAndRemainChart/UseAndRemainChart'
import React from 'react'

type Props = {
    totlaCredits?: number;
    creditsUsed?: number;
    remainingCredits?: number;
}

function CreditsChartDounates({
    creditsUsed = 0,
    totlaCredits = 0,
    remainingCredits = 0
}: Props) {
    return (
        <div className="d-flex justify-content-space-around">
            <UseAndRemainChart
                className='col-6'
                title='Credits Used'
                maximumValue={totlaCredits}
                usedValue={creditsUsed}
                usedLabel='Used Credits'
                maximumLabel='Total Credits'
            />
            <UseAndRemainChart
                className='col-6'
                title='Credits Remaining'
                maximumValue={totlaCredits}
                usedValue={remainingCredits}
                usedLabel='Remaining Credits'
                maximumLabel='Total Credits'
            />
        </div>
    )
}

export default CreditsChartDounates