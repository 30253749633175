import { useModalState } from 'components/Modal/hooks/useModalState'
import Modal from 'components/Modal/Modal'
import { Fragment } from 'react'
import { IToken } from 'types/IToken'
import TextCore, { TextCoreProps } from '../TextCore/TextCore'
import TokenSelectList from './components/TokenSelectList'

interface Props extends Omit<TextCoreProps, 'onChange' | 'value'> {
    onChange?: (token: IToken) => void;
    value?: IToken
}

function TokenSelect({
    onChange,
    value,
    ...props
}: Props) {
    const { isOpen, open, close } = useModalState()
    return (
        <Fragment>
            <TextCore onClick={open} value={value?.name} {...props} />
            <Modal isOpen={isOpen} close={close}>
                <h3 className='mb-4'>
                    Select Token
                </h3>
                <TokenSelectList close={close} selectedTokenId={value?.id} onTokenClick={onChange} />
            </Modal>
        </Fragment>
    )
}

export default TokenSelect