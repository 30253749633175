import Button from "components/Button/Button";
import BaseField from "components/form/BaseField/BaseField";
import TextCore from "components/form/TextCore/TextCore";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IProposalData, IProposalForm } from "../meta/types";

import useRequest from "hooks/useRequest/useRequest";
import endpoints from "constants/endpoints";
import LoadingContainer from "components/LoadingContainer/LoadingContainer";
import { useParams } from "react-router-dom";
import { useListContext } from "containers/ListContainer/hooks/useListContext";
import useSnackbar from "containers/Snackbar/hooks/useSnackbar";
import CircleLoading from "components/Loading/Loading";

type Props = {
  close: () => void;
};

function ProposalListForm({ close }: Props) {
  const form = useForm<IProposalForm>();
  const { address = "" } = useParams<{ address: string }>();
  const {
    callRequest: send,
    pending,
  } = useRequest<any, IProposalData>({
    url: endpoints.dao.createPorpose,
    callRequestOnInit: false,
    method: 'POST'
  });

  const snackbar = useSnackbar();

  const { refreshList } = useListContext();

  const handleSubmit = async (values: IProposalForm) => {
    await send({
      data: {
        contractAddress: address,
        title: values?.title,
      },
    });
    snackbar.open({
      color: "green",
      timeout: 3000,
      content: "Proposal Created successfuly",
    });
    refreshList();
    close();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="position-relative">
        {pending && (
            <LoadingContainer>
                <CircleLoading />
            </LoadingContainer>
        )}
        <h2>Create Proposal</h2>
        <BaseField
          component={TextCore}
          name="title"
          inputLabel="Title"
          className="mt-4"
        />

        <div className="d-flex mt-4 flex-direction-row-reverse">
          <Button type="submit">Create</Button>
        </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default ProposalListForm;
