import PageLogo from 'components/PageLogo/PageLogo'
import '../assets/SidebarHeader.scss'

interface Props {
  onCollapseButtonClick: () => void;
}

function SidebarHeader({
  onCollapseButtonClick
}: Props) {
  return (
    <div className='sidebar-header'>
        <PageLogo size='sm' />
        <div className="sidebar-header-brandname">
            <span className="primary-color-brandname">
                {'Blockchain '}
            </span>
            <span>
                {'Dashboard'}
            </span>
        </div>
        <i onClick={onCollapseButtonClick} className="collapse-icon icon-menu-open"></i>
    </div>
  )
}

export default SidebarHeader