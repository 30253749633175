import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard';
import ThreeDotsLoading from 'components/Loading/ThreeDotsLoading';
import Tooltip from 'components/Tooltip/Tooltip';
import { useChains } from 'containers/ChainProvider/hooks/useChains';
import { useEffect, useState } from 'react';
import './assets/TokenAddressLink.scss';

type Props = {
    chain: string;
    tokenAddress: string;
    displayLink?: boolean;
}

function TokenAddressLink({
    chain,
    tokenAddress,
    displayLink
}: Props) {
    const [state, setState] = useState<{ pending: boolean, link?: string }>({
        pending: false
    });

    const { getChains } = useChains()

    useEffect(() => {
        getChains().then((chains) => {
            const activeChain = chains.find(item => item.name === chain)
            if (activeChain) {
                setState({ link: activeChain.getTokenLink(tokenAddress), pending: false })
            } else {
                setState({ pending: false })
            }
        })
    }, [chain, getChains, tokenAddress])

    if (state.pending)
        return <ThreeDotsLoading />

    if (state.link)
        return <div className="d-flex">
            <a
                href={state.link}
                target={'_blank'}
                rel="noreferrer"
                className='p-2 contract-address-link'
            >
                {displayLink ? state.link?.slice(0, 50) + '...' : tokenAddress}
            </a>
            <CopyToClipboard
                tag={Tooltip}
                tooltipContent={'Copy'}
                textToCopy={tokenAddress}
                className='d-flex'
            >
                <i className='icon icon-copy cursor-pointer my-auto' />
            </CopyToClipboard>
        </div>
    return <div className='d-flex'>
        {tokenAddress}
        <CopyToClipboard
            tag={Tooltip}
            tooltipContent={'Copy'}
            textToCopy={tokenAddress}
            className='d-flex ml-2'
        >
            <i className='icon icon-copy cursor-pointer my-auto' />
        </CopyToClipboard>
    </div>
}

export default TokenAddressLink