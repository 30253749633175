import Button from 'components/Button/Button'
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard'
import QrCode from 'components/QrCode/QrCode'
import { WalletDetailContext } from 'pages/WalletDetail/context/WalletDetailContext'
import { useContext } from 'react'
import '../assets/WalletAssetsDepositDialog.scss'

function WalletAssetsDeposit() {
    const { wallet } = useContext(WalletDetailContext)
    return (
        <div className='wallet-assets-deposit'>
            <h3>
                Deposit Token
            </h3>
            <div className="d-flex">
                <div className="p-3 mt-4 address-container mx-auto text-center">
                    <h3>
                        Wallet Address
                    </h3>
                    <QrCode
                        width={300}
                        height={300}
                        value={wallet.address}
                    />
                    {wallet.address}

                    <div className="d-flex mt-3 justify-content-space-around">
                        <CopyToClipboard textToCopy={wallet.address} tag={Button} className='d-flex px-3'>
                            <i className='icon icon-copy font-md mr-1' /> Copy
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletAssetsDeposit