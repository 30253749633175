import React, { ChangeEvent, FocusEvent, KeyboardEvent, useCallback } from 'react'
import { numberWithCommas } from 'utils/string';
import TextCore, { TextCoreProps } from '../TextCore/TextCore'
import NumberCoreUpDownController from './components/NumberCoreUpDownController';

export interface INumberCoreProps extends TextCoreProps {

    /**
     * add commos on 1000 diveds
     */
    hasSeperator?: boolean;

    /**
     * maximum decimals user can enter 
     */
    decimalsCount?: number;
    /**
     * to dusplay up and down controller
     */
    hasUpDownController?: boolean;
    /**
     * can negative
     */
    canBeNegative?: boolean;
}

function NumberCore({
    onChange,
    onBlur,
    hasSeperator = false,
    decimalsCount = 0,
    value,
    hasUpDownController = false,
    min,
    max,
    canBeNegative,
    ...props
}: INumberCoreProps) {

    const computeFinalNumber = useCallback<(value: string) => string | undefined>((valueEntered: string) => {
        // trim value
        // remove , for hasSeperator and split number for decimals
        const arrayOfDotsSpleted = valueEntered.replace(/,/g, '').split('.')

        // the if below would be true if there is more than 1 DOT text contains
        if (arrayOfDotsSpleted.length > 2)
            return;

        if (valueEntered === '') {
            return valueEntered;
        }

        // sperate array
        let [value, decimals] = arrayOfDotsSpleted;

        // check is Number
        if (isNaN(Number(value)) === true) {
            return;
        }
        // check is decimals number for example, not to be 1.AB
        if (decimals && isNaN(Number(decimals)) === true) {
            return;
        }
        /** add commas of seperator */
        if (hasSeperator) {
            value = numberWithCommas(value)
        }
        if (decimalsCount > 0) {
            /**
             * when user enter for example 123 decimals would be undefined but
             * if enter 123. he/she would except to hold it to enter other decimals
             * for that we not consider 123. invalid but if user left that "123." we remove that DOT
             * when onBlur trigger
            **/
            if (decimals !== undefined) {
                value = value + '.' + decimals.slice(0, decimalsCount);
            }
        }
        return value;
    }, [decimalsCount, hasSeperator])

    const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement> | string) => {
        if (onChange) {
            if (typeof e === 'string') {
                (onChange as any)(e);
                return;
            }
            const valueEntered = e.currentTarget.value;
            const finalValue = computeFinalNumber(valueEntered);
            if (finalValue || finalValue === '') {
                e.currentTarget.value = finalValue;
                onChange(e)
            }
        }
    }, [computeFinalNumber, onChange])



    const handleInputBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.endsWith('.')) {
            e.currentTarget.value = e.currentTarget.value.replace(/\./g, '')
            if (onChange) {
                onChange(e)
            }
        }
        if (onBlur) {
            onBlur(e)
        }
    }, [onBlur, onChange])

    const handleUp = () => {
        const valueNumber: number = Number(value);
        if (isNaN(valueNumber) === false) {
            const finalNumber = computeFinalNumber((valueNumber + 1).toString())
            if (finalNumber) {
                if (onChange) {
                    (onChange as any)(finalNumber.toString())
                }
            }
        } else {
            // default Value when user hint up
            if (onChange) {
                (onChange as any)('1');
            }
        }
    }

    const handleDown = () => {
        const valueNumber: number = Number(value);
        if (isNaN(valueNumber) === false) {
            const finalNumber = computeFinalNumber((valueNumber - 1).toString())
            if (Number(finalNumber) >= 0) {
                if (onChange)
                    (onChange as any)(finalNumber?.toString())
            }
        }
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'ArrowUp') {
            handleUp()
        } else if (e.key === 'ArrowDown') {
            handleDown()
        }
    }

    return (
        <TextCore
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            value={value}
            onKeyDown={handleKeyDown}
            {...props}
            right={props?.right ? props.right : hasUpDownController && <NumberCoreUpDownController
                onDownClick={handleDown}
                onUpClick={handleUp}
            />}
        />
    )
}

export default NumberCore