import classNames from 'classnames'
import { HTMLProps, useRef, useEffect, useCallback } from 'react'
import { ISnackItem } from '../meta/types'

interface Props extends Omit<HTMLProps<HTMLDivElement>, 'id' | 'color'>, Omit<ISnackItem, 'content'> {
    onTimeEnd: (id: number) => void;
    bottom?: number;
}

function SnackItem({
    className,
    children,
    onTimeEnd,
    timeout,
    bottom,
    color,
    id,
    ...props
}: Props) {

    const startTimeRef = useRef<number>(new Date().getTime())
    const timeReachedRef = useRef<number>()
    const intervalCounterRef = useRef<number>(0)

    const timelineRef = useRef<HTMLDivElement>(null)
    const timelineContainerRef = useRef<HTMLDivElement>(null)

    const intervalRef = useRef<any>()
    const timeoutRef = useRef<any>()

    const startInterval = useCallback(() => {
        return setInterval(() => {
            const count = timeout / 10;
            if (timelineRef.current && timelineContainerRef.current) {
                const width = timelineContainerRef.current.getBoundingClientRect().width;
                timelineRef.current.style.width = `${(intervalCounterRef.current++) * (width / count)}px`;
            }
        }, 10)
    }, [timeout])

    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            onTimeEnd(id)
        }, timeout)
        intervalRef.current = startInterval()
        return () => {
            clearInterval(intervalRef.current)
            clearTimeout(timeoutRef.current)
        }
    }, [id, onTimeEnd, startInterval, timeout])

    const stopTimer = () => {
        timeReachedRef.current = (timeReachedRef.current ?? 0) + new Date().getTime() - startTimeRef.current;
        clearTimeout(timeoutRef.current)
        clearInterval(intervalRef.current)
    }

    const startTimerAgain = () => {
        startTimeRef.current = new Date().getTime();
        if (timeReachedRef.current) {
            timeoutRef.current = setTimeout(() => {
                onTimeEnd(id)
            }, timeout - timeReachedRef.current)
            intervalRef.current = startInterval()
        }
    }

    return (
        <div
            onMouseOver={stopTimer}
            onMouseLeave={startTimerAgain}
            className={classNames('snack-item', className, color)}
            style={{ bottom }}
            {...props}
        >
            <div className="snack-item-content">
                {children}
            </div>
            <div ref={timelineContainerRef} className="timeline-container">
                <div ref={timelineRef} className="timeline"></div>
            </div>
        </div>
    )
}

export default SnackItem