import { useModalState } from 'components/Modal/hooks/useModalState'
import endpoints from 'constants/endpoints'
import ListContainer from 'containers/ListContainer/ListContainer'
import useRequest from 'hooks/useRequest/useRequest'
import { useState } from 'react'
import { IListModuleState } from 'types/IListModuleState'
import ServiceCostModal from './components/ServiceCostModal'
import ServiceCostList from './components/ServiceCostList'
import { IServiceCostListModel } from './meta/types'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import CircleLoading from 'components/Loading/Loading'
import ServiceCostFooter from './components/ServiceCostFooter'

function ServiceCost() {

    const [state, setState] = useState<IListModuleState>({
        page: 0
    })

    const {
        close,
        isOpen,
        open,
        payload: serviceCostId
    } = useModalState<number>()

    const {
        responseData,
        pending,
        callRequest
    } = useRequest<IServiceCostListModel | undefined>({
        url: endpoints.serviceCall.getServiceCostList,
        params: {
            page: state.page
        }
    })

    const handlePageChange = (page: number) => {
        setState(prev => ({ ...prev, page }))
    }

    return (
        <ListContainer
            page={state.page}
            refreshList={callRequest}
        >
            {pending && <div className="position-relative">
                <LoadingContainer>
                    <CircleLoading />
                </LoadingContainer>
            </div>}
            {serviceCostId && isOpen && <ServiceCostModal
                closeModal={close}
                serviceCostId={serviceCostId}
            />}
            <h2>
                Service Cost
            </h2>
            <div className="mt-4">
                <ServiceCostList
                    openForm={open}
                    data={responseData?.list}
                />
            </div>
            <ServiceCostFooter
                onPageChange={handlePageChange}
                currenctPage={state.page}
                totalItems={responseData?.totalCount}
            />
        </ListContainer>
    )
}

export default ServiceCost