import Card from 'components/Card/Card'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import TextCore, { TextCoreProps } from '../TextCore/TextCore'
import DatePickerBody from './components/DatePickerBody'
import DatePickerHeader from './components/DatePickerHeader'
import useCalender from './hooks/useCalender'
import { CalenderModeType, IDays } from './meta/types'
import './assets/DatePicker.scss'

interface Props extends Omit<TextCoreProps, 'onChange' | 'value'> {
    onChange?: (value: string) => void;
    value?: string;
}

function Datepicker({
    onClick,
    onChange,
    onBlur,
    value: inputValue,
    ...props
}: Props) {
    const [date, setDate] = useState(moment());
    const daysInMonth = useCalender({ date })
    const [value, setValue] = useState(inputValue ? moment(inputValue).format('YYYY/MM/DD') : '')
    const [calenderMode, setCalenderMode] = useState<CalenderModeType>('DAY')
    const hasRendered = useRef<boolean>(true);

    useEffect(() => {
        if (hasRendered.current) {
            hasRendered.current = false;
            return;
        }
        setValue(inputValue ? moment(inputValue).format('YYYY/MM/DD') : '')
    }, [inputValue])
    const handleMonthChange = useCallback((selectedMonth: number) => {
        setDate(d => {
            return d.clone().month(selectedMonth) as Moment;
        })
    }, [])

    const handleYearChange = useCallback((year: number) => {
        setDate(d => {
            return d.clone().year(year) as Moment;
        })
    }, [])

    const handleDayClick = useCallback((day: IDays) => {
        onChange && onChange(day.enDate);
    }, [onChange])

    return (
        <DropdownMenu
            openButtonOfAnchor
            renderAnchor={({ open }) => <TextCore {...props} onClick={(e) => {
                if (onClick)
                    onClick(e)
                open(e)
            }}
                value={value}
            />}

            contentWidth={300}
        >
            {({ close }) => {
                return <Card>
                    <DatePickerHeader
                        onYearChange={handleYearChange}
                        onMonthChange={handleMonthChange}
                        year={daysInMonth.year}
                        month={daysInMonth.month}
                        calenderMode={calenderMode}
                        onCalenderModeChange={setCalenderMode}
                        monthName={daysInMonth.monthName}
                    />
                    <DatePickerBody
                        calenderMode={calenderMode}
                        daysInMonth={daysInMonth}
                        onDayClick={(e) => {
                            handleDayClick(e)
                            close()
                        }}
                        selectedDate={inputValue}
                        date={date}
                    />
                </Card>
            }}
        </DropdownMenu>
    )
}

export default Datepicker