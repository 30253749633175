import ListContainer from "containers/ListContainer/ListContainer";
import React, { useCallback, useState } from "react";
import { IListModuleState } from "types/IListModuleState";
import ProposalListTable from "./components/ProposalListTable";
import LoadingContainer from "components/LoadingContainer/LoadingContainer";
import CircleLoading from "components/Loading/Loading";
import ProposalListHeader from "./components/ProposalListHeader";
import { IProposalFilter, IProposalList } from "./meta/types";
import useDebounce from "hooks/useDebounce";
import useRequest from "hooks/useRequest/useRequest";
import endpoints from "constants/endpoints";
import { useParams } from "react-router-dom";
import ProposalListFooter from "./components/ProposalListFooter";

function ProposalList() {
  const { address = "" } = useParams<{ address: string }>();

  const [state, setState] = useState<IListModuleState>({
    page: 0,
  });

  const [filters, setFilters] = useState<IProposalFilter>({});

  const handleFilterChange = useCallback(
    (filters: Partial<IProposalFilter>) => {
      setFilters((prev) => ({ ...prev, ...filters }));
    },
    []
  );

  const debounceFilters = useDebounce(filters, 500);

  const { responseData, pending, callRequest } = useRequest<
    IProposalList | undefined
  >({
    url: endpoints.dao.getDaoByAddress("CELO", address),
    callRequestOnInit: true,
    params: {
      ...debounceFilters,
      page: state.page,
    },
  });
  return (
    <ListContainer refreshList={callRequest} page={state.page}>
      <div className="position-relative">
        {pending && (
          <LoadingContainer>
            <CircleLoading />
          </LoadingContainer>
        )}
      </div>
      <ProposalListHeader changeEvent={handleFilterChange} filters={filters} />
      <ProposalListTable data={responseData?.proposals} />
      <ProposalListFooter
        currenctPage={state.page}
        totalItems={responseData?.totalCount}
        onPageChange={(page) => setState({ page })}
      />
    </ListContainer>
  );
}

export default ProposalList;
