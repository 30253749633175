import Button from "components/Button/Button";
import ThreeDotsLoading from "components/Loading/ThreeDotsLoading";
import endpoints from "constants/endpoints";
import useRequest from "hooks/useRequest/useRequest";
import React from "react";

type Props = {
  chain: string;
  contractAddress: string;
};

function DaoListProposalCount({ chain, contractAddress }: Props) {
  const { responseData, callRequest, pending } = useRequest<{
    totalCount: number;
  }>({
    url: endpoints.dao.getDaoByAddress(chain, contractAddress),
    callRequestOnInit: false,
  });
  if (responseData?.totalCount || responseData?.totalCount === 0)
    return <div>{responseData?.totalCount}</div>;
  if (pending) return <ThreeDotsLoading fill="white" />;
  else
    return (
      <Button type="button" onClick={() => callRequest()} disabled={pending}>
        {"Ge Count Of Proposals"}
      </Button>
    );
}

export default DaoListProposalCount;
