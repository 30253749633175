import Card from 'components/Card/Card'
import DashboardWalletList from './components/DashboardWalletList'
import './assets/Dashboard.scss'
import DashboardWalletListHeader from './components/DashboardWalletListHeader'
import CreditsCharts from 'components/CreditsCharts/CreditsCharts'

function Dashboard() {
    return (
        <div className='dashboard d-flex flex-wrap'>
            <div className="col-12 mb-4">
                <DashboardWalletListHeader />
                <DashboardWalletList />
            </div>
            <h4 className='py-1 item-titles flex-grow-1'>
                Statistics
            </h4>
            <Card className="d-flex col-12 flex-wrap">
                <CreditsCharts className='col-12' />
            </Card>
        </div>
    )
}

export default Dashboard