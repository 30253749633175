import classNames from 'classnames';
import React from 'react'
import { ITabPanelHeaderItem } from '../meta/types'

interface Props extends ITabPanelHeaderItem {
  isActive: boolean;
  onClick: (id: string | number) => void;
}

function TabPanelHeaderItem({
  id,
  title,
  isActive,
  onClick
}: Props) {
  return (
    <div onClick={() => onClick(id)}
      className={classNames('tab-panel-header-item', {
        'is-active': isActive
      })}>
      {title}
    </div>
  )
}

export default TabPanelHeaderItem