import Button from 'components/Button/Button';
import ButtonIconText from 'components/Button/components/ButtonIconText';
import { ITableColumn } from 'components/Table/meta/types'
import Table from 'components/Table/Table'
import Tooltip from 'components/Tooltip/Tooltip';
import { useMemo } from 'react';
import { IServiceCost } from 'types/IServiceCost';


type Props = {
    data?: IServiceCost[];
    openForm: (serviceCostId: number) => void;
}

function ServiceCostList({
    data = [],
    openForm
}: Props) {
    const columns = useMemo<ITableColumn<IServiceCost>[]>(() => [
        {
            field: 'name',
            width: '30%',
            header: 'Name',
        },
        {
            field: 'category',
            width: '27%',
            header: 'Category',
        },
        {
            field: 'cost',
            width: '35%',
            header: 'Costs',
        },
        {
            field: 'id',
            header: 'Actions',
            render: (v: number) => <div className="d-flex">
                <Tooltip tooltipContent='Edit'>
                    <Button
                        onClick={() => openForm(v)}>
                        <ButtonIconText
                            icon={<i className='icon icon-edit' />}
                        />
                    </Button>
                </Tooltip>
            </div>
        }
    ], [openForm]);

    return (
        <Table
            columns={columns}
            data={data}
        />
    )
}

export default ServiceCostList