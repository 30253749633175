import classNames from 'classnames'
import React, { HTMLProps, ReactNode } from 'react'
import bgImg from './assets/bg-image.png'
import './assets/SignInUpLayout.scss'

interface Props extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  displaySlider?: boolean;
}

function SignInUpLayout({
  children,
  className,
  displaySlider = true,
  ...rest
}: Props) {
  return (
    <div {...rest} className={classNames('sign-in-up-warpper', className)}>
      <div className="sign-in-up-container">
        <div className="sign-in-up-form-container">
          <div className="sign-in-up-form position-relative">
            {children}  
          </div>
        </div>
        {displaySlider && <div className="sign-in-up-carousel">
          <img src={bgImg} alt="cryptoArt3" />
        </div>}
      </div>
    </div>
  )
}

export default SignInUpLayout