import CoinIcon from 'components/CoinIcon/CoinIcon';
import { ITableColumn } from 'components/Table/meta/types';
import Table from 'components/Table/Table'
import moment from 'jalali-moment';
import { Link } from 'react-router-dom';

const CELO_LINK = (address: string) => `https://alfajores.celoscan.io/address/${address}`

const columns: ITableColumn[] = [

  {
    field: 'label',
    header: 'Name',
    render: (label, row) => <Link className='d-flex' to={`/wallet/${row?.wallet}`}>
      <CoinIcon icon={row.chain} color='primary-color-lighter' height={32} width={32} className='my-auto mr-2' />
      <div className="my-auto">
        <div className='font-w-400' style={{ marginTop: 2 }}>
          {label}
        </div>
      </div>
    </Link>
  },
  {
    field: 'type',
    header: 'Type',
    render: () => 'Test'
  },
  {
    field: 'wallet',
    header: 'Wallet ID',
  },
  {
    field: 'address',
    header: 'Address Info',
    render: v => <a className='font-xs font-w-400' target='_blank' href={CELO_LINK(v)} rel="noreferrer">
      {v}
    </a>
  },
  {
    field: 'createdAt',
    header: 'Date Added',
    render: v => v && moment(v).format('YYYY/MM/DD hh:mm')
  }
];

interface Props {
  data?: any[];
}

function WalletListTable({
  data = []
}: Props) {
  return (
    <Table
      columns={columns}
      keyField='wallet'
      data={data}
    />
  )
}

export default WalletListTable