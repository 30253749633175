import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import { useModalState } from 'components/Modal/hooks/useModalState'
import endpoints from 'constants/endpoints'
import ListContainer from 'containers/ListContainer/ListContainer'
import useRequest from 'hooks/useRequest/useRequest'
import { useState } from 'react'
import { IListData } from 'types/IListData'
import { IListModuleState } from 'types/IListModuleState'
import AccessTokenCreateModal from './components/AccessTokenCreateModal'
import AccessTokenDisplayToken from './components/AccessTokenDisplayToken'
import AccessTokenHeader from './components/AccessTokenHeader'
import AccessTokenList from './components/AccessTokenList'
import { IAccessTokenCreateSuccessModel } from './meta/types'

// type Props = {}

function AccessToken() {
    const { close, isOpen, toggle } = useModalState()
    const {
        close: closeSuccessDialog,
        isOpen: isSuccessDialogOpen,
        open: openSuccessDialog,
        payload: successDialogPayload
    } = useModalState<IAccessTokenCreateSuccessModel>()
    const [state,] = useState<IListModuleState>({
        page: 0
    })

    const {
        responseData,
        pending,
        callRequest
    } = useRequest<IListData | undefined>({
        url: endpoints.accessToken.list,
        callRequestOnInit: true,
        params: { page: state.page }
    })

    const handleCreateSuccessfuly = (model: IAccessTokenCreateSuccessModel) => {
        openSuccessDialog(model)
    }

    // const handlePageChange = (page: number) => {
    //     setState({ page })
    // }
    return (
        <ListContainer page={0} refreshList={callRequest}>
            <div className='position-relative'>
                {pending && <LoadingContainer>
                    <CircleLoading />
                </LoadingContainer>}
                <AccessTokenDisplayToken
                    close={closeSuccessDialog}
                    isOpen={isSuccessDialogOpen}
                    accessToken={successDialogPayload?.accessToken}
                />
                <AccessTokenCreateModal
                    isOpen={isOpen}
                    close={close}
                    onCreateSuccessfuly={handleCreateSuccessfuly}
                />
                <AccessTokenHeader
                    openDialog={toggle}
                />
                <AccessTokenList
                    data={responseData?.list}
                />
            </div>
        </ListContainer>
    )
}

export default AccessToken