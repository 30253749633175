import Table from 'components/Table/Table'
import { ITableColumn } from 'components/Table/meta/types'
import moment from 'jalali-moment';
import { IDao } from 'types/IDao';
import DaoListProposalCount from './DaoListProposalCount';
import { Link } from 'react-router-dom';

const columns: ITableColumn<IDao>[] = [
    {
        field: 'name',
        header: 'Name',
        render: (v, row) => <Link to={`/dao/address/${row.contractAddress}`}>
            {v}
        </Link>
    },
    {
        field: 'contractAddress',
        header: 'Contract address'
    },
    {
        field: 'contractAddress',
        header: 'Proposal Count',
        render: (v) => <DaoListProposalCount
            chain='CELO'
            contractAddress={v}
        />
    },
    {
        field: 'createdAt',
        header: 'Date Created',
        render: v => moment(v).format('YYYY/MM/DD HH:mm')
    }
];

type Props = {
    data?: IDao[];
}

function DaoListTable({
    data = []
}: Props) {
  return (
    <Table 
        columns={columns}
        data={data}
    />
  )
}

export default DaoListTable