import { createContext } from "react";
import { ITokenFactorySuccessDialogModalPayload } from "../meta/types";

export const TokenFactoryContext = createContext<{
  openCreateSuccessDialog: (
    payload: ITokenFactorySuccessDialogModalPayload
  ) => void;
}>({
  openCreateSuccessDialog: () => {},
});
