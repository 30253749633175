import classNames from 'classnames'
import { HTMLProps } from 'react'
import './assets/LoadingContainer.scss'

interface ILoadingContainerProps extends HTMLProps<HTMLDivElement> {

}

function LoadingContainer({
    children,
    className,
    ...props
}: ILoadingContainerProps) {
    return (
        <div {...props} className={classNames("loading-container", className)}>
            <div className="loading">
                {children}
            </div>
        </div>
    )
}

export default LoadingContainer