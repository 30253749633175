import { Moment } from "moment";
import React, { ReactElement } from "react";
import { CalenderModeType, IDays, IDaysInMonth } from "../meta/types";
import Calender from "./Calender";
import MonthViewr from "./MonthList";

interface Props {
  calenderMode: CalenderModeType;
  date?: Moment;
  onDayClick: (day: IDays) => void;
  selectedDate?: string;
  daysInMonth: IDaysInMonth;
}

function DatePickerBody({
  calenderMode,
  onDayClick,
  selectedDate,
  daysInMonth,
  date,
}: Props): ReactElement {
  if (calenderMode === "DAY")
    return (
      <Calender
        onDayClick={onDayClick}
        selectedDate={selectedDate}
        daysInMonth={daysInMonth}
      />
    );
  return <MonthViewr />;
}

export default DatePickerBody;
