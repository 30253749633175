import Button from 'components/Button/Button'
import ButtonIconText from 'components/Button/components/ButtonIconText'
import { useModalState } from 'components/Modal/hooks/useModalState'
import Modal from 'components/Modal/Modal'
import { Fragment, ReactNode } from 'react'
import TradingCommissionForm from './TradingCommissionForm'

type Props = {
    tokenId?: number;
    currency?: string;
    buttonTitle?: string;
    buttonIcon?: ReactNode;
}

function TradingCommissionFormDialog({
    currency,
    tokenId,
    buttonIcon = <i className="icon icon-plus mr-2" />,
    buttonTitle = 'Add New',
}: Props) {
    const { isOpen, close, open } = useModalState()
    return (
        <Fragment>
            <Button onClick={open} color="primary-color-lighter" className="my-auto">
                <ButtonIconText
                    icon={buttonIcon}
                    text={buttonTitle}
                />
            </Button>
            <Modal isOpen={isOpen} close={close}>
                <TradingCommissionForm
                    close={close}
                    currency={currency}
                    tokenId={tokenId}
                />
            </Modal>
        </Fragment>
    )
}

export default TradingCommissionFormDialog