import Button from 'components/Button/Button'
import ButtonIconText from 'components/Button/components/ButtonIconText'
import endpoints from 'constants/endpoints'
import { useListContext } from 'containers/ListContainer/hooks/useListContext'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'

type Props = {
    userId: number;
}

function UserListConfirm({
    userId
}: Props) {
    const { refreshList } = useListContext();

    const {
        callRequest
    } = useRequest({
        callRequestOnInit: false,
        method: 'PATCH'
    })

    const snackbar = useSnackbar()

    const handleConfirmClick = async () => {
        await callRequest({
            url: endpoints.admin.confirm(userId)
        });
        snackbar.open({ color: 'green', timeout: 3000, content: 'Successfuly confirmed' })
        refreshList()
    }

    return (
        <Button color='primary' onClick={handleConfirmClick}>
            <ButtonIconText
                text='Confirm'
            />
        </Button>
    )
}

export default UserListConfirm