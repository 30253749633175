import { COLOR_TRANSLATOR } from '../meta/constants'
import { ICoinIconProps } from '../meta/types'

interface Props extends ICoinIconProps { }

function DOGEIcon({
    color = 'primary',
    inverse = false,
    ...props
}: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                fill={inverse ? 'white' : COLOR_TRANSLATOR[color]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.936 10.9575H13.1715V12.672H9.936V16.2855H11.9767C12.7845 16.2855 13.4453 16.1768 13.9598 15.9578C14.4743 15.7395 14.8778 15.4372 15.171 15.0502C15.4709 14.6478 15.6751 14.1822 15.768 13.689C15.8764 13.1326 15.9291 12.5668 15.9255 12C15.9291 11.4332 15.8764 10.8674 15.768 10.311C15.6752 9.81772 15.471 9.35214 15.171 8.94975C14.8778 8.56275 14.4735 8.2605 13.9598 8.04225C13.4453 7.82325 12.7845 7.7145 11.9767 7.7145H9.936V10.9575ZM7.8615 12.672H6.75V10.9583H7.8615V6H12.7733C13.6807 6 14.466 6.1575 15.1298 6.47025C15.7935 6.7845 16.335 7.21275 16.7557 7.7565C17.1757 8.2995 17.4885 8.9355 17.6932 9.66375C17.898 10.392 18 11.1712 18 12C18.0051 12.7892 17.9017 13.5753 17.6925 14.3363C17.4885 15.0638 17.1757 15.7005 16.755 16.2435C16.335 16.7873 15.7935 17.2155 15.1298 17.5297C14.466 17.8432 13.68 18 12.7733 18H7.8615V12.672Z"
                fill={inverse ? COLOR_TRANSLATOR[color] : 'white'}
            />
        </svg>
    )
}

export default DOGEIcon