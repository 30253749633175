import RadioButtonCore, { IRadioButtonCoreProps } from '../RadioButtonCore/RadioButtonCore'
import { useRadioGroup } from '../RadioGroupCore/hooks/useRadioGroup'

interface Props extends Omit<IRadioButtonCoreProps, 'isActive'> {
    value: string;
}

function RadioButton({
    value: radioValue,
    ...props
}: Props) {
    const { value: activeValue, update } = useRadioGroup()
    return (
        <RadioButtonCore
            isActive={activeValue === radioValue}
            onClick={() => update(radioValue)}
            {...props}
        />
    )
}

export default RadioButton