import classNames from 'classnames'
import Button from 'components/Button/Button'
import { useModalState } from 'components/Modal/hooks/useModalState'
import Modal from 'components/Modal/Modal'
import { Fragment } from 'react'
import WalletAssetsDeposit from './WalletAssetsDeposit'

interface Props {
    className?: string;
}

function WalletAssetsDepositDialog({
    className,
}: Props) {
    const {
        close,
        isOpen,
        open
    } = useModalState()
    return (
        <Fragment>
            <Button className={classNames('d-flex mr-3', className)} color='primary' onClick={open}>
                <i className='md-icon icon-download' />
                <div className="title my-auto">
                    Deposit
                </div>
            </Button>
            {isOpen && <Modal isOpen={isOpen} close={close}>
                <WalletAssetsDeposit />
            </Modal>}
        </Fragment>
    )
}

export default WalletAssetsDepositDialog