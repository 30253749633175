import Button from 'components/Button/Button'
import CoinCombobox from 'components/CoinCombobox/CoinCombobox'
import BaseField from 'components/form/BaseField/BaseField'
import NumberCore from 'components/form/NumberCore/NumberCore'
import RadioButton from 'components/form/RadioButton/RadioButton'
import RadioGroupCore from 'components/form/RadioGroupCore/RadioGroupCore'
import TextCore from 'components/form/TextCore/TextCore'
import WalletCombobox from 'components/form/WalletCombobox/WalletCombobox'
import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import { useListContext } from 'containers/ListContainer/hooks/useListContext'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'
import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { maxValudator, minValidator } from 'utils/validators'
import { TokenFactoryContext } from '../context/TokenFactoryContext'
import { ITokenFactoryCreateModel, ITokenFactoryCreateModelForm, ITokenFactoryCreateResponseModel } from '../meta/types'

interface Props {
    closeModal: () => void;
}

function TokenFactoryForm({
    closeModal
}: Props) {

    const { openCreateSuccessDialog } = useContext(TokenFactoryContext)

    const {
        callRequest,
        pending,
    } = useRequest<ITokenFactoryCreateResponseModel, ITokenFactoryCreateModel>({
        method: 'POST',
        callRequestOnInit: false,
    })

    const { refreshList } = useListContext()

    const snackbar = useSnackbar()

    const handleSubmit = async (values: ITokenFactoryCreateModelForm) => {
        const responseData = await callRequest({
            url: endpoints.token.create(values.chain.id),
            data: {
                name: values.name,
                symbol: values.symbol,
                supply: values.supply.replace(/,/g, ''),
                totalSupply: values.totalSupply.replace(/,/g, ''),
                digits: Number(values.digits),
                address: values.addressType === 'address' ? values.address : values.wallet.address
            }
        })

        openCreateSuccessDialog({
            tokenAddress: responseData.data.tokenAddress,
            chain: values.chain.id
        })

        refreshList()

        closeModal()

        snackbar.open({
            color: 'green',
            timeout: 4000,
            content: 'Token Created Successfuly'
        })
    }

    const form = useForm<ITokenFactoryCreateModelForm>({ defaultValues: { addressType: 'address' } })

    const addressType = form.watch('addressType')

    return (
        <FormProvider {...form}>
            <form className='position-relative' onSubmit={form.handleSubmit(handleSubmit)}>
                <h2 className='mb-4'>
                    Generate New Token
                </h2>
                {pending && <LoadingContainer>
                    <CircleLoading />
                </LoadingContainer>}
                <BaseField
                    component={CoinCombobox}
                    name="chain"
                    required
                    inputLabel="Chain"
                />
                <BaseField
                    required
                    component={TextCore}
                    name="name"
                    validators={{
                        validTokenName: (v: string) => v.includes(' ') ? 'Name should not include [space]' : undefined
                    }}
                    className="mt-4"
                    inputLabel="Name"
                />
                <BaseField
                    required
                    component={TextCore}
                    name="symbol"
                    className="mt-4"
                    inputLabel="Symbol"
                />
                <div className="d-flex">
                    <BaseField
                        required
                        component={NumberCore}
                        hasSeperator
                        name="totalSupply"
                        className="mt-4 pr-1 col-4"
                        inputLabel="Total Supply"
                    />

                    <BaseField
                        required
                        component={NumberCore}
                        hasSeperator
                        name="supply"
                        className="mt-4 pl-1 pr-1 col-4"
                        inputLabel="Supply"
                    />
                    <BaseField
                        required
                        component={NumberCore}
                        name="digits"
                        className="mt-4 col-4 pl-1"
                        inputLabel="Digits"
                        placeholder="can be 1-18"
                        validators={{
                            ...maxValudator(18),
                            ...minValidator(1)
                        }}
                        hasUpDownController
                    />
                </div>

                <BaseField
                    name='addressType'
                    component={RadioGroupCore}>
                    <div className="d-flex mt-4">
                        <RadioButton label='Use Address' value='address' />
                        <RadioButton className='ml-4' label='Select From Your Wallets' value='wallet' />
                    </div>
                </BaseField>

                <div className="mt-4">
                    {addressType === 'address' ? <BaseField
                        required
                        component={TextCore}
                        name="address"
                        key='address'
                        inputLabel="Target Address"
                    /> : <BaseField
                        component={WalletCombobox}
                        name="wallet"
                        key='wallet'
                        required
                        inputLabel="Target Address"
                    />}
                </div>

                <div className="d-flex justify-content-center mt-4">
                    <Button type='submit'>
                        Create Token
                    </Button>
                </div>

            </form>
        </FormProvider>
    )
}

export default TokenFactoryForm