import { ITableColumn } from './meta/types'
import TableRow, { ITableRowProps } from './components/TableRow'
import './assets/Table.scss'
import { ElementType, HTMLProps } from 'react'
import classNames from 'classnames'

export interface TableProps extends Omit<HTMLProps<HTMLTableElement>, 'data'> {
    /**
     * Columns witch describe how data should display
     */
    columns: ITableColumn[];
    /**
     * could be any data but in form of Array<object>
     */
    data?: any[];
    /**
     * custom table row component
     */
    tableRowComponent?: ElementType<ITableRowProps>;

    keyField?: string;
}

function Table({
    columns,
    data,
    tableRowComponent: TableRowComponent = TableRow,
    keyField = 'id',
    ...tableElementProps
}: TableProps) {
  return (
    <table {...tableElementProps} className={classNames('table', tableElementProps?.className)}>
        <thead>
            <tr>
                {columns.map((column, index) => <th 
                style={{ width: column.width }}
                key={(column.uniqueSortKey || column.field as string) ?? index}>
                    {column.header}
                </th>)}
            </tr>
        </thead>
        <tbody>
            {data?.map(row => <TableRowComponent
                columns={columns}
                row={row}
                key={row[keyField]}
            />)}
        </tbody>
    </table>
  )
}

export default Table