import CoinIcon from 'components/CoinIcon/CoinIcon'
import Combobox, { IComboboxProps } from 'components/form/Combobox/Combobox'
import ThreeDotsLoading from 'components/Loading/ThreeDotsLoading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import useCurrency from 'containers/CurrencyProvider/hooks/useCurrency'
import { ICurrencyItem } from 'containers/CurrencyProvider/meta/types'

interface Props extends IComboboxProps { }

function CoinCombobox({
    ...props
}: Props) {
    const { pending, currencyList } = useCurrency()

    const renderLabel = (item: any, index: number, isSelected?: boolean) => <div className='d-flex'>
        <CoinIcon className='my-auto' color={'primary-color-lighter'} icon={item?.isin} height={32} width={32} />
        <div className='ml-2'>
            <div className="title my-auto">
                {item?.isin}
            </div>
            <div className="note">
                {item.name}
            </div>
        </div>
    </div>

    const getItemLabel = (item: ICurrencyItem) => item ? <div className='d-flex'>
        <CoinIcon color='primary-color-lighter' style={{ marginTop: 1 }} icon={item?.isin as any} height={24} width={24} />
        <div className="title my-auto ml-2" style={{ marginTop: 5 }}>
            {item?.name}
        </div>
    </div> : ''

    return (
        <div className={"coin-combobox position-relative w-100"}>
            {pending && <LoadingContainer>
                {<ThreeDotsLoading />}
            </LoadingContainer>}

            <Combobox
                items={currencyList}
                className="mt-6"
                getItemLabel={getItemLabel}
                renderLabel={renderLabel}
                {...props}
            />
        </div>
    )
}

export default CoinCombobox