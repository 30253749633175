import * as d3 from "d3";

export function drawDountChart(data: number[], div: HTMLDivElement) {
  div.innerHTML = "";
  var dataset: any = { things: data };
  var width = 200;
  var height = 200;
  var radius = Math.min(width, height) / 2;
  var pie = d3.pie().sort(null);
  var arc = d3
    .arc()
    .innerRadius(radius)
    .outerRadius(radius - 17)
    .padAngle(0.0)
    .cornerRadius(0);
  var remainArc = d3
    .arc()
    .innerRadius(radius)
    .outerRadius(radius - 15)
    .cornerRadius(0)
    .padRadius(16);
  // .padAngle(100)
  // .cornerRadius(16);

  var svg = d3
    .select(div)
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
  svg
    .selectAll("path")
    .data(pie(dataset.things))
    .enter()
    .append("path")
    .attr("fill", function (d, i) {
      return getColors(i);
    })
    .attr("d", function (d, i) {
      return i === 0 ? (arc as any)(d, i) : (remainArc as any)(d, i);
    });

  function getColors(i: number) {
    var colorArray = ["#BD00FF", "#E5E5E5"];
    return colorArray[i];
  }

  let a = svg
    .append("svg:text")
    .attr("dy", ".35em")
    .attr("text-anchor", "middle")
    .attr("style", "font-weight: 500; font-size: 25px")
    .attr("fill", "#BD00FF");

  if (data[1] + data[0] > 0) {
    a.text(`${((data[0] / (data[1] + data[0])) * 100).toFixed(2)}%`);
  } else {
    a.text('0');
  }
}
