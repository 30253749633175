import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import useRequest from 'hooks/useRequest/useRequest'
import React, { Fragment } from 'react'
import { IProfile } from 'types/IProfile'
import UserInfoForm from '../UserInfoForm'

type Props = {
    close: () => void;
}

function UserInfoFormContainer({
    close
}: Props) {
    const {
        responseData,
        pending
    } = useRequest<IProfile>({
        url: endpoints.profile
    })
    return (
        <Fragment>
            {pending && <LoadingContainer>
                <CircleLoading />
            </LoadingContainer>}
            {responseData && <UserInfoForm
                close={close}
                initialData={responseData.userInfo}
            />}
        </Fragment>
    )
}

export default UserInfoFormContainer