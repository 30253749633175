import classNames from "classnames";
import Button from "components/Button/Button";
import ButtonIconText from "components/Button/components/ButtonIconText";
import TextCore from "components/form/TextCore/TextCore";
import React, { useState } from "react";
import { IProposalFilter } from "../meta/types";
import ProposalListDialog from "./ProposalListDialog";

type Props = {
    filters: IProposalFilter;
    changeEvent: (filters: Partial<IProposalFilter>) => void;
};

function ProposalListHeader({
    changeEvent,
    filters
}: Props) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  return (
    <div className="user-list-header py-3 px-2 pr-3">
      <div className="d-flex">
        <h2 className="flex-grow-1">Proposal List</h2>
        <ProposalListDialog />
        <Button color="yellow" className="ml-2" onClick={() => setIsFilterOpen((prev) => !prev)}>
          <ButtonIconText icon={<i className="icon icon-filter" />} />
        </Button>
      </div>

      <div
        className={classNames("d-flex mb-4 filters", {
          "is-open": isFilterOpen,
        })}
      >
        <TextCore
          className="search-text col-3"
          placeholder="Name"
          value={filters.name}
          onChange={(e) =>
            changeEvent({
              name: e.target.value,
            })
          }
        />
      </div>
    </div>
  );
}

export default ProposalListHeader;
