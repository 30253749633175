import TradingCommissionFormEditDialog from './TradingCommissionFormDialogEdit'

type Props = {
    id: number,
    tokenId?: number;
    currency?: string;
}

function TradingCommissionListActions({
    id,
    currency,
    tokenId
}: Props) {
    return (
        <div className='d-flex'>
            <TradingCommissionFormEditDialog
                id={id}
                currency={currency}
                tokenId={tokenId}
            />
        </div>
    )
}

export default TradingCommissionListActions