import API from 'API'
import classNames from 'classnames'
import Button from 'components/Button/Button'
import BaseField from 'components/form/BaseField/BaseField'
import TextCore from 'components/form/TextCore/TextCore'
import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import { AUTH_LOCAL_STORAGE_KEYS } from 'constants/auth'
import endpoints from 'constants/endpoints'
import SignInUpLayout from 'containers/SignInUpLayout/SignInUpLayout'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { RootState } from 'redux/store'
import { userSlice } from 'redux/user/userSlice'
import { IAuthResponseData, ILoginFormValues } from './meta/types'

interface Props {
    url?: string;
    displaySlider?: boolean;
    title?: ReactNode;
    onFailur?: (error: any, values: any) => void;
    onSuccess?: (result: IAuthResponseData, values: ILoginFormValues) => void;
}

function Login({
    url = endpoints.getToken,
    displaySlider,
    onFailur,
    onSuccess,
    title
}: Props) {

    const [visiblityOfPassword, setVisiblityOfPassword] = useState(false)

    const snackbar = useSnackbar()

    const navigate = useNavigate()
    const autheticateState = useSelector<RootState>(state => state.user.autheticateState)

    const dispatch = useDispatch()

    const handleVisiblityIconClick = () => {
        setVisiblityOfPassword(prev => !prev)
    }

    useEffect(() => {
        if (autheticateState === 'AUTHENTICATED') {
            navigate('/')
        }
    }, [autheticateState, navigate])

    const {
        callRequest: login,
        pending
    } = useRequest<IAuthResponseData>({
        url,
        method: 'POST',
        snackErrors: false,
        callRequestOnInit: false,
    })

    const handleSubmitForm = useCallback(async (values: ILoginFormValues) => {
        login({
            data: {
                userName: values.userName,
                password: values.password
            }
        }).then((res) => {
            localStorage.setItem(AUTH_LOCAL_STORAGE_KEYS.TOKEN, res.data.accessToken);
            localStorage.setItem(AUTH_LOCAL_STORAGE_KEYS.REFRESH_TOKEN, res.data.accessToken);
            API.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
            if (onSuccess) {
                onSuccess(res.data, values);
            } else {
                dispatch(userSlice.actions.setUser(values))
                navigate('/dashboard')
            }
        }).catch((error) => {
            if (onFailur) {
                onFailur(error, values)
            } else {
                snackbar.open({ color: 'red', content: 'Error! username or password is not correct...', timeout: 4000 })
            }
        })
    }, [dispatch, login, navigate, onFailur, onSuccess, snackbar])

    const form = useForm<ILoginFormValues>()

    return (
        <SignInUpLayout displaySlider={displaySlider}>
            {title || <h2 className=''>
                Sign into Your Account
            </h2>}
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmitForm)}>
                    {pending && <LoadingContainer>
                        <CircleLoading height={100} width={100} />
                    </LoadingContainer>}
                    <div className="field-container mt-4">
                        <i className='left-icon icon-account' />
                        <BaseField
                            component={TextCore}
                            name="userName"
                            className='w-100'
                            inputLabel='Username'
                            required
                        />
                    </div>
                    <div className="field-container mt-4">
                        <i className='left-icon icon-lock' />
                        <BaseField
                            component={TextCore}
                            name="password"
                            required
                            className='w-100'
                            inputLabel='Password'
                            type={visiblityOfPassword ? 'text' : 'password'}
                            right={<i
                                className={classNames('icon button', {
                                    'icon-visibility': visiblityOfPassword === false,
                                    'icon-visibility-off': visiblityOfPassword
                                })}
                                onClick={handleVisiblityIconClick}
                            />}
                        />
                    </div>
                    <div className="sign-in-up-button-container">
                        <Button type="submit" className='sign-in-up-button mt-6'>
                            Sign In
                        </Button>
                    </div>
                </form>
            </FormProvider>
            <div className="have-not-an-account">
                Don't have an account? <Link to="/signUp">Sign Up</Link>
            </div>
        </SignInUpLayout>
    )
}

export default Login