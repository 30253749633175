import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import ListContainer from 'containers/ListContainer/ListContainer'
import useDebounce from 'hooks/useDebounce'
import useRequest from 'hooks/useRequest/useRequest'
import React, { useState } from 'react'
import { IListModuleState } from 'types/IListModuleState'
import WalletListFooter from './components/WalletListFooter'
import WalletListHeader from './components/WalletListHeader'
import WalletListTable from './components/WalletListTable'
import { IWalletListModel } from './meta/types'

function WalletList() {

  const [searchValue, setSearchValue] = useState('')
  const [addressValue, setAddressValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const debouncedAddressValue = useDebounce(addressValue, 500)

  const [state, setState] = useState<IListModuleState>({
    page: 0
  })

  const {
    responseData,
    pending,
    callRequest
  } = useRequest<IWalletListModel | undefined>({
    url: endpoints.wallet.list,
    callRequestOnInit: true,
    params: {
      page: state.page,
      labelContains: debouncedSearchValue,
      address: debouncedAddressValue
    }
  })
  const handleSearchChange = (value: string) => {
    setSearchValue(value)
  }

  const handleAddressChange = (value: string) => {
    setAddressValue(value)
  }

  const handlePageChange = (page: number) => {
    setState({ page })
  }

  return (
    <ListContainer
      refreshList={callRequest}
      page={state.page}
    >
      <div className='position-relative'>
        {pending && <LoadingContainer>
          <CircleLoading />
        </LoadingContainer>}
        <WalletListHeader
          searchValue={searchValue}
          onSearchValueChange={handleSearchChange}
          addressValue={addressValue}
          onAddressValueChange={handleAddressChange}
        />
        <WalletListTable data={responseData?.wallets} />
        <WalletListFooter
          currenctPage={state.page}
          totalItems={responseData?.totalCount}
          onPageChange={handlePageChange}
        />
      </div>
    </ListContainer>
  )
}

export default WalletList