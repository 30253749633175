import { SVGProps } from 'react'

export interface ILoadingProps extends SVGProps<SVGSVGElement> {

}

function CircleLoading({
    height = '30px',
    width = '30px',
    ...props
}: ILoadingProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={width}
            height={height}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            {...props}
        >
            <circle cx="50" cy="50" fill="none" stroke="#7000ff" strokeWidth="12" r="43" strokeDasharray="202.63272615654165 69.54424205218055">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.970873786407767s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
        </svg>
    )
}

export default CircleLoading

