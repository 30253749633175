import { ColorType } from "types/ColorType";

export const COLOR_TRANSLATOR: Record<ColorType, string> = {
    primary: '#7000FF',
    green: '#3FECB8',
    red: '#EA6464',
    white: '#fff',
    yellow: '#ffd000',
    "primary-color-lighter": '#BD00FF',
    'gray-dark': 'gray'
}