import TextCore from 'components/form/TextCore/TextCore'
import WalletCreateDialog from './WalletCreateDialog'
import '../assets/WalletListHeader.scss'
import Button from 'components/Button/Button'
import { useState } from 'react'
import classNames from 'classnames'
import Tooltip from 'components/Tooltip/Tooltip'

interface Props {
  searchValue?: string;
  onSearchValueChange: (searchValue: string) => void;

  addressValue?: string;
  onAddressValueChange: (addressValue: string) => void;
}

function WalletListHeader({
  searchValue,
  onSearchValueChange,
  onAddressValueChange,
  addressValue
}: Props) {
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  return (
    <div className="wallet-list-header">
      <div className='d-flex py-4'>
        <h2 className='my-auto flex-grow-1'>
          Wallet List
        </h2>
        <Button
          onClick={() => setIsFilterOpen(prev => !prev)}
          className='mr-2 d-flex'
          color='yellow'
          tag={Tooltip}
          tooltipContent={'Filter'}
        >
          <i className='icon icon-filter font-lg' />
        </Button>
        <WalletCreateDialog />
      </div>
      <div className={classNames("d-flex mb-4 filters", { 'is-open': isFilterOpen })}>
        <TextCore
          className='search-text'
          placeholder='Search in Name...'
          value={searchValue}
          onChange={(e) => onSearchValueChange(e.target.value)}
        />
        <TextCore
          className='search-text ml-3'
          placeholder='Search in Address...'
          value={addressValue}
          onChange={(e) => onAddressValueChange(e.target.value)}
        />
      </div>
    </div>
  )
}

export default WalletListHeader