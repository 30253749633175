import Button from 'components/Button/Button'
import ButtonIconText from 'components/Button/components/ButtonIconText'
import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import ListContainer from 'containers/ListContainer/ListContainer'
import useRequest from 'hooks/useRequest/useRequest'
import { ITokenAddress } from 'types/ITokenAddress'
import WalletAssetsTable from './components/WalletAssetsTable'

type Props = {
    address: string,
    chain: string;
    className?: string;
}

function WalletAssets({
    address,
    chain,
    className
}: Props) {

    const {
        responseData,
        pending,
        callRequest
    } = useRequest<ITokenAddress[] | undefined>({
        url: endpoints.token.assets(chain, address),
    })

    return (
        <ListContainer
            page={0}
            refreshList={callRequest}
        >
            <div className='position-relative'>
                {pending && <LoadingContainer>
                    <CircleLoading />
                </LoadingContainer>}
                <WalletAssetsTable
                    className={className}
                    data={responseData}
                    chain={chain}
                    walletAddress={address}
                />
            </div>
            <div className="d-flex ">
                <Button onClick={() => callRequest()} color='gray-dark' className='mt-4'>
                    <ButtonIconText
                        icon={<i className='icon mr-1 icon-refresh' />}
                        text={'Refresh'}
                    />
                </Button>
            </div>
        </ListContainer>
    )
}

export default WalletAssets