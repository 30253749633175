import Button from 'components/Button/Button'
import BaseField from 'components/form/BaseField/BaseField'
import TextCore from 'components/form/TextCore/TextCore'
import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import PasswordStrengeViewer from 'components/Password/components/PasswordStrengeViewer'
import endpoints from 'constants/endpoints'
import SignInUpLayout from 'containers/SignInUpLayout/SignInUpLayout'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'
import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getPasswordStrength } from 'utils/password'
import { IForceChangePasswordData, IForceChangePasswordFormValues } from './meta/types'

interface Props {
    redirectPath: string;
}

function ForceChangePassword({
    redirectPath = '/login'
}: Props) {
    const snackbar = useSnackbar()

    const navigate = useNavigate()
    const [search] = useSearchParams()

    const {
        callRequest: changePassword,
        pending
    } = useRequest<any, IForceChangePasswordData>({
        url: endpoints.forceChangePassword,
        method: 'POST',
        snackErrors: false,
        callRequestOnInit: false,
    })

    const handleSubmitForm = useCallback(async (values: IForceChangePasswordFormValues) => {
        if (values.newPassword !== values.newPasswordRepeat) {
            snackbar.open({ content: 'password does\'nt match with repeat', color: 'green', timeout: 3000 })
            return;
        }
        changePassword({
            data: {
                userName: search.get('userName') ?? '',
                password: values.password,
                newPassword: values.newPassword,
            }
        }).then((res) => {
            snackbar.open({ color: 'green', content: 'Successfuly Password changed!', timeout: 3000 })
            navigate(redirectPath)
        }).catch(() => {

            snackbar.open({ color: 'red', content: 'Error! username or password is not correct...', timeout: 4000 })
        })
    }, [changePassword, search, snackbar, navigate, redirectPath])

    const form = useForm<IForceChangePasswordFormValues>()

    const newPassword = form.watch('newPassword');

    return (
        <SignInUpLayout displaySlider={false}>
            <h2 className=''>
                Change Password
            </h2>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmitForm)}>
                    {pending && <LoadingContainer>
                        <CircleLoading height={100} width={100} />
                    </LoadingContainer>}
                    <BaseField
                        component={TextCore}
                        inputLabel="Current Password"
                        name="password"
                        type="password"
                        className='mt-4'
                        required
                    />

                    <BaseField
                        component={TextCore}
                        inputLabel="New Password"
                        name="newPassword"
                        type="password"
                        className='mt-4'
                        required
                    />

                    <BaseField
                        component={TextCore}
                        inputLabel="New Password Repeat"
                        name="newPasswordRepeat"
                        className='mt-4'
                        type="password"
                        required
                    />

                    <PasswordStrengeViewer
                        className='mt-4'
                        value={getPasswordStrength(newPassword).power}
                    />
                    {getPasswordStrength(newPassword).message}

                    <div className="mt-4 d-flex justify-content-center">
                        <Button type='submit' color='primary'>
                            Change Password
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </SignInUpLayout>
    )
}

export default ForceChangePassword