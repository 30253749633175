import Button from 'components/Button/Button'
import { useModalState } from 'components/Modal/hooks/useModalState'
import Modal from 'components/Modal/Modal'
import { Fragment } from 'react'
import { IUserListItem } from '../meta/types'
import UserListCredits from './UserListCredits'

type Props = {
    row: IUserListItem
}

function UserListCreditsDialog({
    row
}: Props) {
    const {
        isOpen,
        close,
        open
    } = useModalState()
    return (
        <Fragment>
            <Button onClick={open}>
                Credits
            </Button>
            <Modal width={600} close={close} isOpen={isOpen}>
                <UserListCredits
                    userName={row.userName}
                />
            </Modal>
        </Fragment>
    )
}

export default UserListCreditsDialog