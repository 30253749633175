export type SubscriberCb<T = any> = (payload: T) => void;

/**
 * observer is use to implement emitter and subscriber design pattern
 */
export class Observer<T = any> {

    subscribers: { name: string, cb: SubscriberCb<T> }[] = []

    on(name: string, cb: SubscriberCb<T>) {
        this.subscribers.push({ name, cb })
    }

    off(cb: SubscriberCb<T>) {
        const indexToDelete = this.subscribers.findIndex(item => item.cb === cb);
        this.subscribers.splice(indexToDelete, 1);
    }

    emit(name: string, payload: T) {
        this.subscribers.filter(subsciber => subsciber.name === name).forEach(subsciber => {
            subsciber.cb(payload)
        });
    }

}