import classNames from 'classnames'
import CoinIcon from 'components/CoinIcon/CoinIcon'
import ComboboxLabelIcon from 'components/ComboboxLabelIcon/ComboboxLabelIcon'
import ThreeDotsLoading from 'components/Loading/ThreeDotsLoading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import useRequest from 'hooks/useRequest/useRequest'
import { IWalletListModel } from 'pages/Wallet/meta/types'
import { IWallet } from 'types/IWallet'
import Combobox, { IComboboxProps } from '../Combobox/Combobox'

interface Props extends IComboboxProps {
    comboboxClassName?: string;
}

function WalletCombobox({
    value,
    onChange,
    className,
    comboboxClassName,
    ...props
}: Props) {

    const {
        pending,
        responseData
    } = useRequest<IWalletListModel>({
        url: endpoints.wallet.list,
    })

    return (
        <div className={classNames("position-relative", className)}>
            {pending && <LoadingContainer>
                <ThreeDotsLoading />
            </LoadingContainer>}
            <Combobox
                items={responseData?.wallets ?? []}
                renderLabel={(item: IWallet) => <ComboboxLabelIcon 
                    icon={<CoinIcon icon={item.chain} />}
                    label={item.label}
                />}
                value={value}
                placeholder='Select yout wallet as token address'
                onChange={onChange}
                getItemKey={(item: IWallet) => item?.address}
                getItemLabel={(item: IWallet) => item?.label}
                className={comboboxClassName}
                {...props}
            />
        </div>
    )
}

export default WalletCombobox