import classNames from 'classnames'
import './assets/SwitchButton.scss'

interface Props {
  className?: string;
  onChange?: (newState: boolean) => void;
  value?: boolean;
}

function SwitchButton({
  className,
  onChange,
  value = false
}: Props) {
  const handleSwitchButtonClick = () => {
    if (onChange) {
      onChange(!value)
    }
  }
  return (
    <div
      onClick={handleSwitchButtonClick}
      className={classNames(className, 'switch-button', {
        'is-active': value
      })}
    >
      <div className="switch-button-circle">
        {value ? <i className="icon icon-check" /> : <i className="icon icon-close" />}
      </div>
    </div>
  )
}

export default SwitchButton