import Card from 'components/Card/Card'
import CoinIcon from 'components/CoinIcon/CoinIcon'
import { Link } from 'react-router-dom'
import { IWallet } from 'types/IWallet'
import '../assets/DashboardWalletListItem.scss'

interface Props extends IWallet {

}


function DashboardWalletListItem({
    id,
    chain,
    label,
    wallet,
}: Props) {
    return (
        <Link to={`/wallet/${wallet}`} className='dashboard-wallet-list-item'>
            <Card className='wallet-item d-flex p-3'>
                <CoinIcon
                    height={40}
                    width={40}
                    icon={chain}
                />
                <div className="title-and-subtitle flex-grow-1">
                    <div className="title">
                        {label}
                    </div>
                    <div className="subtitle">
                        {chain}
                    </div>
                </div>
            </Card>
        </Link>
    )
}

export default DashboardWalletListItem