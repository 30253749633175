import ReactPaginate from 'react-paginate';
import './assets/Pagination.scss'

type Props = {
    pageCount: number;
    currenctPage?: number;
    onPageChange?: (page: number) => void;
}

function Pagination({
    pageCount,
    currenctPage,
    onPageChange
}: Props) {

    const handlePageChange = ({ selected }: { selected: number }) => {
        if (onPageChange) {
            onPageChange(selected)
        }
    }

    return (
        <ReactPaginate
            className='pagination'
            pageCount={pageCount}
            activeLinkClassName={'page-active'}
            nextLabel={<div className='next-label'>
                Next
                <i className='icon icon-chevron-right' />
            </div>}
            onPageChange={handlePageChange}
            previousLabel={<div className='previus-label'>
                <i className='icon icon-chevron-right' />
                Prev
            </div>}
            forcePage={currenctPage}
        />
    )
}

export default Pagination