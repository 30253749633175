export function waitAndCall(time: number = 1000, promise: Promise<any>) {
    new Promise((res, rej) => {
        setTimeout(async () => {
            try {
                const result = await promise;
                res(result)
            } catch(err) {
                rej(err)
            }
        }, time)
    })
}

export function wait(time: number = 1000) {
    new Promise((res, rej) => {
        setTimeout(async () => {
            try {
                res(null)
            } catch(err) {
                rej(err)
            }
        }, time)
    })
}