import Button from 'components/Button/Button'
import { useModalState } from 'components/Modal/hooks/useModalState'
import Modal from 'components/Modal/Modal'
import React, { Fragment } from 'react'
import CreditForm from './components/CreditForm'

function CreditModal() {
    const {
        close,
        open,
        isOpen
    } = useModalState()
    return (
        <Fragment>
            <Button onClick={open}>
                Add Credits
            </Button>
            <Modal close={close} minHeight={200} isOpen={isOpen}>
                <CreditForm closeModal={close} />
            </Modal>
        </Fragment>
    )
}

export default CreditModal