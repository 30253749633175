import Card from 'components/Card/Card'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import { useModalState } from 'components/Modal/hooks/useModalState'
import Modal from 'components/Modal/Modal'
import UserChangePassword from 'components/UserChangePassword/components/UserChangePasswordForm'
import UserInfoFormContainer from 'components/UserInfoForm/components/UserInfoFormContainer'
import { AUTH_LOCAL_STORAGE_KEYS } from 'constants/auth'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { userSlice } from 'redux/user/userSlice'
import './assets/UserWidget.scss'

function UserWidget() {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user.user)

  const {
    close: closeChangePassword,
    isOpen: isChangePasswordOpen,
    open: openChangePassword,
  } = useModalState()

  const {
    close: closeChangeUserInfo,
    isOpen: isUserInfoOpen,
    open: openChangeUserInfo,
  } = useModalState()

  const handleSignOutClick = () => {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEYS.TOKEN)
    dispatch(userSlice.actions.logout())
    if(user?.isAdmin) {
      window.location.href = '/admin/login';
    } else {
      window.location.href = '/login';
    }
  }

  return (<Fragment>
    <Modal isOpen={isChangePasswordOpen} close={closeChangePassword}>
      <UserChangePassword
        close={closeChangePassword}
      />
    </Modal>

    <Modal isOpen={isUserInfoOpen} close={closeChangeUserInfo}>
      <UserInfoFormContainer
        close={closeChangeUserInfo}
      />
    </Modal>
    <DropdownMenu
      contentWidth={245}
      renderAnchor={({ toggle }) => {
        return <div className='user-widget d-flex pr-2' onClick={toggle}>
          <div className="title mr-2">
            {user?.userInfo ? <Fragment>
              {user?.userInfo?.firstName} {user?.userInfo?.lastName}
            </Fragment> : user?.userName}
          </div>
          <i className='icon icon-person' />
        </div>
      }}
    >
      <Card className='user-widget-dropdown-content'>
        <ul>
          {/* <li>
                  <i className='icon icon-person' />
                  <div className="text">
                    Profile
                  </div>
                </li>
              */}
          <li onClick={openChangeUserInfo}>
            <i className='icon icon-settings' />
            <div className="text">
              Settings
            </div>
          </li>
          <li onClick={openChangePassword}>
            <i className='icon icon-lock' />
            <div className="text">
              Change Password
            </div>
          </li>
          <li className='splitter'></li>
          <li onClick={handleSignOutClick}>
            <i className='icon icon-logout' />
            <div className="text">
              Sign Out
            </div>
          </li>
        </ul>
      </Card>
    </DropdownMenu>
  </Fragment>
  )
}

export default UserWidget