export function comboboxDefaultGetKey(item: any) {
    return item?.id;
}

export function comboboxDefaultGetLabel(item: any) {
    return item?.label;
}

export function comboboxDefaultRenderLabel(item: any) {
    return item?.label
}