import moment from "jalali-moment";
import { memo, useCallback } from "react";
import { CalenderModeType } from "../meta/types";

interface DatePickerHeaderProps {
  month: number;
  monthName: string;
  onMonthChange: (month: number) => void;
  onYearChange: (year: number) => void;
  onCalenderModeChange: (mode: CalenderModeType) => void;
  calenderMode: CalenderModeType;
  year: number;
}

const DatePickerHeader = memo(
  ({
    month,
    monthName,
    year,
    onMonthChange,
    onYearChange,
    onCalenderModeChange,
    calenderMode,
  }: DatePickerHeaderProps) => {

    const onNextClick = useCallback(() => {
      if (calenderMode === "DAY") onMonthChange(month);
      else onYearChange(year + 1);
    }, [calenderMode, month, onMonthChange, onYearChange, year]);

    const onPreviusClick = useCallback(() => {
      if (calenderMode === "DAY") onMonthChange(month - 2);
      else onYearChange(year - 1);
    }, [calenderMode, month, onMonthChange, onYearChange, year]);

    const handleMonthNameClick = useCallback(() => {
      // onCalenderModeChange(calenderMode === 'MONTH' ? 'DAY' : 'MONTH')
    }, []);

    const handleCurrentClick = useCallback(() => {
      onYearChange(moment().jYear());
      onMonthChange(moment().jMonth());
      // onChange && onChange(moment().format('YYYY/MM/DD'))
    }, [onMonthChange, onYearChange]);

    return (
      <div className="date-picker-header d-flex">
        <div className="arrows d-flex">
          <i onClick={onPreviusClick} className="icon-chevron-left"></i>
          <div className="year-month" onClick={handleMonthNameClick}>
            <div className="current-date" onClick={handleCurrentClick}>
              <div className="circle"></div>
            </div>
            <span className="current-date-month">
              {calenderMode === "DAY" ? monthName : year}
            </span>
          </div>
          <i onClick={onNextClick} className="icon-chevron-right"></i>
        </div>
      </div>
    );
  }
);

export default DatePickerHeader;
