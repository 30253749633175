import Button from 'components/Button/Button'
import ButtonIconText from 'components/Button/components/ButtonIconText'
import { useModalState } from 'components/Modal/hooks/useModalState'
import Modal from 'components/Modal/Modal'
import { Fragment } from 'react'
import TokenFactoryForm from './TokenFactoryForm'

function TokenFactoryDialog() {
    const {
        close,
        isOpen,
        open,
    } = useModalState()
    return (
        <Fragment>
            <Button onClick={open} color='primary'>
                <ButtonIconText
                    text={'Generate New Token'}
                    icon={<i className='icon icon-plus' />}
                />
            </Button>
            <Modal width={500} isOpen={isOpen} close={close}>
                <TokenFactoryForm
                    closeModal={close}
                />
            </Modal>
        </Fragment>
    )
}

export default TokenFactoryDialog