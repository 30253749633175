import classNames from 'classnames'
import { forwardRef, HTMLProps, ReactNode } from 'react'

interface Props extends HTMLProps<HTMLDivElement> {
    children?: ReactNode;
    width?: number;
}

const ModalCard = forwardRef<HTMLDivElement, Props>(function ({
    children,
    className,
    width,
    style,
    ...rest
}: Props, ref) {
  return (
    <div ref={ref} style={{ width, ...style }} {...rest} className={classNames('modal-card portal-item', className)}>
        {children}
    </div>
  )
})

export default ModalCard