import { Method } from 'axios'
import Button from 'components/Button/Button'
import BaseField from 'components/form/BaseField/BaseField'
import NumberCore from 'components/form/NumberCore/NumberCore'
import endpoints from 'constants/endpoints'
import { useListContext } from 'containers/ListContainer/hooks/useListContext'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ITradingCommissionValues, ITradingCommissionValuesForm } from '../meta/types'

type Props = {
    url?: string;
    method?: Method;
    tokenId?: number;
    currency?: string;
    close: () => void;
    initialData?: ITradingCommissionValuesForm;
}

function TradingCommissionForm({
    url = endpoints.tradingCommissions.create,
    currency,
    tokenId,
    close,
    initialData,
    method = 'post'
}: Props) {

    const {
        callRequest
    } = useRequest<any, ITradingCommissionValues>({
        url,
        method: method,
        callRequestOnInit: false
    })

    const snackbar = useSnackbar()

    const { refreshList } = useListContext()

    const form = useForm<ITradingCommissionValuesForm>({
        defaultValues: initialData
    })

    const handleSubmit = async (values: ITradingCommissionValuesForm) => {
        await callRequest({
            data: {
                lowerLimitOfTurnOver: Number(values?.lowerLimitOfTurnOver),
                upperLimitOfTurnOver: Number(values?.upperLimitOfTurnOver),
                makerCommission: Number(values?.makerCommission) / 100,
                takerCommission: Number(values?.takerCommission) / 100,
                currency: currency,
                tokenId: tokenId
            }
        })
        snackbar.open({
            color: 'green',
            content: 'Regsitered successfuly!',
            timeout: 3000
        })
        close()
        refreshList()
    }

    return (
        <FormProvider {...form}>
            <h3>
                Register Commission
            </h3>
            <form onSubmit={form.handleSubmit(handleSubmit)} className='d-flex flex-wrap'>
                <BaseField
                    name="lowerLimitOfTurnOver"
                    inputLabel="Lower limit of turn over"
                    decimalsCount={8}
                    component={NumberCore}
                    required
                    className="col-6 mt-3"
                    right={<div className='my-auto px-2'>
                        IRR
                    </div>}
                />
                <BaseField
                    name="upperLimitOfTurnOver"
                    inputLabel="Upper limit of turn over"
                    component={NumberCore}
                    decimalsCount={8}
                    required
                    right={<div className='my-auto px-2'>
                        IRR
                    </div>}
                    className="col-6 mt-3 pl-2"
                />
                <BaseField
                    name="takerCommission"
                    inputLabel="Taker commission"
                    component={NumberCore}
                    required
                    decimalsCount={8}
                    right={<div className='my-auto px-2'>
                        %
                    </div>}
                    className="col-6 mt-3"
                />
                <BaseField
                    name="makerCommission"
                    inputLabel="Maker commission"
                    component={NumberCore}
                    required
                    decimalsCount={8}
                    right={<div className='my-auto px-2'>
                        %
                    </div>}
                    className="col-6 mt-3 pl-2"
                />
                <div className="d-flex col-12 flex-direction-row-reverse mt-3">
                    <Button type="submit">
                        Register
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}

export default TradingCommissionForm