import React, { ReactNode } from 'react'
import { CurrencyContext } from './context/CurrencyContext'

type Props = {
    children: ReactNode;
}

function CurrencyProvider({
    children
}: Props) {
    return (
        <CurrencyContext.Provider value={{
            currencyList: [
                {
                    id: 'CELO',
                    isin: 'CELO',
                    name: 'CELO',
                }
            ],
            pending: false
        }}>
            {children}
        </CurrencyContext.Provider>
    )
}

export default CurrencyProvider