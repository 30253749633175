import { COLOR_TRANSLATOR } from '../meta/constants'
import { ICoinIconProps } from '../meta/types'

interface Props extends ICoinIconProps { }

function CELOIcon({
    color = 'primary-color-lighter',
    inverse = false,
    secondaryColor = 'primary',
    height = 25,
    width = 25,
    ...props
}: Props) {
    return (
        <svg version="1.1" id="Celo_Rings" xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 950 950"
            height={height}
            width={width}
            xmlSpace="preserve"
            {...props}>
            <path
                fill={COLOR_TRANSLATOR[color]}
                d="M375,850c151.9,0,275-123.1,275-275S526.9,300,375,300S100,423.1,100,575S223.1,850,375,850z
	 M375,950C167.9,950,0,782.1,0,575s167.9-375,375-375s375,167.9,375,375S582.1,950,375,950z"/>
            <path
                fill={COLOR_TRANSLATOR[secondaryColor]} d="M575,650c151.9,0,275-123.1,275-275S726.9,100,575,100S300,223.1,300,375S423.1,650,575,650z
	 M575,750c-207.1,0-375-167.9-375-375S367.9,0,575,0s375,167.9,375,375S782.1,750,575,750z"/>
            <path
                fill={COLOR_TRANSLATOR[secondaryColor]}
                d="M587.4,750c26-31.5,44.6-68.4,54.5-108.1c39.6-9.9,76.5-28.5,108.1-54.5
	c-1.4,45.9-11.3,91.1-29.2,133.5C678.5,738.7,633.3,748.6,587.4,750z M308.1,308.1c-39.6,9.9-76.5,28.5-108.1,54.5
	c1.4-45.9,11.3-91.1,29.2-133.4c42.3-17.8,87.6-27.7,133.4-29.2C336.6,231.5,318,268.4,308.1,308.1z"/>
        </svg>
    )
}

export default CELOIcon