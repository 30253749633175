import classNames from 'classnames'
import { HTMLProps, ReactNode, useEffect, useRef } from 'react'
import { drawDountChart } from './meta/utils'
import './assets/UseAndRemainChart.scss'

export interface UseAndRemainChartProps extends HTMLProps<HTMLDivElement> {
    title?: string;
    maximumLabel?: ReactNode;
    usedLabel?: ReactNode;
    maximumValue: number;
    usedValue: number;
}

function UseAndRemainChart({
    title,
    className,
    maximumLabel,
    usedLabel,
    maximumValue,
    usedValue,
    ...props
}: UseAndRemainChartProps) {
    const dountChartRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (dountChartRef.current) {
            drawDountChart([usedValue, maximumValue - usedValue], dountChartRef.current)
        }
    }, [maximumValue, usedValue])
    return (
        <div className={classNames("donut-chart-container", className)} {...props}>
            <h3 className='text-center mb-2'>
                {title}
            </h3>
            <div ref={dountChartRef} className='donut-chart mt-3' />
            <div className="donut-chart-attr-list mt-3">
                <div className="donut-chart-attr donut-chart-maximum">
                    <div className="value">
                        {maximumValue}
                    </div>
                    <div className="label">
                        {maximumLabel}
                    </div>
                </div>
                <div className="donut-chart-attr donut-chart-used">
                    <div className="value">
                        {usedValue}
                    </div>
                    <div className="label">
                        {usedLabel}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UseAndRemainChart