import classNames from 'classnames'
import { HTMLProps } from 'react'
import './assets/RadioButton.scss'

export interface IRadioButtonCoreProps extends HTMLProps<HTMLDivElement> {
    isActive?: boolean;
    label?: string;
}

function RadioButtonCore({
    isActive,
    label,
    className,
    ...props
}: IRadioButtonCoreProps) {
    return (
        <div {...props} className={classNames("radio-button-group", className)}>
            <div className={classNames('radio-button', { 'is-active': isActive })}>
                <div className="radio-button-circle"></div>
            </div>
            <label>
                {label}
            </label>
        </div>
    )
}

export default RadioButtonCore