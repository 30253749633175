import Modal from "components/Modal/Modal";
import { useModalState } from "components/Modal/hooks/useModalState";
import React, { Fragment } from "react";
import ProposalListForm from "./ProposalListForm";
import Button from "components/Button/Button";
import ButtonIconText from "components/Button/components/ButtonIconText";

// type Props = {};

function ProposalListDialog() {
  const { isOpen, close, open } = useModalState();
  return (
    <Fragment>
      <Button onClick={open}>
        <ButtonIconText icon={<i className="icon icon-plus" />} />
      </Button>
      <Modal isOpen={isOpen} close={close}>
        <ProposalListForm close={close} />
      </Modal>
    </Fragment>
  );
}

export default ProposalListDialog;
