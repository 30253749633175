import { HTMLProps, useEffect, useRef } from 'react'
import QRCodeStyling from "qr-code-styling";

export interface IQrCodeProps extends HTMLProps<HTMLDivElement> {
    value?: string;
    height?: number;
    width?: number;
}

function QrCode({
    value = '',
    width = 300,
    height = 300,
    ...props
}: IQrCodeProps) {

    const qrCodeElementRef = useRef<HTMLDivElement>(null)
    const qrCodeRef = useRef<QRCodeStyling>()
    useEffect(() => {
        if (qrCodeRef.current) {
            qrCodeRef.current.update({
                data: value,
                height,
                width
            })
        } else {
            qrCodeRef.current = new QRCodeStyling({
                height,
                width,
                backgroundOptions: {
                    color: '#eee',
                },
                dotsOptions: {
                    type: 'dots',
                    color: '#7000FF'
                },
                cornersDotOptions: {
                    type: 'dot',
                    color: '#BD00FF'
                },
                cornersSquareOptions: {
                    type: 'extra-rounded',
                    color: '#7000FF'
                },
                data: value,
            })
            if(qrCodeElementRef.current) {
                qrCodeRef.current.append(qrCodeElementRef.current);
            }
        }
    }, [height, value, width])

    return (
        <div {...props} ref={qrCodeElementRef} />
    )
}

export default QrCode