import '../assets/NumberCoreUpDownController.scss'

type Props = {
    onUpClick: () => void;
    onDownClick: () => void;
}

function NumberCoreUpDownController({
    onDownClick,
    onUpClick
}: Props) {
    return (
        <div className='number-core-up-down-controller'>
            <div onClick={onUpClick} className='number-core-up'>
                +
            </div>
            <div onClick={onDownClick} className='number-core-down'>
                -
            </div>
        </div>
    )
}

export default NumberCoreUpDownController