import Button from 'components/Button/Button'
import ButtonIconText from 'components/Button/components/ButtonIconText'
import { useModalState } from 'components/Modal/hooks/useModalState'
import Modal from 'components/Modal/Modal'
import React, { Fragment } from 'react'
import WalletTokenTransactions from './WalletTokenTransactions'

type Props = {
  tokenName: string;
  chain: string
  address: string;
  contractAddress: string;
}

function WalletTokenTransactionsListDialog({
  chain,
  address,
  contractAddress,
  tokenName
}: Props) {
  const { close, isOpen, open } = useModalState()
  return (
    <Fragment>
      <Button onClick={open}>
        <ButtonIconText
          style={{ whiteSpace: 'nowrap' }}
          icon={<i className='icon mr-1 icon-sync-alt' />}
          text={'Transaction List'}
        />
      </Button>
      <Modal width={750} isOpen={isOpen} close={close}>
        <WalletTokenTransactions
          tokenName={tokenName}
          address={address}
          chain={chain}
          contractAddress={contractAddress}
        />
      </Modal>
    </Fragment>
  )
}

export default WalletTokenTransactionsListDialog