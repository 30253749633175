import { ISidebarItem } from "./types";

export const SIDEBAR_MENU_ITEMS: ISidebarItem[] = [
    {
        icon: <i className="icon icon-dashboard" />,
        title: 'Dashboard',
        link: '/dashboard'
    },
    {
        icon: <i className="icon icon-api" />,
        title: 'Access Token',
        link: '/accessToken'
    },
    {
        icon: <i className="icon icon-wallet" />,
        title: 'Wallet',
        link: '/wallet'
    },
    {
        icon: <i className="icon icon-token" />,
        title: 'Token Factory',
        link: '/tokenFactory'
    },
    {
        icon: <i className="icon icon-toll" />,
        title: 'Credits',
        link: '/credits'
    },
    {
        icon: <i className="icon icon-toll" />,
        title: 'Dao',
        link: '/dao'
    },
];

export const ADMIN_SIDEBAR_MENU_ITEMS: ISidebarItem[] = [
    {
        icon: <i className="icon icon-person" />,
        title: 'Users',
        link: '/userList'
    },
    {
        icon: <i className="icon icon-toll" />,
        title: 'Service Cost',
        link: '/serviceCost'
    },

    {
        icon: <i className="icon icon-toll" />,
        title: 'Service Call log',
        link: '/serviceCallLog'
    },
    {
        icon: <i className="icon icon-toll" />,
        title: 'Trading Commissions',
        link: '/tradingCommission'
    },
];