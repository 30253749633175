import Button from 'components/Button/Button'
import ButtonIconText from 'components/Button/components/ButtonIconText'
import BaseField from 'components/form/BaseField/BaseField'
import Datepicker from 'components/form/Datepicker/Datepicker'
import TextCore from 'components/form/TextCore/TextCore'
import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import { useListContext } from 'containers/ListContainer/hooks/useListContext'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { IAccessTokenCreateSuccessModel, IAccessTokenForm } from '../meta/types'

type Props = {
    closeModal: () => void;
    onCreateSuccessfuly?: (model: IAccessTokenCreateSuccessModel) => void;
}

function AccessTokenCreateForm({
    closeModal,
    onCreateSuccessfuly
}: Props) {
    const snackbar = useSnackbar()
    const form = useForm<IAccessTokenForm>({ mode: 'onTouched' })

    const {
        callRequest,
        pending,
    } = useRequest<IAccessTokenCreateSuccessModel, IAccessTokenForm>({
        url: endpoints.accessToken.generate,
        method: 'POST',
        callRequestOnInit: false,
    })

    const { refreshList } = useListContext()

    const handleSubmit = async (values: IAccessTokenForm) => {
        const response = await callRequest({
            data: values
        })

        refreshList()

        closeModal()

        snackbar.open({
            color: 'green',
            timeout: 4000,
            content: 'Api Created Successfuly'
        })
        if (onCreateSuccessfuly)
            onCreateSuccessfuly(response.data)
    }
    return (
        <FormProvider {...form}>
            <form className='position-relative' onSubmit={form.handleSubmit(handleSubmit)}>
                {pending && <LoadingContainer>
                    <CircleLoading />
                </LoadingContainer>}

                <h3 className='py-2'>
                    Generate Access Token
                </h3>
                <BaseField
                    component={TextCore}
                    name="name"
                    inputLabel='Access Token Name'
                    className='mt-4'
                    required
                />
                <BaseField
                    component={Datepicker}
                    name="validUntil"
                    inputLabel='Valid Until'
                    className='mt-4'
                    required
                />
                <div className="d-flex justify-content-center mt-4">
                    <Button type='submit'>
                        <ButtonIconText
                            text={'Register'}
                        />
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}

export default AccessTokenCreateForm