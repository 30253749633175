import classNames from 'classnames';
import CoinIcon from 'components/CoinIcon/CoinIcon';
import { ITableColumn } from 'components/Table/meta/types';
import Table from 'components/Table/Table';
import TokenAddressLink from 'components/TokenAddressLink/TokenAddressLink';
import { IToken } from 'types/IToken';

const columns: ITableColumn<IToken>[] = [
    {
        field: 'name',
        header: 'Name',
        render: (v, row) => <div className="d-flex">
            <CoinIcon
                className='mb-1'
                icon={row.chain}
            />
            <div className="name my-auto ml-2">
                {row.name}
            </div>
        </div>
    },
    {
        field: 'symbol',
        header: 'Symbol',
    },
    {
        field: 'totalSupply',
        header: 'Type',
        uniqueSortKey: 'type',
        width: '12%',
        render: v => 'ERC-20'
    },
    {
        field: 'address',
        uniqueSortKey: 'status',
        header: 'Deployed',
        width: '12%',
        render: _ => <div className="d-flex">
            <div style={{ borderRadius: '50%', marginRight: 3.5, height: 10, width: 10 }} className='my-auto bg-green'></div>
            <div style={{ marginTop: 3.5 }}>
                Deployed
            </div>
        </div>
    },
    {
        field: 'tokenAddress',
        header: 'Token Address',
        width: '20%',
        render: (v: string, row) => {
            return <TokenAddressLink
                chain={row.chain}
                tokenAddress={v}
            />
        }
    }
];

type Props = {
    data?: IToken[];
    className?: string;
}

function TokenFactoryList({
    data = [],
    className
}: Props) {
    return (
        <Table
            className={classNames('token-factory-list', className)}
            data={data}
            keyField={'tokenAddress'}
            columns={columns}
        />
    )
}

export default TokenFactoryList