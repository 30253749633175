import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import ListContainer from 'containers/ListContainer/ListContainer'
import useRequest from 'hooks/useRequest/useRequest'
import { ITradingCommission } from 'types/ITradingCommission'
import TradingCommissionListHeader from './TradingCommissionListHeader'
import TradingCommissionListTable from './TradingCommissionListTable'

type Props = {
    currency?: string;
    tokenId?: number;
};

function TradingCommissionList({
    currency,
    tokenId
}: Props) {

    const {
        callRequest,
        responseData,
        pending
    } = useRequest<ITradingCommission[]>({
        url: endpoints.tradingCommissions.list,
        params: {
            currency,
            tokenId
        }
    })

    return (
        <ListContainer
            page={0}
            refreshList={callRequest}
        >
            {pending && <div className="position-relative">
                <LoadingContainer>
                    <CircleLoading />
                </LoadingContainer>
            </div>}
            <TradingCommissionListHeader
                currency={currency}
                tokenId={tokenId}
            />
            <div className="mt-2">
                <TradingCommissionListTable
                    data={responseData}
                    currency={currency}
                    tokenId={tokenId}
                />
            </div>
        </ListContainer>
    )
}

export default TradingCommissionList