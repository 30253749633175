import SidebarItem from './components/SidebarItem'
import { ADMIN_SIDEBAR_MENU_ITEMS, SIDEBAR_MENU_ITEMS } from './meta/constants'
import './assets/Sidebar.scss'
import SidebarHeader from './components/SidebarHeader'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'

interface Props {
  onCollapseButtonClick: () => void;
}

function Sidebar({
  onCollapseButtonClick
}: Props) {
  const isAdmin = useSelector((state: RootState) => state.user.user?.isAdmin)
  return (
    <div className={'sidebar'}>
      <SidebarHeader onCollapseButtonClick={onCollapseButtonClick} />
      <div className="sidebar-items-list">
        {(isAdmin ? ADMIN_SIDEBAR_MENU_ITEMS : SIDEBAR_MENU_ITEMS).map((sidebarItem, index) => <SidebarItem
          icon={sidebarItem.icon}
          collapsedIcon={sidebarItem.collapsedIcon}
          title={sidebarItem.title}
          link={sidebarItem.link}
          key={index}
        />)}
      </div>
    </div>
  )
}

export default Sidebar