import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import ListContainer from 'containers/ListContainer/ListContainer'
import useRequest from 'hooks/useRequest/useRequest'
import { useState } from 'react'
import { IListModuleState } from 'types/IListModuleState'
import { IServiceCallLogList } from 'types/IServiceCallLogList'
import ServiceCallLogListFooter from './components/ServiceCallLogListFooter'
import ServiceCallLogListTable from './components/ServiceCallLogListTable'

function ServiceCallLogList() {
  const [state, setState] = useState<IListModuleState>({
    page: 0
  })

  const {
    responseData,
    pending,
    callRequest
  } = useRequest<IServiceCallLogList | undefined>({
    url: endpoints.serviceCall.serviceCallCostLogList,
    callRequestOnInit: true,
    params: {
      page: state.page,
    }
  })

  const handlePageChange = (page: number) => {
    setState({ page })
  }

  return (
    <ListContainer
      refreshList={callRequest}
      page={state.page}
    >
      <div className='position-relative'>
        {pending && <LoadingContainer>
          <CircleLoading />
        </LoadingContainer>}
      </div>
      <ServiceCallLogListTable
        data={responseData?.logs}
      />
      <ServiceCallLogListFooter
        currenctPage={state.page}
        totalItems={responseData?.totalCount}
        onPageChange={handlePageChange}
      />
    </ListContainer>
  )
}

export default ServiceCallLogList