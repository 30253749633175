import classNames from 'classnames'
import { HTMLProps } from 'react'
import './assets/Chip.scss'
import { ChipModeType } from './meta/types'

export interface IChipProps extends HTMLProps<HTMLDivElement> {
    mode?: ChipModeType
}

function Chip({
    mode = 'default',
    children
}: IChipProps) {
  return (
    <div className={classNames('chip', mode)}>
        {children}
    </div>
  )
}

export default Chip