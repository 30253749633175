import Button from 'components/Button/Button'
import BaseField from 'components/form/BaseField/BaseField'
import NumberCore from 'components/form/NumberCore/NumberCore'
import CircleLoading from 'components/Loading/Loading'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import endpoints from 'constants/endpoints'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'
import { CreditsContext } from 'pages/Credits/context/CreditsContext'
import React, { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ICreditFormValues } from '../meta/types'

type Props = {
    closeModal: () => void;
}

function CreditForm({
    closeModal
}: Props) {

    const snackbar = useSnackbar()

    const form = useForm<ICreditFormValues>({
        defaultValues: {
            creditsToAdd: 100
        }
    })

    const {
        callRequest,
        pending
    } = useRequest<any, ICreditFormValues>({
        method: 'post',
        url: endpoints.serviceCall.serviceCallAddCredits,
        callRequestOnInit: false,
    })


    const { observer } = useContext(CreditsContext)

    const handleSubmit = async (values: ICreditFormValues) => {
        await callRequest({
            data: {
                creditsToAdd: Number(values.creditsToAdd)
            }
        })

        observer.emit('REFRESH_CHARTS', null);

        closeModal()

        snackbar.open({
            color: 'green',
            timeout: 4000,
            content: 'Credits Added succefuly'
        })
    }

    return (
        <FormProvider {...form}>
            <form className='position-relative' onSubmit={form.handleSubmit(handleSubmit)}>
                {pending && <LoadingContainer>
                    <CircleLoading />
                </LoadingContainer>}
                <h3 className='py-2'>
                    Add Credits
                </h3>
                <BaseField
                    component={NumberCore}
                    name='creditsToAdd'
                    className="mt-4"
                    inputLabel="Credits to add"
                />
                <div className="d-flex flex-direction-row-reverse mt-4">
                    <Button type='submit'>
                        Add
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}

export default CreditForm