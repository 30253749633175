import Button from 'components/Button/Button'
import BaseField from 'components/form/BaseField/BaseField'
import TextCore from 'components/form/TextCore/TextCore'
import endpoints from 'constants/endpoints'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'
import { FormProvider, useForm } from 'react-hook-form'
import { ISingleResult } from 'types/ISingleResult'
import { IUserCreditsData, IUserCreditsFormValues } from '../meta/types'

type Props = {
    userName: string;
    refreshCharts: () => void;
}

function UserListAddCredits({
    userName,
    refreshCharts
}: Props) {

    const {
        callRequest: addCredits
    } = useRequest<ISingleResult<boolean>, IUserCreditsData>({
        url: endpoints.admin.addCredits(userName),
        method: 'POST',
        callRequestOnInit: false
    })

    const form = useForm<IUserCreditsFormValues>()
    const snackbar = useSnackbar()
    const handleSubmit = async (values: IUserCreditsFormValues) => {
        await addCredits({
            data: {
                creditsToAdd: Number(values?.creditsToAdd)
            }
        })
        refreshCharts()
        snackbar.open({
            color: 'green',
            timeout: 3000,
            content: 'Credits Added Successfuly'
        })
    }
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
                <h3 className='my-3'>
                    Add Credits
                </h3>
                <BaseField
                    component={TextCore}
                    name="creditsToAdd"
                    number
                    inputLabel="Credits to Add"
                />
                <div className="d-flex flex-direction-row-reverse mt-3">
                    <Button type='submit'>
                        Add
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}

export default UserListAddCredits