import Button from 'components/Button/Button'
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard'
import TextCore from 'components/form/TextCore/TextCore'
import Modal from 'components/Modal/Modal'
import Tooltip from 'components/Tooltip/Tooltip'
import '../assets/AccessTokenDisplayToken.scss'

type Props = {
    isOpen?: boolean;
    close?: () => void;
    accessToken?: string;
}

function AccessTokenDisplayToken({
    accessToken,
    isOpen,
    close
}: Props) {
    return (
        <Modal cardClassName='accesss-token-display-token' isOpen={isOpen} close={close}>
            <div className="text">
                New access token has been created. please save it securily now:
            </div>
            <div className="d-flex">
                <TextCore
                    readOnly
                    className='mt-4'
                    value={accessToken}
                    right={<CopyToClipboard tag={Tooltip} tooltipContent={'Copy'} className='icon icon-copy cursor-pointer' textToCopy={accessToken} />}
                />
                
            </div>
            <div className="d-flex justify-content-center mt-4">
                <Button onClick={close}>
                    I have saved this token
                </Button>
            </div>
        </Modal>
    )
}

export default AccessTokenDisplayToken