import Button from 'components/Button/Button'
import BaseField from 'components/form/BaseField/BaseField'
import TextCore from 'components/form/TextCore/TextCore'
import { AUTH_LOCAL_STORAGE_KEYS } from 'constants/auth'
import endpoints from 'constants/endpoints'
import useSnackbar from 'containers/Snackbar/hooks/useSnackbar'
import useRequest from 'hooks/useRequest/useRequest'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { userSlice } from 'redux/user/userSlice'
import { IUserChangePassword, IUserChangePasswordData } from '../meta/types';

type Props = {
    close: () => void;
}

function UserChangePassword({
    close
}: Props) {

    const form = useForm<IUserChangePassword>()

    const {
        callRequest: changePassword,
    } = useRequest<any, IUserChangePasswordData>({
        url: endpoints.changePassword,
        method: 'PUT',
        callRequestOnInit: false
    })

    const user = useSelector((state: RootState) => state.user.user)
    const dispatch = useDispatch()

    const snackbar = useSnackbar()

    const handleSubmit = async (values: IUserChangePassword) => {
        if (values?.newPasswordRepeat !== values?.newPassword) {
            snackbar.open({
                color: 'red',
                timeout: 4000,
                content: 'New Password not equal with repeat'
            })
            return;
        }
        changePassword({
            data: {
                currentPassword: values?.currentPassword,
                newPassword: values?.newPassword,
            }
        }).then(() => {
            snackbar.open({
                color: 'green',
                timeout: 4000,
                content: 'Password Changed'
            })

            close()
            localStorage.removeItem(AUTH_LOCAL_STORAGE_KEYS.TOKEN)
            dispatch(userSlice.actions.logout())
            if (user?.isAdmin) {
                window.location.href = '/admin/login';
            } else {
                window.location.href = '/login';
            }
        })

    }

    return (
        <FormProvider {...form}>
            <h3>
                Change Password
            </h3>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="d-flex flex-wrap position-relative">
                <BaseField
                    component={TextCore}
                    name="currentPassword"
                    className="col-12 mt-4"
                    type="password"
                    inputLabel="Current Password"
                    required
                />

                <BaseField
                    component={TextCore}
                    name="newPassword"
                    className="col-12 mt-4"
                    type="password"
                    inputLabel="newPassword"
                    required
                />

                <BaseField
                    component={TextCore}
                    name="newPasswordRepeat"
                    className="col-12 mt-4"
                    type="password"
                    inputLabel="New Password Repeat"
                    required
                />

                <div className="d-flex flex-direction-row-reverse mt-4">
                    <Button type='submit'>
                        Change Password
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}

export default UserChangePassword