import classNames from 'classnames'
import { HTMLProps, ReactNode } from 'react'
import './assets/FieldWrapper.scss'

export interface FieldWrapperProps extends HTMLProps<HTMLDivElement> {
    icon?: ReactNode;
    children?: ReactNode;
}

function FieldWrapper({
    icon,
    children,
    className,
    ...rest
}: FieldWrapperProps) {
  return (
    <div {...rest} className={classNames('field-wrapper', className)}>
        <div className="icon">
            {icon}
        </div>
        {children}
    </div>
  )
}

export default FieldWrapper