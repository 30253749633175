import { ITableColumn } from 'components/Table/meta/types';
import Table from 'components/Table/Table';
import moment from 'jalali-moment';
import React from 'react'
import { IServiceCallLog } from 'types/IServiceCallLog';

const columns: ITableColumn<IServiceCallLog>[] = [
    {
        field: 'serviceName',
        header: 'Service Name',
        width: '25%',
    },
    {
        field: 'serviceUrl',
        header: 'Service Url',
        width: '25%',
    },
    {
        field: 'userId',
        header: 'User',
    },
    {
        field: 'cost',
        header: 'Cost',
    },
    {
        field: 'createdAt',
        header: 'Date',
        width: '20%',
        render: v => moment(v).format('YYYY/MM/DD hh:mm')
    }
];

type Props = {
    data?: IServiceCallLog[];
}

function AdminServiceCallLogListTable({
    data = []
}: Props) {
    return (
        <Table
            data={data}
            columns={columns}
        />
    )
}

export default AdminServiceCallLogListTable