import Pagination from "components/Pagination/Pagination";

type Props = {
  onPageChange: (page: number) => void;
  totalItems?: number;
  currenctPage?: number;
};

function DaoListFooter({
  onPageChange,
  totalItems = 0,
  currenctPage = 0,
}: Props) {
  return (
    <div className="d-flex flex-direction-row-reverse mt-4">
      <Pagination
        currenctPage={currenctPage}
        onPageChange={onPageChange}
        pageCount={
          totalItems / Number(process.env.REACT_APP_API_DEFAULT_PAGE_SIZE || 10)
        }
      />
    </div>
  );
}

export default DaoListFooter;
