import API from 'API'
import endpoints from 'constants/endpoints'
import { ReactNode, useCallback, useRef } from 'react'
import { IChain } from 'types/IChain'
import { ChainContext } from './context/ChainContext'
import { IChainProviderItem } from './meta/types'

interface Props {
    children: ReactNode;
}

function ChainProvider({
    children
}: Props) {

    const chainListPromiseRef = useRef<Promise<IChainProviderItem[]>>()

    const chainListRef = useRef<IChainProviderItem[]>()

    const getChains = useCallback<() => Promise<IChainProviderItem[]>>(async () => {
        // promise done before
        if (chainListRef.current) {
            return chainListRef.current;
        }
        // is promise pending 
        else if (chainListPromiseRef.current) {
            return chainListPromiseRef.current
        }
        // calling is for the first time 
        else {
            chainListPromiseRef.current = API.get<IChain[]>(endpoints.chain)
                .then((res) => res.data)
                .then((chins) => {
                    return chins.map<IChainProviderItem>(chain => ({
                        ...chain,
                        getTokenLink: (contractAddress: string) => chain.tokenLink
                            .replace(/{contractAddress}/g, contractAddress)
                    }))
                });
            const chains: IChainProviderItem[] = await chainListPromiseRef.current;
            chainListRef.current = chains;
            const finalChains = chains;
            return finalChains;
        }
    }, [])

    return (
        <ChainContext.Provider value={{
            getChains
        }}>
            {children}
        </ChainContext.Provider>
    )
}

export default ChainProvider