import Modal from 'components/Modal/Modal'
import ServiceCostForm from './ServiceCostForm'

type Props = {
    serviceCostId: number;
    closeModal: () => void;
}

function ServiceCostModal({
    serviceCostId,
    closeModal
}: Props) {
    return (
        <Modal width={450} close={closeModal} isOpen={true}>
            <ServiceCostForm
                serviceCostId={serviceCostId}
                closeModal={closeModal}
            />
        </Modal>
    )
}

export default ServiceCostModal